import React from 'react';
import PropTypes from 'prop-types';
import {
	Table, Button, Col,
	Form, FormGroup, Label, Input
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';

import InlineEdit from 'components/utils/inline_edit';
import ConfirmationModal from 'components/utils/confirmation_modal';

import { fetchDocumentsOfDocumentCategory, addDocumentToCategory } from 'actions/document_category';
import { updateDocument, removeDocument } from 'actions/document';

class AdminDocumentCategoryDetail extends React.Component {
	state = {
		dataReady: false,
		documents: [],
		removeDocumentCategoryId: 0,
		removeDocumentId: 0,
		removeDocumentCategoryModalOpen: false,
		removeDocumentModalOpen: false,
		newDocumentForm: {
			title: '',
			file: null
		},
		uploadingDocument: false
	};

	handleNewDocumentFormUpdate = (value, prop) => {
		this.setState((prevState) => ({
			newDocumentForm: { ...prevState.newDocumentForm, [prop]: value }
		}));
	};

	addDocumentToCategory = (e) => {
		e.preventDefault();
		this.setState({ uploadingDocument: true });
		return addDocumentToCategory(this.props.documentCategory.id, this.state.newDocumentForm)
		.then((doc) => {
			this.setState((prevState) => ({
				documents: [ ...prevState.documents, doc ],
				newDocumentForm: {
					title: ''
				},
				uploadingDocument: false
			}));
		});
	};

	handleDocumentCategoryEdited = (value, prop) => {
		return this.props.updateDocumentCategory({ [prop]: value });
	}

	handleRemoveDocumentCategory = (documentCategoryId) => {
		return this.props.removeDocumentCategory(documentCategoryId);
	};

	handleDocumentEdited = (documentId, value, prop) => {
		return updateDocument(documentId, { [prop]: value })
		.then((document) => {
			this.setState((prevState) => ({
				documents: prevState.documents.map((d) => d.id === documentId ? { ...d, ...document } : d)
			}));
		});
	};

	handleRemoveDocument = () => {
		return removeDocument(this.state.removeDocumentId)
		.then(() => {
			this.setState((prevState) => ({
				documents: prevState.documents.filter((d) => d.id !== prevState.removeDocumentId),
				removeDocumentId: 0,
				removeDocumentModalOpen: false
			}));
		});
	};

	componentDidMount() {
		fetchDocumentsOfDocumentCategory(this.props.documentCategory.id)
		.then((documents) => {
			this.setState({
				documents,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.documentCategory.id !== prevProps.documentCategory.id) {
			this.setState({ dataReady: false }, () => {
				fetchDocumentsOfDocumentCategory(this.props.documentCategory.id)
				.then((documents) => {
					this.setState({
						documents,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<h3>Category</h3>
			<Table>
				<thead>
					<tr>
						<th>Title</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><InlineEdit type="text" value={ this.props.documentCategory.title } onEdited={ (title) => this.handleDocumentCategoryEdited(title, 'title') } /></td>
						<td className="text-right">
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeDocumentId: document.id, removeDocumentCategoryModalOpen: true }) }>
								<i className="fa fa-trash"></i>{' '}Delete
							</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			<hr />
			<h3>Documents</h3>
			<Table>
				<thead>
					<tr>
						<th>Title</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
				{ this.state.documents.map((document) => (
					<tr key={ document.id }>
						<td><InlineEdit type="text" value={ document.title } onEdited={ (title) => this.handleDocumentEdited(document.id, title, 'title') } /></td>
						<td className="text-right">
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeDocumentId: document.id, removeDocumentModalOpen: true }) }>
								<i className="fa fa-trash"></i>{' '}Delete
							</Button>
						</td>
					</tr>
				)) }
				</tbody>
			</Table>
			<hr />
			<h4>Add document</h4>
			<Form onSubmit={ this.addDocumentToCategory }>
				<FormGroup row>
					<Label for="documentTitle" sm={3}>Title</Label>
					<Col sm={9}>
						<Input type="text" value={ this.state.newDocumentForm.title } onChange={ (e) => this.handleNewDocumentFormUpdate(e.target.value, 'title') } name="documentTitle" id="documentTitle" />
					</Col>
				</FormGroup>
				<FormGroup>
					<Input type="file" onChange={ (e) => this.handleNewDocumentFormUpdate(e.target.files[0], 'file') } name="documentFile" id="documentFile" />
				</FormGroup>
				<FormGroup>
					<Button color="primary" style={{ cursor: 'pointer', width: '5rem', height: '2.5rem' }}>
						{ !this.state.uploadingDocument && <React.Fragment>
							<i className="fa fa-file-text"></i> Add
						</React.Fragment> }
						<PulseLoader size={10} color={'#ffffff'} loading={this.state.uploadingDocument} />
					</Button>
				</FormGroup>
			</Form>
			<ConfirmationModal
				isOpen={ this.state.removeDocumentCategoryModalOpen }
				text="Are you sure you want to remove this document category ?"
				onConfirm={ () => { this.handleRemoveDocumentCategory(this.state.removeDocumentCategoryId); this.setState({ removeDocumentCategoryId: 0, removeDocumentCategoryModalOpen: false }); } }
				onDismiss={ () => { this.setState({ removeDocumentCategoryId: 0, removeDocumentCategoryModalOpen: false }); } }
			/>
			<ConfirmationModal
				isOpen={ this.state.removeDocumentModalOpen }
				text="Are you sure you want to remove this document ?"
				onConfirm={ this.handleRemoveDocument }
				onDismiss={ () => { this.setState({ removeDocumentId: 0, removeDocumentModalOpen: false }); } }
			/>
			</React.Fragment>
		);
	}
};

AdminDocumentCategoryDetail.propTypes = {
	documentCategory: PropTypes.object.isRequired,
	updateDocumentCategory: PropTypes.func.isRequired,
	removeDocumentCategory: PropTypes.func.isRequired
};

export default AdminDocumentCategoryDetail;

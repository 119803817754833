import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Form, FormGroup, Label, Input, Button
} from 'reactstrap';

import { fetchSchoolYears, updateSchoolYear, addSchoolYear } from 'actions/school_year';
import AdminSchoolYearDetail from 'components/admin/school_years/detail';

class AdminSchoolYears extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		newSchoolYearForm: {
			title: '',
			startDate: '',
			endDate: '',
			nbOpenWeeks: 35
		}
	}

	handleNewSchoolYearFormUpdate = (value, prop) => {
		this.setState((prevState) => ({
			newSchoolYearForm: { ...prevState.newSchoolYearForm, [prop]: value }
		}));
	};

	addSchoolYear = (e) => {
		e.preventDefault();
		return addSchoolYear(this.state.newSchoolYearForm)
		.then((schoolYear) => {
			this.setState((prevState) => ({
				schoolYears: [ ...prevState.schoolYears, schoolYear ]
			}));
		});
	};

	updateSchoolYear = (schoolYearId, changes) => {
		return updateSchoolYear(schoolYearId, changes)
		.then((schoolYear) => {
			this.setState((prevState) => ({
				schoolYears: prevState.schoolYears.map((s) => s.id === schoolYearId ? { ...s, ...schoolYear } : s)
			}));
		});
	};
	
	componentDidMount() {
		fetchSchoolYears()
		.then((schoolYears) => this.setState({
			schoolYears,
			dataReady: true
		}));
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Row>
				<Col xs="3">
					<Nav vertical>
						{ this.state.schoolYears.map((schoolYear) => {
							return (
								<NavItem key={ schoolYear.id }>
									<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/' + schoolYear.id }>
										{ schoolYear.title }
									</NavLink>
								</NavItem>
							);
						}) }
					</Nav>
					<hr />
					<h4>Add school year</h4>
					<Form onSubmit={ this.addSchoolYear }>
						<FormGroup>
							<Label for="schoolYearTitle">Title</Label>
							<Input type="text" value={ this.state.newSchoolYearForm.title } onChange={ (e) => this.handleNewSchoolYearFormUpdate(e.target.value, 'title') } name="schoolYearTitle" id="schoolYearTitle" />
						</FormGroup>
						<FormGroup>
							<Label for="schoolYearStartDate">Start date</Label>
							<Input type="date" value={ this.state.newSchoolYearForm.startDate } onChange={ (e) => this.handleNewSchoolYearFormUpdate(e.target.value, 'startDate') } name="schoolYearStartDate" id="schoolYearStartDate" />
						</FormGroup>
						<FormGroup>
							<Label for="schoolYearEndDate">End date</Label>
							<Input type="date" value={ this.state.newSchoolYearForm.endDate } onChange={ (e) => this.handleNewSchoolYearFormUpdate(e.target.value, 'endDate') } name="schoolYearEndDate" id="schoolYearEndDate" />
						</FormGroup>
						<FormGroup>
							<Label for="schoolYearNbOpenWeeks">Nb open weeks</Label>
							<Input type="number" value={ this.state.newSchoolYearForm.nbOpenWeeks } onChange={ (e) => this.handleNewSchoolYearFormUpdate(parseInt(e.target.value, 10), 'nbOpenWeeks') } name="schoolYearNbOpenWeeks" id="schoolYearNbOpenWeeks" />
						</FormGroup>
						<FormGroup>
							<Button color="primary" style={{ cursor: 'pointer' }}>
								<i className="fa fa-graduation-cap"></i> Add
							</Button>
						</FormGroup>
					</Form>
				</Col>
				<Col xs="9">
					<Route path={ this.props.match.url + '/:schoolYearId' } render={ (props) => (
						<AdminSchoolYearDetail
							{ ...props }
							schoolYear={ this.state.schoolYears.find((s) => s.id === parseInt(props.match.params.schoolYearId, 10)) }
							updateSchoolYear={ (changes) => this.updateSchoolYear(parseInt(props.match.params.schoolYearId, 10), changes) }
						/>
					) } />
				</Col>
			</Row>
		);
	}
};

export default AdminSchoolYears;

export const strasbourgDistricts = [
	'Bourse, Esplanade, Krutenau',
	'Centre République',
	'Centre Gare',
	'Conseil des XV, Rotterdam',
	'Cronenbourg, Hautepierre, Poteries, Hohberg',
	'Koenigshoffen, Montagne-Verte, Elsau',
	'Meinau',
	'Neudorf, Schluthfeld, Port du Rhin, Musau',
	'Neuhof, Stockfeld, Ganzau',
	'Robertsau, Wacken',
	'Autre'
];

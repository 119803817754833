import React, { createContext } from 'react';
import { withRouter } from 'react-router-dom';

import { whoami, signin, signout } from 'actions/user';

export const AuthContext = createContext({
	user: null
});

class Provider extends React.Component {
	state = {
		dataReady: false,
		user: null
	};

	signin = (credentials) => {
		return signin(credentials)
		.then((user) => {
			this.setState(
				{ user },
				() => this.props.history.push('/')
			);
		});
	};

	signout = () => {
		signout();
		this.setState(
			{ user: null, dataReady: false },
			() => {
				this.props.history.push('/');
				this.setState({ dataReady: true });
			}
		);
	}

	componentDidMount() {
		whoami()
		.then((user) => this.setState(
			{ user, dataReady: true }
		))
		.catch(() => {
			signout();
			this.setState({ user: null, dataReady: true });
		});
	}
	
	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<AuthContext.Provider value={{
				user: this.state.user,
				signin: this.signin,
				signout: this.signout
			}}>
				{ this.props.children }
			</AuthContext.Provider>
		)
	}
};

export const AuthProvider = withRouter(Provider);

import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import { HashRouter } from 'react-router-dom';
import App from 'components/app';

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
	<HashRouter>
		<App />
	</HashRouter>,
	document.getElementById('root')
);

// registerServiceWorker();

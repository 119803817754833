import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const fetchHolidayContractInvoice = (id) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id, authorization())
	.then(checkStatus)
	.then((res) => res.blob());
};

export const sendHolidayContractInvoice = (id) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id, authorization({
		method: 'POST'
	}))
	.then(checkStatus)
	.then((res) => res.json());
}

export const updateHolidayContractInvoice = (id, changes) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeHolidayContractInvoice = (id) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

export const addHolidayContractInvoiceModification = (id, modif) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id + '/modifications', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(modif)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeHolidayContractInvoiceModification = (id, modifId) => {
	return fetch(apiBasename + '/holidayContractInvoice/' + id + '/modifications/' + modifId, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
}


import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Row, Col, Table, Button
} from 'reactstrap';

import InlineEdit from 'components/utils/inline_edit';

import {
	fetchChildrenOfParent
} from 'actions/parent';

class AccountantParentDetail extends React.Component {
	state = {
		dataReady: false,
		children: []
	};

	handleParentEdited = (value, prop) => {
		return this.props.updateParent({ [prop]: value });
	};

	componentDidMount() {
		fetchChildrenOfParent(this.props.parent.id)
		.then((children) => {
			this.setState({
				children,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.parent.id !== prevProps.parent.id) {
			this.setState({ dataReady: false }, () => {
				fetchChildrenOfParent(this.props.parent.id)
				.then((children) => {
					this.setState({
						children,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Firstname</th>
						<td><span>{ this.props.parent.firstname }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Lastname</th>
						<td><span>{ this.props.parent.lastname }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel home</th>
						<td><span>{ this.props.parent.telHome }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel mobile</th>
						<td><span>{ this.props.parent.telMobile }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel pro</th>
						<td><span>{ this.props.parent.telPro }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Email</th>
						<td><span>{ this.props.parent.email }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Address</th>
						<td><span>{ this.props.parent.address }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>District</th>
						<td><span>{ this.props.parent.district }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Profession</th>
						<td><span>{ this.props.parent.profession }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Employer</th>
						<td><span>{ this.props.parent.employer }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Mother tongue</th>
						<td><span>{ this.props.parent.motherTongue }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>CAF number</th>
						<td><span>{ this.props.parent.cafNumber }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Committee member</th>
						<td><span>{ this.props.parent.committeeMember ?
							<i style={{ color: 'green'}} className="fa fa-check"></i> :
							<i style={{ color: 'red' }} className="fa fa-ban"></i> }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Playgroup staff</th>
						<td><span>{ this.props.parent.staff ?
							<i style={{ color: 'green'}} className="fa fa-check"></i> :
							<i style={{ color: 'red' }} className="fa fa-ban"></i> }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Référence Unique de Mandat (RUM)</th>
						<td><InlineEdit type="text" value={ this.props.parent.rum } onEdited={ (rum) => this.handleParentEdited(rum, 'rum') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>IBAN</th>
						<td><InlineEdit type="text" value={ this.props.parent.iban } onEdited={ (iban) => this.handleParentEdited(iban, 'iban') } /></td>
					</tr>
					<tr>
						<th scope="row">Children</th>
						<td>
							<Row>
								<Col>
								{ this.state.children.map((child) => (
									<Button
										key={ child.id }
										tag={ Link } to={ '/accountant/children/' + child.id + '/info' }
										color="primary" size="sm" className="mr-1"
										style={{ cursor: 'pointer' }}>
										{ child.firstname } { child.lastname }
									</Button>
								)) }
								</Col>
							</Row>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
};

AccountantParentDetail.propTypes = {
	parent: PropTypes.object.isRequired,
	updateParent: PropTypes.func.isRequired
};

export default AccountantParentDetail;

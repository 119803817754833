import React, { createContext } from 'react';
import isBefore from 'date-fns/is_before';
import compareDesc from 'date-fns/compare_desc';

import { fetchConfigValues } from 'actions/config_value';
import { fetchConfigRevisableValues } from 'actions/config_revisable_value';

export const ConfigContext = createContext({});

export class ConfigProvider extends React.Component {
	state = {
		dataReady: false,
		configValues: [],
		configRevisableValues: []
	};

	getConfigValue = (name) => this.state.configValues.find((configValue) => configValue.name === name).value;

	getConfigRevisableValue = (name, date) => {
		const values = this.state.configRevisableValues
		.filter((configRevisableValue) => configRevisableValue.name === name && isBefore(configRevisableValue.validSince, date))
		.sort((a, b) => compareDesc(a.validSince, b.validSince));
		if (values.length > 0) {
			return values[0].value;
		} else {
			return null;
		}
	};

	componentDidMount() {
		Promise.all([
			fetchConfigValues(),
			fetchConfigRevisableValues()
		])
		.then(([ configValues, configRevisableValues ]) => this.setState({
			configValues,
			configRevisableValues, 
			dataReady: true
		}));
	}
	
	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<ConfigContext.Provider value={{
				getConfigValue: this.getConfigValue,
				getConfigRevisableValue: this.getConfigRevisableValue
			}}>
				{ this.props.children }
			</ConfigContext.Provider>
		)
	}
};

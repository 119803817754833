import React from 'react';
import PropTypes from 'prop-types';
import {
	Col, Table,
	ButtonGroup, Button,
	Form, FormGroup, Label, Input
} from 'reactstrap';

import ConfirmationModal from 'components/utils/confirmation_modal';
import InlineEdit from 'components/utils/inline_edit';
import Week from 'components/utils/week';

import {
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchRegularOffersOfSchoolYear,
	addRegularOfferToSchoolYear
} from 'actions/school_year';
import { updateRegularOffer, removeRegularOffer } from 'actions/regular_offer';

import { dayNames } from 'services/calendar';

class AdminSchoolYearDetailRegularOffers extends React.Component {
	state = {
		dataReady: false,
		schoolTimeSlotsByDay: [],
		regularOffers: [],
		selectedRegularOfferId: null,
		newOfferProperties: {
			title: '',
			rate: 0,
			nonCAFIncrease: 0
		},
		newOfferSchoolTimeSlotsId: [],
		confirmationModalOpen: false,
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 }
	};

	toggleRegularOffer = (regularOfferId) => {
		this.setState((prevState) => {
			const selectedRegularOfferId = regularOfferId === prevState.selectedRegularOfferId ? null : regularOfferId;
			const schoolTimeSelectedSlotsId = selectedRegularOfferId ?
				this.state.regularOffers
				.find((regularOffer) => regularOffer.id === selectedRegularOfferId)
				.SchoolTimeSlots
				.map((sts) => sts.id)
				: [];

			return {
				selectedRegularOfferId,
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: schoolTimeSelectedSlotsId.includes(slot.id) }))
				}))
			};
		});
	};

	handleRegularOfferEdited = (regularOfferId, changes) => {
		return updateRegularOffer(regularOfferId, changes)
		.then((regularOffer) => {
			this.setState((prevState) => ({
				regularOffers: prevState.regularOffers.map((r) => r.id === regularOfferId ? { ...r, ...regularOffer } : r)
			}));
		});
	};

	toggleSchoolTimeSlot = (schoolTimeSlotId) => {
		this.setState((prevState) => ({
			schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
				...stsOfDay,
				slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: slot.id === schoolTimeSlotId ? !slot.selected : slot.selected }))
			})),
			newOfferSchoolTimeSlotsId: prevState.newOfferSchoolTimeSlotsId.includes(schoolTimeSlotId) ?
				prevState.newOfferSchoolTimeSlotsId.filter((id) => id !== schoolTimeSlotId) :
				[ ...prevState.newOfferSchoolTimeSlotsId, schoolTimeSlotId ]
		}));
	};

	handleNewOfferPropertyChange = (value, prop) => {
		this.setState((prevState) => ({
			newOfferProperties: { ...prevState.newOfferProperties, [prop]: value }
		}));
	};

	addRegularOffer = (event) => {
		event.preventDefault();
		addRegularOfferToSchoolYear(
			this.props.schoolYear.id,
			{
				title: this.state.newOfferProperties.title,
				rate: this.state.newOfferProperties.rate * 100,
				nonCAFIncrease: this.state.newOfferProperties.nonCAFIncrease * 100,
				schoolTimeSlots: this.state.newOfferSchoolTimeSlotsId
			}
		)
		.then((regularOffer) => {
			this.setState((prevState) => ({
				regularOffers: [ ...prevState.regularOffers, regularOffer ],
				selectedRegularOfferId: null,
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: false }))
				})),
				newOfferProperties: {
					title: '',
					rate: 0,
					nonCAFIncrease: 0
				},
				newOfferSchoolTimeSlotsId: []
			}));
		});
	};

	removeRegularOffer = () => {
		this.setState({ confirmationModalOpen: true });
	};
	
	confirmRemoveRegularOffer = () => {
		removeRegularOffer(this.state.selectedRegularOfferId)
		.then(() => {
			this.setState((prevState) => ({
				regularOffers: prevState.regularOffers.filter((r) => r.id !== prevState.selectedRegularOfferId),
				selectedRegularOfferId: null,
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: false }))
				})),
				confirmationModalOpen: false
			}));
		})
	};

	dismissRemoveRegularOffer = () => {
		this.setState({ confirmationModalOpen: false });
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolTimeSlotsOfSchoolYear(this.props.schoolYear.id),
			fetchRegularOffersOfSchoolYear(this.props.schoolYear.id)
		])
		.then(([ schoolTimeSlots, regularOffers ]) => {
			this.setState({
				schoolTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
					name: dayNames[i],
					slots: schoolTimeSlots.filter((sts) => sts.weekday === i).map((slot) => ({ ...slot, active: true, selected: false }))
				})),
				regularOffers,
				dataReady: true
			});
		});
	}

	render() {
		const selectedRegularOffer = this.state.regularOffers.find((r) => r.id === this.state.selectedRegularOfferId);
		
		return (
			!this.state.dataReady ?
			'Loading...' :
			<div className="text-center">
				<ButtonGroup className="mb-2">
					{ this.state.regularOffers.map((regularOffer) => {
						const active = this.state.selectedRegularOfferId === regularOffer.id;
						const color = active ? 'success' : 'info';
						return (
							<Button key={ regularOffer.id } color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.toggleRegularOffer(regularOffer.id) }>
								{ regularOffer.title }
							</Button>
						);
					}) }
				</ButtonGroup>

				{ this.state.selectedRegularOfferId && selectedRegularOffer &&
				<React.Fragment>
				<Table className="text-left" key={ selectedRegularOffer.id }>
					<tbody>
						<tr>
							<th scope="row" style={{ width: '25%'}}>Title</th>
							<td><InlineEdit type="text" value={ selectedRegularOffer.title } onEdited={ (title) => this.handleRegularOfferEdited(selectedRegularOffer.id, { title }) } /></td>
						</tr>
						<tr>
							<th scope="row" style={{ width: '25%'}}>Rate</th>
							<td><InlineEdit type="number" value={ selectedRegularOffer.rate/100 } onEdited={ (rate) => this.handleRegularOfferEdited(selectedRegularOffer.id, { rate: rate*100 }) } /></td>
						</tr>
						<tr>
							<th scope="row" style={{ width: '25%'}}>Non-CAF increase</th>
							<td><InlineEdit type="number" value={ selectedRegularOffer.nonCAFIncrease/100 } onEdited={ (nonCAFIncrease) => this.handleRegularOfferEdited(selectedRegularOffer.id, { nonCAFIncrease: nonCAFIncrease*100 }) } /></td>
						</tr>
					</tbody>
				</Table>
				<Button color="danger" className="mb-2" style={{ cursor: 'pointer' }} onClick={ this.removeRegularOffer }>
					<i className="fa fa-trash-o"></i>Remove
				</Button>
				<ConfirmationModal
					isOpen={ this.state.confirmationModalOpen }
					text={ 'Are you sure you want to remove this regular offer (' + selectedRegularOffer.title +') ?' }
					onConfirm={ this.confirmRemoveRegularOffer }
					onDismiss={ this.dismissRemoveRegularOffer }
				/>
				</React.Fragment> }

				{ !this.state.selectedRegularOfferId &&
				<Form onSubmit={ this.addRegularOffer }>
					<FormGroup row>
						<Label for="title" sm={2}>Title</Label>
						<Col sm={10}>
							<Input type="text" required value={ this.state.newOfferProperties.title } onChange={ (e) => this.handleNewOfferPropertyChange(e.target.value, 'title') } name="title" id="title" placeholder="Title" />
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label for="rate" sm={2}>Rate</Label>
						<Col sm={10}>
							<Input type="number" value={ this.state.newOfferProperties.rate } onChange={ (e) => this.handleNewOfferPropertyChange(parseInt(e.target.value, 10) || 0, 'rate') } name="rate" id="rate" />
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label for="nonCAFIncrease" sm={2}>Non-CAF increase</Label>
						<Col sm={10}>
							<Input type="number" value={ this.state.newOfferProperties.nonCAFIncrease } onChange={ (e) => this.handleNewOfferPropertyChange(parseInt(e.target.value, 10) || 0, 'nonCAFIncrease') } name="nonCAFIncrease" id="nonCAFIncrease" />
						</Col>
					</FormGroup>
					<FormGroup row style={{ justifyContent: 'center' }}>
						<Button color="primary" style={{ cursor: 'pointer' }} disabled={ this.state.newOfferSchoolTimeSlotsId.length === 0 }>
							<i className="fa fa-plus"></i> Add
						</Button>
					</FormGroup>
				</Form> }
				
				<Week
					config={ this.state.weekConfig }
					slotsByDay={ this.state.schoolTimeSlotsByDay }
					onToggleSlot={ !this.state.selectedRegularOfferId ? this.toggleSchoolTimeSlot : null }
				/>
			</div>
		);
	}
};

AdminSchoolYearDetailRegularOffers.propTypes = {
	schoolYear: PropTypes.object.isRequired
};

export default AdminSchoolYearDetailRegularOffers;

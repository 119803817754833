import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

const User = {
	hasRole(roles) {
		if (Array.isArray(roles)) {
			return roles.some((r) => this.roles.includes(r));
		} else {
			return this.roles.indexOf(roles) >= 0;
		}
	}
};

const makeUser = (data) => Object.assign(Object.create(User), data);

export const whoami = () => {
	return fetch(apiBasename + '/user/whoami', authorization())
	.then(checkStatus)
	.then((res) => res.json())
	.then((user) => makeUser(user));
};

export const signin = (credentials) => {
	return fetch(apiBasename + '/user/signin', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(credentials)
	})
	.then(checkStatus)
	.then((res) => res.json())
	.then((data) => {
		window.localStorage.setItem('token', data.token);
		return makeUser(data.user);
	});
};

export const signout = () => {
	window.localStorage.removeItem('token');
};

export const fetchUsers = () => {
	return fetch(apiBasename + '/user', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const updateUser = (id, changes) => {
	return fetch(apiBasename + '/user/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeUser = (id) => {
	return fetch(apiBasename + '/user/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

export const fetchParentOfUser = (id) => {
	return fetch(apiBasename + '/user/' + id + '/parent', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

import React from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col, Button, ButtonGroup,
	Table, Collapse,
	Form, FormGroup, Label, Input,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
// import { toast } from 'react-toastify';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfMonth from 'date-fns/start_of_month';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import addMonths from 'date-fns/add_months';
import subMonths from 'date-fns/sub_months';

import InlineEdit from 'components/utils/inline_edit';
import ConfirmationModal from 'components/utils/confirmation_modal';

import { monthNames } from 'services/calendar';
import { saveAs } from 'file-saver';

import { fetchSchoolYear, fetchRegularOffersOfSchoolYear } from 'actions/school_year';
import { fetchInvoiceModificationCategories } from 'actions/invoice_modification_category';
import { downloadRegularContract } from 'actions/regular_contract';

class AdminChildDetailRegularContract extends React.Component {
	state = {
		dataReady: false,
		schoolYear: null,
		regularOffers: [],
		invoiceModificationCategories: [],
		invoiceModificationCategoriesById: {},
		changeContractStatusOpen: false,
		showInvoiceModifications: false,
		editedModificationsInvoiceId: 0,
		showNewInvoiceModificationForm: false,
		shouldRegenerateInvoice: false,
		newInvoiceModification: {
			InvoiceModificationCategoryId: null,
			title: '',
			amount: 0
		},
		sendMailToParents: true,
		downloadingContract: false,
		downloadingInvoiceId: null
	};

	handleContractEdited = (value, prop) => {
		if (prop === 'startDate') {
			const newstart = startOfMonth(value);
			let d = startOfMonth(this.props.regularContract.startDate);
			while (isBefore(d, newstart)) {
				if (this.props.regularContract.RegularContractInvoices.find((i) => i.year === d.getFullYear() && i.month === d.getMonth())) {
					// toast.error('Contract already has an invoice for a month before this date');
					return Promise.reject();
				}
				d = addMonths(d, 1);
			}
		}
		if (prop === 'endDate') {
			const newend = lastDayOfMonth(value);
			let d = startOfMonth(this.props.regularContract.endDate);
			while (isAfter(d, newend)) {
				if (this.props.regularContract.RegularContractInvoices.find((i) => i.year === d.getFullYear() && i.month === d.getMonth())) {
					// toast.error('Contract already has an invoice for a month after this date');
					return Promise.reject();
				}
				d = subMonths(d, 1);
			}
		}
		if (prop === 'status' && value === 'valid') { // validate contract
			return this.props.updateRegularContract({ [prop]: value, sendMail: this.state.sendMailToParents });
		}
		return this.props.updateRegularContract({ [prop]: value });
	};

	toggleRegularOffer = (regularOfferId) => {
		if (this.props.regularContract.RegularOffers.some((contractRegularOffer) => contractRegularOffer.id === regularOfferId)) {
			return this.props.removeRegularOfferFromRegularContract(regularOfferId);
		} else {
			return this.props.addRegularOfferToRegularContract(regularOfferId);
		}
	};

	toggleInvoiceModifications = (invoiceId) => {
		if (!this.state.showInvoiceModifications) {
			this.setState({
				showInvoiceModifications: true,
				editedModificationsInvoiceId: invoiceId,
				showNewInvoiceModificationForm: false,
				shouldRegenerateInvoice: false,
				newInvoiceModification: {
					InvoiceModificationCategoryId: null,
					title: '',
					amount: 0
				}
			});
		} else {
			const invoice = this.props.regularContract.RegularContractInvoices.find((i) => i.id === this.state.editedModificationsInvoiceId);
			this.setState({
				showInvoiceModifications: false,
				editedModificationsInvoiceId: 0
			}, () => {
				if (this.state.shouldRegenerateInvoice) {
					this.props.generateRegularContractInvoice(invoice.year, invoice.month);
				}
			});
		}
	};

	toggleShowNewInvoiceModification = () => {
		this.setState((prevState) => ({
			showNewInvoiceModificationForm: !prevState.showNewInvoiceModificationForm
		}));
	};

	handleNewInvoiceModificationEdited = (value, prop) => {
		this.setState((prevState) => ({
			newInvoiceModification: {
				...prevState.newInvoiceModification,
				[prop]: value
			}
		}));
	};

	handleAddRegularContractInvoiceModification = (e) => {
		e.preventDefault();
		this.props.addRegularContractInvoiceModification(this.state.editedModificationsInvoiceId, this.state.newInvoiceModification)
		.then(() => {
			this.setState({ shouldRegenerateInvoice: true });
		});
	};

	handleRemoveRegularContractInvoiceModification = (modifId) => {
		this.props.removeRegularContractInvoiceModification(this.state.editedModificationsInvoiceId, modifId)
		.then(() => {
			this.setState({ shouldRegenerateInvoice: true });
		});
	};

	downloadContract = () => {
		this.setState({ downloadingContract: true });
		downloadRegularContract(this.props.regularContract.id)
		.then((blob) => {
			saveAs(blob, 'regular_contract.pdf');
			this.setState({ downloadingContract: false });
		});
	};

	downloadInvoice = (invoiceId) => {
		const invoice = this.props.regularContract.RegularContractInvoices.find((i) => i.id === invoiceId);
		if (invoice) {
			this.setState({ downloadingInvoiceId: invoice.id });
			this.props.downloadRegularContractInvoice(invoice.id, invoice.filename)
			.then(() => {
				this.setState({ downloadingInvoiceId: null });
			});
		}
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYear(this.props.regularContract.SchoolYearId),
			fetchRegularOffersOfSchoolYear(this.props.regularContract.SchoolYearId),
			fetchInvoiceModificationCategories()
		])
		.then(([ schoolYear, regularOffers, invoiceModificationCategories ]) => {
			const invoiceModificationCategoriesById = {};
			invoiceModificationCategories.forEach((c) => invoiceModificationCategoriesById[c.id] = c);
			this.setState({
				schoolYear,
				regularOffers,
				invoiceModificationCategories,
				invoiceModificationCategoriesById,
				dataReady: true
			});
		});
	}
	
	render() {
		const contractInvoices = [];
		let d = startOfMonth(this.props.regularContract.startDate);
		const end = lastDayOfMonth(this.props.regularContract.endDate);
		while (isBefore(d, end)) {
			contractInvoices.push({
				year: d.getFullYear(),
				month: d.getMonth(),
				invoice: this.props.regularContract.RegularContractInvoices.find((i) => i.year === d.getFullYear() && i.month === d.getMonth())
			});
			d = addMonths(d, 1);
		}

		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row className="mb-2">
				<Col>
					<h4 className="text-right ml-auto">
						{ this.props.regularContract.status === 'valid' ?
							<i style={{ color: 'green'}} className="fa fa-check"></i> :
							<i style={{ color: 'red' }} className="fa fa-ban"></i>
						}
						{' '}Regular contract
						{' '}<i className="fa fa-clock-o"></i>
						{ (this.props.regularContract.status === 'valid' && this.props.regularContract.RegularContractInvoices.every((i) => i.status === 'edited')) &&
							<React.Fragment>
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ changeContractStatusOpen: true }) }>
								Invalidate
							</Button>
							<ConfirmationModal
								isOpen={ this.state.changeContractStatusOpen }
								text="Are you sure you want to invalidate this contract ?"
								onConfirm={ () => { this.setState({ changeContractStatusOpen: false }); this.handleContractEdited('demand', 'status'); } }
								onDismiss={ () => { this.setState({ changeContractStatusOpen: false }); } }
							/>
							</React.Fragment>
						}
						{ this.props.regularContract.status === 'demand' &&
							<React.Fragment>
							<Button color="success" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ changeContractStatusOpen: true }) }>
								Validate
							</Button>
							<ConfirmationModal
								isOpen={ this.state.changeContractStatusOpen }
								onConfirm={ () => { this.setState({ changeContractStatusOpen: false }); this.handleContractEdited('valid', 'status'); } }
								onDismiss={ () => { this.setState({ changeContractStatusOpen: false }); } }
							>
								<p>Are you sure you want to validate this regular contract ?</p>
								<Form>
									<FormGroup row>
										<Col sm={1} style={{ textAlign: 'right' }}>
											<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="sendmail" checked={ this.state.sendMailToParents } onChange={ (e) => this.setState({ sendMailToParents: e.target.checked }) } />
										</Col>
										<Label for="sendmail" sm={11}>Send email to the parents</Label>
									</FormGroup>
								</Form>
							</ConfirmationModal>
							</React.Fragment>
						}
						<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '13rem', height: '2rem' }} onClick={ this.downloadContract }>
							{ !this.state.downloadingContract && <React.Fragment>
								<i className="fa fa-download"></i>{' '}Download contract PDF
							</React.Fragment> }
							<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingContract} />
						</Button>
					</h4>
				</Col>
			</Row>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Start date</th>
						<td>
							<InlineEdit type="date" min={ this.state.schoolYear.startDate } max={ this.state.schoolYear.endDate } value={ this.props.regularContract.startDate } onEdited={ (startDate) => this.handleContractEdited(startDate, 'startDate') } />
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>End date</th>
						<td>
							<InlineEdit type="date" min={ this.state.schoolYear.startDate } max={ this.state.schoolYear.endDate } value={ this.props.regularContract.endDate } onEdited={ (endDate) => this.handleContractEdited(endDate, 'endDate') } />
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent income</th>
						<td>
						{ this.props.regularContract.status === 'valid'
							? <span>{ (this.props.regularContract.parentIncome / 100) + '€' }</span>
							: <InlineEdit type="number" value={ this.props.regularContract.parentIncome / 100 } onEdited={ (parentIncome) => this.handleContractEdited(parentIncome * 100, 'parentIncome') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent nb children</th>
						<td>
						{ this.props.regularContract.status === 'valid'
							? <span>{ this.props.regularContract.parentNbChildren }</span>
							: <InlineEdit type="number" value={ this.props.regularContract.parentNbChildren } onEdited={ (parentNbChildren) => this.handleContractEdited(parentNbChildren, 'parentNbChildren') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>CAF rate</th>
						<td>
						{ this.props.regularContract.status === 'valid'
							? <span>{ this.props.regularContract.CAFrate ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red'}} className="fa fa-ban"></i> }</span>
							: <InlineEdit type="checkbox" value={ this.props.regularContract.CAFrate } onEdited={ (CAFrate) => this.handleContractEdited(CAFrate, 'CAFrate') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Payment method</th>
						<td>
							<InlineEdit type="select" options={ [ 'debit', 'transfer', 'check', 'other' ] } value={ this.props.regularContract.paymentMethod } onEdited={ (paymentMethod) => this.handleContractEdited(paymentMethod, 'paymentMethod') }/>
						</td>
					</tr>
				</tbody>
			</Table>
			<ButtonGroup className="mb-4">
				{ this.state.regularOffers.map((regularOffer) => {
					const active = this.props.regularContract.RegularOffers.some((contractRegularOffer) => contractRegularOffer.id === regularOffer.id);
					const color = active ? 'success' : 'info';
					return (
						<Button
							key={ regularOffer.id } color={ color } active={ active }
							style={{ cursor: 'pointer' }}
							disabled={ this.props.regularContract.status === 'valid' }
							onClick={ () => this.toggleRegularOffer(regularOffer.id) }
						>
							{ regularOffer.title }
						</Button>
					);
				}) }
			</ButtonGroup>
			<hr/>
			<h4 className="text-right ml-auto">
				Invoices{' '}<i className="fa fa-money"></i>
			</h4>
			<Table size="sm">
				<thead>
					<tr>
						<th>Month</th>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ contractInvoices.map((contractInvoice) => (
						<tr key={ contractInvoice.year + '-' + contractInvoice.month }>
							<th scope="row" style={{ width: '25%'}}>{ contractInvoice.year } { monthNames[contractInvoice.month] }</th>
							<td>{ contractInvoice.invoice ? (contractInvoice.invoice.amount/100)+'€' : '' }</td>
							<td>{ contractInvoice.invoice ?
								<InlineEdit type="number" step={0.01} value={ contractInvoice.invoice.paidAmount/100 } onEdited={ (paidAmount) => this.props.updateRegularContractInvoice(contractInvoice.invoice.id, { paidAmount: paidAmount*100 }) } />
								: '' }
							</td>
							<td>{ contractInvoice.invoice ?
								((contractInvoice.invoice.amount-contractInvoice.invoice.paidAmount)/100)+'€' : '' }
							</td>
							<td className="text-right">
								{ contractInvoice.invoice && contractInvoice.invoice.status === 'edited'
									&& (
										<React.Fragment>
										<Button color="warning" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.props.sendRegularContractInvoice(contractInvoice.invoice.id) }
										>
											<i className="fa fa-envelope-o"></i>{' '}Send
										</Button>
										<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.props.removeRegularContractInvoice(contractInvoice.invoice.id) }
										>
											<i className="fa fa-trash"></i>{' '}Remove
										</Button>
										</React.Fragment>
									)
								}
								{ contractInvoice.invoice && contractInvoice.invoice.status === 'sent'
									&& (
										<React.Fragment>
										<Button color="success" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
											onClick={ () => this.props.updateRegularContractInvoice(contractInvoice.invoice.id, { status: 'paid', paidAmount: contractInvoice.invoice.amount }) }
										>
											<i className="fa fa-check"></i>{' '}Mark as paid
										</Button>
										<Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-envelope"></i>{' '}Sent</Button>
										</React.Fragment>
									)
								}
								{ contractInvoice.invoice && contractInvoice.invoice.status === 'paid'
									&& (
										<React.Fragment>
										<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
											onClick={ () => this.props.updateRegularContractInvoice(contractInvoice.invoice.id, { status: 'sent', paidAmount: 0 }) }
										>
											<i className="fa fa-times"></i>{' '}Mark as unpaid
										</Button>
										<Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-check"></i>{' '}Paid</Button>
										</React.Fragment>
									)
								}
								{ contractInvoice.invoice
									&& <Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '8rem' }}
										onClick={ () => this.downloadInvoice(contractInvoice.invoice.id) }
									>
										{ this.state.downloadingInvoiceId !== contractInvoice.invoice.id && <React.Fragment>
											<i className="fa fa-download"></i>{' '}Download
										</React.Fragment> }
										<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingInvoiceId === contractInvoice.invoice.id} />
									</Button>
								}
								{ contractInvoice.invoice
									&& <Button color="secondary" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.toggleInvoiceModifications(contractInvoice.invoice.id) }
									>
										<i className="fa fa-file-text"></i>{' '}Modifications ({contractInvoice.invoice.InvoiceModifications.length})
									</Button>
								}
								<Button color="primary" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
									onClick={ () => this.props.generateRegularContractInvoice(contractInvoice.year, contractInvoice.month) }
								>
									<i className="fa fa-file-text"></i>{' '}Generate
								</Button>
							</td>
						</tr>
					)) }
				</tbody>
			</Table>
			<Modal isOpen={this.state.showInvoiceModifications}>
				<ModalHeader>Invoice modifications</ModalHeader>
				<ModalBody>
					{ this.state.editedModificationsInvoiceId !== 0 &&
						<React.Fragment>
						<Table striped hover size="sm">
							<thead>
								<tr>
									<th>Category</th>
									<th>Title</th>
									<th>Amount</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{ contractInvoices.find((i) => i.invoice.id === this.state.editedModificationsInvoiceId).invoice.InvoiceModifications.map((im) => (
									<tr>
										<td>{im.InvoiceModificationCategoryId ? this.state.invoiceModificationCategoriesById[im.InvoiceModificationCategoryId].title : 'Unknown'}</td>
										<td>{im.title}</td>
										<td>{(im.amount/100)+'€'}</td>
										<td><Button color="danger" size="sm" style={{cursor: 'pointer'}} onClick={() => {
											this.handleRemoveRegularContractInvoiceModification(im.id);
										}}>
											<i className="fa fa-trash"></i></Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<p style={{ color: 'white', backgroundColor: 'rgb(23, 178, 168)', cursor: 'pointer' }} className="p-2 d-flex align-items-center" onClick={ this.toggleShowNewInvoiceModification }>
							New invoice modification
							{ this.state.showNewInvoiceModificationForm ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
						</p>
						<Collapse isOpen={this.state.showNewInvoiceModificationForm}>
							<Form onSubmit={ this.handleAddRegularContractInvoiceModification }>
								<FormGroup row>
									<Label for="category" sm={2}>Category</Label>
									<Col sm={4}>
										<Input type="select" value={ this.state.newInvoiceModification.InvoiceModificationCategoryId } onChange={ (e) => this.handleNewInvoiceModificationEdited(parseInt(e.target.value, 10), 'InvoiceModificationCategoryId') }>
											<option value={ 0 }>-- unknown --</option>
											{ this.state.invoiceModificationCategories.map((imc) => (
												<option key={ imc.id } value={ imc.id }>{ imc.title }</option>
											)) }
										</Input>
									</Col>
									<Label for="title" sm={2}>Title</Label>
									<Col sm={4}>
										<Input type="text" value={this.state.newInvoiceModification.title} onChange={(e) => { this.handleNewInvoiceModificationEdited(e.target.value, 'title'); }} />
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="amount" sm={2}>Amount</Label>
									<Col sm={4}>
										<Input type="number" step={0.01} value={this.state.newInvoiceModification.amount/100} onChange={(e) => { this.handleNewInvoiceModificationEdited(parseFloat(e.target.value)*100, 'amount'); }} />
									</Col>
									<Col sm={4}>
										<Button color="primary" style={{ cursor: 'pointer' }}>
											<i className="fa fa-file-text"></i>{' '}Add
										</Button>
									</Col>
								</FormGroup>
							</Form>
						</Collapse>
						</React.Fragment>
					}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={ this.toggleInvoiceModifications }>Ok</Button>
				</ModalFooter>
			</Modal>
			</React.Fragment>
		);
	}
};

AdminChildDetailRegularContract.propTypes = {
	regularContract: PropTypes.object.isRequired,
	updateRegularContract: PropTypes.func.isRequired,
	generateRegularContractInvoice: PropTypes.func.isRequired,
	removeRegularContractInvoice: PropTypes.func.isRequired,
	downloadRegularContractInvoice: PropTypes.func.isRequired,
	sendRegularContractInvoice: PropTypes.func.isRequired,
	updateRegularContractInvoice: PropTypes.func.isRequired,
	addRegularOfferToRegularContract: PropTypes.func.isRequired,
	removeRegularOfferFromRegularContract: PropTypes.func.isRequired,
	addRegularContractInvoiceModification: PropTypes.func.isRequired,
	removeRegularContractInvoiceModification: PropTypes.func.isRequired
};

export default AdminChildDetailRegularContract;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Row, Col, Table, Button, Input, Form, FormGroup, Label
} from 'reactstrap';

import ConfirmationModal from 'components/utils/confirmation_modal';
import InlineEdit from 'components/utils/inline_edit';

import { strasbourgDistricts } from 'services/strasbourg_districts';
import { regimesSecu } from 'services/regimes_secu';

import {
	fetchChildrenOfParent, fetchUserOfParent, createUserOfParent,
	addChildToParent, removeChildFromParent
} from 'actions/parent';

class AdminParentDetail extends React.Component {
	state = {
		dataReady: false,
		children: [],
		addChildId: -1,
		user: null,
		removeParentModalOpen: false
	};

	handleParentEdited = (value, prop) => {
		return this.props.updateParent({ [prop]: value });
	};

	handleAddChildIdChanged = (addChildId) => {
		this.setState({ addChildId });
	};

	removeChildFromParent = (childId) => {
		removeChildFromParent(this.props.parent.id, childId)
		.then(() => {
			this.setState((prevState) => ({
				children: prevState.children.filter((c) => c.id !== childId)
			}));
		});
	};

	addChildToParent = () => {
		addChildToParent(this.props.parent.id, this.state.addChildId)
		.then((child) => {
			this.setState((prevState) => ({
				children: [ ...prevState.children, child ]
			}));
		});
	};

	handleCreateUser = () => {
		createUserOfParent(this.props.parent.id)
		.then((user) => {
			this.setState({ user });
		});
	};

	componentDidMount() {
		Promise.all([
			fetchChildrenOfParent(this.props.parent.id),
			fetchUserOfParent(this.props.parent.id)
		])
		.then(([ children, user ]) => {
			this.setState({
				children,
				user,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.parent.id !== prevProps.parent.id) {
			this.setState({ dataReady: false }, () => {
				Promise.all([
					fetchChildrenOfParent(this.props.parent.id),
					fetchUserOfParent(this.props.parent.id)
				])
				.then(([ children, user ]) => {
					this.setState({
						children,
						user,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<div className="text-right">
				<Button size="sm" color="danger" className="mb-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeParentModalOpen: true }) }>
					Remove parent{' '}<i className="fa fa-trash"></i>
				</Button>
			</div>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Firstname</th>
						<td><InlineEdit type="text" value={ this.props.parent.firstname } onEdited={ (firstname) => this.handleParentEdited(firstname, 'firstname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Lastname</th>
						<td><InlineEdit type="text" value={ this.props.parent.lastname } onEdited={ (lastname) => this.handleParentEdited(lastname, 'lastname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel home</th>
						<td><InlineEdit type="text" value={ this.props.parent.telHome } onEdited={ (telHome) => this.handleParentEdited(telHome, 'telHome') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel mobile</th>
						<td><InlineEdit type="text" value={ this.props.parent.telMobile } onEdited={ (telMobile) => this.handleParentEdited(telMobile, 'telMobile') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel pro</th>
						<td><InlineEdit type="text" value={ this.props.parent.telPro } onEdited={ (telPro) => this.handleParentEdited(telPro, 'telPro') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Email</th>
						<td><InlineEdit type="text" value={ this.props.parent.email } onEdited={ (email) => this.handleParentEdited(email, 'email') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Address</th>
						<td><InlineEdit type="text" value={ this.props.parent.address } onEdited={ (address) => this.handleParentEdited(address, 'address') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>District</th>
						<td><InlineEdit type="select" options={ strasbourgDistricts } value={ this.props.parent.district } onEdited={ (district) => this.handleParentEdited(district, 'district') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Profession</th>
						<td><InlineEdit type="text" value={ this.props.parent.profession } onEdited={ (profession) => this.handleParentEdited(profession, 'profession') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Employer</th>
						<td><InlineEdit type="text" value={ this.props.parent.employer } onEdited={ (employer) => this.handleParentEdited(employer, 'employer') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Employer grant</th>
						<td><InlineEdit type="checkbox" value={ this.props.parent.employerGrant } onEdited={ (employerGrant) => this.handleParentEdited(employerGrant, 'employerGrant') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Mother tongue</th>
						<td><InlineEdit type="text" value={ this.props.parent.motherTongue } onEdited={ (motherTongue) => this.handleParentEdited(motherTongue, 'motherTongue') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Régime Sécu</th>
						<td><InlineEdit type="select" options={ regimesSecu } value={ this.props.parent.regimeSecu } onEdited={ (regimeSecu) => this.handleParentEdited(regimeSecu, 'regimeSecu') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>CAF number</th>
						<td><InlineEdit type="text" value={ this.props.parent.cafNumber } onEdited={ (cafNumber) => this.handleParentEdited(cafNumber, 'cafNumber') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Committee member</th>
						<td><InlineEdit type="checkbox" value={ this.props.parent.committeeMember } onEdited={ (committeeMember) => this.handleParentEdited(committeeMember, 'committeeMember') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Playgroup staff</th>
						<td><InlineEdit type="checkbox" value={ this.props.parent.staff } onEdited={ (staff) => this.handleParentEdited(staff, 'staff') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Référence Unique de Mandat (RUM)</th>
						<td><InlineEdit type="text" value={ this.props.parent.rum } onEdited={ (rum) => this.handleParentEdited(rum, 'rum') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>IBAN</th>
						<td><InlineEdit type="text" value={ this.props.parent.iban } onEdited={ (iban) => this.handleParentEdited(iban, 'iban') } /></td>
					</tr>
					<tr>
						<th scope="row">Children</th>
						<td>
							<Row>
								<Col>
								{ this.state.children.map((child) => (
									<React.Fragment key={ child.id }>
									<Button
										tag={ Link } to={ '/admin/children/' + child.id + '/info' }
										color="primary" size="sm" className="mr-1"
										style={{ cursor: 'pointer' }}>
										{ child.firstname } { child.lastname }
									</Button>
									<Button
										color="danger" size="sm" className="mr-4" style={{ cursor: 'pointer' }}
										onClick={ () => this.removeChildFromParent(child.id) }>
										<i className="fa fa-trash"></i>
									</Button>
									</React.Fragment>
								)) }
								</Col>
							</Row>
							<Form className="mt-2" onSubmit={ (e) => { e.preventDefault(); this.addChildToParent(); } }>
								<FormGroup row>
									<Label for="documentTitle" sm={3}>Child id</Label>
									<Col sm={6}>
										<Input type="number" value={ this.state.addChildId } onChange={ (e) => this.handleAddChildIdChanged(e.target.value) } />
									</Col>
									<Button sm={3} color="primary" style={{ cursor: 'pointer' }}>
										<i className="fa fa-user"></i> Add
									</Button>
								</FormGroup>
							</Form>
							{/* <Row>
								<Col>
									<Input type="number" value={ this.state.addChildId } onChange={ (e) => this.handleAddChildIdChanged(e.target.value) } />
									<Button color="primary" style={{ cursor: 'pointer' }} onClick={ this.addChildToParent }>
										<i className="fa fa-user"></i> Add
									</Button>
								</Col>
							</Row> */}
						</td>
					</tr>
					<tr>
						<th scope="row">User</th>
						<td>
						{ this.state.user ?
							<Button
								tag={ Link } to={ '/admin/users/' + this.state.user.id }
								color="primary" size="sm"
								style={{ cursor: 'pointer' }}>
								{ this.state.user.username }
							</Button> :
							<Button
								size="sm" color="danger" style={{ cursor: 'pointer' }}
								onClick={ this.handleCreateUser }>
								Create user account{' '}<i className="fa fa-user"></i>
							</Button>
						}
						</td>
					</tr>
				</tbody>
			</Table>
			<ConfirmationModal
				isOpen={ this.state.removeParentModalOpen }
				text={ 'Are you sure you want to remove this parent (' + this.props.parent.firstname + ' ' + this.props.parent.lastname + ') ?' }
				onConfirm={ () => { this.setState({ removeParentModalOpen: false }); this.props.removeParent(); } }
				onDismiss={ () => { this.setState({ removeParentModalOpen: false }); } }
			/>
			</React.Fragment>
		);
	}
};

AdminParentDetail.propTypes = {
	parent: PropTypes.object.isRequired,
	updateParent: PropTypes.func.isRequired,
	removeParent: PropTypes.func.isRequired
};

export default AdminParentDetail;

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const fetchChildren = () => {
	return fetch(apiBasename + '/child', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const updateChild = (id, changes) => {
	return fetch(apiBasename + '/child/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeChild = (id) => {
	return fetch(apiBasename + '/child/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

export const fetchParentsOfChild = (id) => {
	return fetch(apiBasename + '/child/' + id + '/parents', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularContractsOfChild = (id) => {
	return fetch(apiBasename + '/child/' + id + '/regularContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularContractsOfChildOfSchoolYear = (childId, schoolYearId) => {
	return fetch(apiBasename + '/child/' + childId + '/regularContracts?SchoolYearId=' + schoolYearId, authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addRegularContractToChild = (id, regularContract) => {
	return fetch(apiBasename + '/child/' + id + '/regularContracts', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(regularContract)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchPunctualContractsOfChild = (id) => {
	return fetch(apiBasename + '/child/' + id + '/punctualContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchPunctualContractsOfChildOfSchoolYear = (childId, schoolYearId) => {
	return fetch(apiBasename + '/child/' + childId + '/punctualContracts?SchoolYearId=' + schoolYearId, authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addPunctualContractToChild = (id, punctualContract) => {
	return fetch(apiBasename + '/child/' + id + '/punctualContracts', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(punctualContract)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayContractsOfChild = (id) => {
	return fetch(apiBasename + '/child/' + id + '/holidayContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayContractsOfChildOfSchoolYear = (childId, schoolYearId) => {
	return fetch(apiBasename + '/child/' + childId + '/holidayContracts?SchoolYearId=' + schoolYearId, authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addHolidayContractToChild = (id, holidayContract) => {
	return fetch(apiBasename + '/child/' + id + '/holidayContracts', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(holidayContract)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchDocumentsOfChild = (id) => {
	return fetch(apiBasename + '/child/' + id + '/documents', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addDocumentToChild = (id, doc) => {
	const formData = new FormData();
	formData.append('doc', doc.file);
	return fetch(apiBasename + '/child/' + id + '/documents', authorization({
		method: 'PUT',
		body: formData
	}))
	.then(() => fetch(apiBasename + '/child/' + id + '/documents', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			title: doc.title,
			filename: doc.file.name
		})
	})))
	.then(checkStatus)
	.then((res) => res.json());
};

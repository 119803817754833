import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Route, Link } from 'react-router-dom';
import {
	Col, Row,
	Badge, Button, Collapse, Table,
	Form, FormGroup, Label, Input
} from 'reactstrap';
import format from 'date-fns/format';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import isEqual from 'date-fns/is_equal';
import compareAsc from 'date-fns/compare_asc';

import { ConfigContext } from 'contexts/config';

import { saveAs } from 'file-saver';

import { fetchChildren } from 'actions/child';
import { fetchSchoolYears, fetchHolidayContractsOfSchoolYear } from 'actions/school_year';
import {
	updateHolidayContract,
	removeHolidayContract,
	addHolidayOfferToHolidayContract,
	removeHolidayOfferFromHolidayContract,
	generateHolidayContractInvoice
} from 'actions/holiday_contract';
import {
	sendHolidayContractInvoice,
	fetchHolidayContractInvoice,
	removeHolidayContractInvoice,
	updateHolidayContractInvoice,
	addHolidayContractInvoiceModification,
	removeHolidayContractInvoiceModification
} from 'actions/holiday_contract_invoice';

import { debounce } from 'services/debounce';
import ConfirmationModal from 'components/utils/confirmation_modal';
import Paginate from 'components/utils/paginate';

import AdminHolidayContractDetail from 'components/admin/contracts/holiday_contract';

class AdminHolidayContracts extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		schoolYearsById: null,
		children: [],
		childrenById: null,
		holidayContracts: [],
		showFilters: false,
		filters: {
			remote: {
				schoolYearId: this.props.currentSchoolYearId,
			},
			local: {
				contractStatus: 'valid',
				invoiceStatus: 'any',
				minStartDate: '',
				maxStartDate: '',
				lastname: ''
			}
		},
		filteredHolidayContracts: [],
		paginationStartIndex: 0,
		paginationEndIndex: 0,
		paginationCurrentPage: 1,
		removeContractModalOpen: false
	};

	updateHolidayContract = (holidayContractId, changes) => {
		return updateHolidayContract(holidayContractId, changes)
		.then((holidayContract) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc);
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	generateHolidayContractInvoice = (holidayContractId) => {
		return generateHolidayContractInvoice(holidayContractId)
		.then((invoice) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: invoice
					} : hc;
				});
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	downloadHolidayContractInvoice = (invoiceId, filename) => {
		return fetchHolidayContractInvoice(invoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};

	sendHolidayContractInvoice = (holidayContractId, invoiceId) => {
		return sendHolidayContractInvoice(invoiceId)
		.then((invoice) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: { ...hc.HolidayContractInvoice, ...invoice }
					} : hc;
				});
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	removeHolidayContractInvoice = (holidayContractId, invoiceId) => {
		return removeHolidayContractInvoice(invoiceId)
		.then(() => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: null
					} : hc;
				});
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	updateHolidayContractInvoice = (holidayContractId, invoiceId, changes) => {
		return updateHolidayContractInvoice(invoiceId, changes)
		.then((invoice) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: { ...hc.HolidayContractInvoice, ...invoice }
					} : hc;
				});
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	addHolidayContractInvoiceModification = (holidayContractId, invoiceId, modif) => {
		return addHolidayContractInvoiceModification(invoiceId, modif)
		.then((invoiceModification) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: {
							...hc.HolidayContractInvoice,
							InvoiceModifications: [ ...hc.HolidayContractInvoice.InvoiceModifications, invoiceModification ]
						}
					} : hc;
				})
			}));
		});
	};

	removeHolidayContractInvoiceModification = (holidayContractId, invoiceId, modifId) => {
		return removeHolidayContractInvoiceModification(invoiceId, modifId)
		.then(() => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: {
							...hc.HolidayContractInvoice,
							InvoiceModifications: hc.HolidayContractInvoice.InvoiceModifications.filter((m) => m.id !== modifId)
						}
					} : hc;
				})
			}));
		});
	};

	addHolidayOfferToHolidayContract = (holidayContractId, holidayOffer) => {
		return addHolidayOfferToHolidayContract(holidayContractId, holidayOffer)
		.then((holidayContract) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc);
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	removeHolidayOfferFromHolidayContract = (holidayContractId, holidayOfferId) => {
		return removeHolidayOfferFromHolidayContract(holidayContractId, holidayOfferId)
		.then((holidayContract) => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc);
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};

	handleRemoveHolidayContract = (holidayContractId) => {
		return removeHolidayContract(holidayContractId)
		.then(() => {
			this.setState((prevState) => {
				const holidayContracts = prevState.holidayContracts.filter((c) => c.id !== holidayContractId);
				return {
					holidayContracts,
					filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
				};
			});
		});
	};
	
	toggleFilters = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
	};
	
	handleFilterChange = (value, prop) => {
		if (prop === 'schoolYearId') { // remote filter
			this.setState((prevState) => ({
				dataReady: false,
				filters: {
					remote: {
						...prevState.filters.remote,
						[prop]: value,
					},
					local: {
						...prevState.filters.local,
						minStartDate: this.state.schoolYearsById[value].startDate,
						maxStartDate: this.state.schoolYearsById[value].endDate
					}
				}
			}), () => {
				fetchHolidayContractsOfSchoolYear(this.state.filters.remote.schoolYearId)
				.then((holidayContracts) => {
					this.setState({
						holidayContracts,
						dataReady: true,
						filteredHolidayContracts: this.filteredHolidayContracts(holidayContracts)
					});
				});
			});
		} else { // local filter
			this.setState((prevState) => ({
				filters: {
					remote: { ...prevState.filters.remote },
					local: {
						...prevState.filters.local,
						[prop]: value
					}
				}
			}), this.debouncedUpdateFilteredHolidayContracts);
		}
	};

	filteredHolidayContracts = (holidayContracts) => {
		return holidayContracts
		.filter((holidayContract) => {
			const child = this.state.childrenById[holidayContract.ChildId];
			const invoice = holidayContract.HolidayContractInvoice;
			holidayContract.startDate = holidayContract.HolidayOffers.length > 0 ?
				holidayContract.HolidayOffers.reduce(
					(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
					holidayContract.HolidayOffers[0].Day.date
				) :
				this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate;

			return child.lastname.toLowerCase().includes(this.state.filters.local.lastname.toLowerCase()) &&
				(this.state.filters.local.contractStatus === 'any' || holidayContract.status === this.state.filters.local.contractStatus) &&
				(this.state.filters.local.invoiceStatus === 'any' || (!invoice && this.state.filters.local.invoiceStatus === 'not edited') || (invoice && invoice.status === this.state.filters.local.invoiceStatus)) &&
				(isAfter(holidayContract.startDate, this.state.filters.local.minStartDate) || isEqual(holidayContract.startDate, this.state.filters.local.minStartDate)) &&
				isBefore(holidayContract.startDate, this.state.filters.local.maxStartDate);
		})
		.sort((a, b) => compareAsc(a.startDate, b.startDate));
	};
	updateFilteredHolidayContracts = () => {
		this.setState({
			filteredHolidayContracts: this.filteredHolidayContracts(this.state.holidayContracts)
		});
	};
	debouncedUpdateFilteredHolidayContracts = debounce(this.updateFilteredHolidayContracts, 500, false);

	pageChanged = (paginationStartIndex, paginationEndIndex, paginationCurrentPage) => {
		this.setState({ paginationStartIndex, paginationEndIndex, paginationCurrentPage });
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYears(),
			fetchChildren(),
			fetchHolidayContractsOfSchoolYear(this.state.filters.remote.schoolYearId)
		])
		.then(([ schoolYears, children, holidayContracts ]) => {
			const schoolYearsById = {};
			schoolYears.forEach((s) => schoolYearsById[s.id] = s);
			const childrenById = {};
			children.forEach((c) => childrenById[c.id] = c);
			this.setState((prevState) => ({
				schoolYears,
				schoolYearsById,
				children,
				childrenById,
				holidayContracts,
				filters: {
					remote: { ...prevState.filters.remote, },
					local: {
						...prevState.filters.local,
						minStartDate: schoolYearsById[this.props.currentSchoolYearId].startDate,
						maxStartDate: schoolYearsById[this.props.currentSchoolYearId].endDate
					}
				}
			}), () => {
				this.setState({
					dataReady: true,
					filteredHolidayContracts: this.filteredHolidayContracts(this.state.holidayContracts)
				});
			});
		});
	}

	render() {
		const filterParagraphStyle = {
			color: 'white',
			backgroundColor: 'rgba(52, 58, 64, 0.60)',
			cursor: 'pointer'
		};
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row>
				<Col>
					<p style={ filterParagraphStyle } className="p-2 d-flex align-items-center" onClick={ this.toggleFilters }>
						Filters
						<Badge className="ml-3" color="info">{ this.state.filteredHolidayContracts.length }</Badge>
						{ this.state.showFilters ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
					</p>
					<Collapse isOpen={ this.state.showFilters }>
						<Form onSubmit={ (e) => e.preventDefault() }>
							<FormGroup row>
								<Label for="schoolYear" sm={2}>School year</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.remote.schoolYearId } onChange={ (e) => this.handleFilterChange(e.target.value, 'schoolYearId') } name="schoolYear" id="schoolYear">
										{ this.state.schoolYears.map((schoolYear) => (
											<option key={ schoolYear.id } value={ parseInt(schoolYear.id, 10) }>{ schoolYear.title }</option>
										)) }
									</Input>
								</Col>
								<Label for="lastname" sm={2}>Lastname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.local.lastname } onChange={ (e) => this.handleFilterChange(e.target.value, 'lastname') } name="lastname" id="lastname" placeholder="Lastname" />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="contractStatus" sm={2}>Contract status</Label>
								<Col sm={4}>
									<Input type="select" className="fa" value={ this.state.filters.local.contractStatus } onChange={ (e) => this.handleFilterChange(e.target.value, 'contractStatus') } name="contractStatus" id="contractStatus">
										<option value="any">-- any --</option>
										<option value="valid">valid</option>
										<option value="demand">demand</option>
									</Input>
								</Col>
								<Label for="invoiceStatus" sm={2}>Invoice status</Label>
								<Col sm={4}>
									<Input type="select" className="fa" value={ this.state.filters.local.invoiceStatus } onChange={ (e) => this.handleFilterChange(e.target.value, 'invoiceStatus') } name="invoiceStatus" id="invoiceStatus">
										<option value="any">-- any --</option>
										<option value="not edited">not edited</option>
										<option value="edited">edited</option>
										<option value="sent">sent</option>
										<option value="paid">paid</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="minStartDate" sm={2}>Start date between</Label>
								<Col sm={2}>
									<Input type="date"
										min={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate }
										max={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].endDate }
										value={ this.state.filters.local.minStartDate }
										onChange={ (e) => this.handleFilterChange(e.target.value, 'minStartDate') } name="minStartDate" id="minStartDate"
									/>
								</Col>
								<Col sm={2}>
									<Input type="date" 
										min={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate }
										max={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].endDate }
										value={ this.state.filters.local.maxStartDate }
										onChange={ (e) => this.handleFilterChange(e.target.value, 'maxStartDate') } name="maxStartDate" id="maxStartDate"
									/>
								</Col>
							</FormGroup>
						</Form>
						<hr />
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<Route path={ this.props.match.url } exact render={ () => (
						<React.Fragment>
						<Paginate nbItems={ this.state.filteredHolidayContracts.length } onPageChange={ this.pageChanged } initialPage={ this.state.paginationCurrentPage } />
						<Table striped hover size="sm">
							<thead>
								<tr>
									<th>Child</th>
									<th>Start date</th>
									<th>End date</th>
									<th>Demand date</th>
									<th>Contract status</th>
									<th>Invoice status</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
							{ this.state.filteredHolidayContracts.slice(this.state.paginationStartIndex, this.state.paginationEndIndex + 1).map((holidayContract) => {
								const child = this.state.childrenById[holidayContract.ChildId];
								const invoice = holidayContract.HolidayContractInvoice;
								const startDate = holidayContract.HolidayOffers.length > 0 ?
									format(holidayContract.HolidayOffers.reduce(
										(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
										holidayContract.HolidayOffers[0].Day.date
									), 'DD-MM-YYYY') :
									'???';
								const endDate = holidayContract.HolidayOffers.length > 0 ?
									format(holidayContract.HolidayOffers.reduce(
										(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
										holidayContract.HolidayOffers[0].Day.date
									), 'DD-MM-YYYY') :
									'???';
								
								return (
									<tr key={ holidayContract.id }>
										<td>
											{ child.firstname } { child.lastname }
											<Link to={ '/admin/children/' + child.id + '/info' } className="ml-2"><i className="fa fa-external-link"></i></Link>
										</td>
										<td>{ startDate }</td>
										<td>{ endDate }</td>
										<td>{ format(holidayContract.createdAt, 'DD-MM-YYYY') }</td>
										<td>
											{ holidayContract.status === 'valid' ?
												<i style={{ color: 'green' }} className="fa fa-check"></i> :
												<i style={{ color: 'red' }} className="fa fa-ban"></i>
											}
										</td>
										<td>
											{
												invoice && invoice.status === 'edited' ?
												<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
												invoice && invoice.status === 'sent' ?
												<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
												invoice && invoice.status === 'paid' ?
												<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment> :
												<React.Fragment><i style={{ color: '#CC0000' }} className="fa fa-ban"></i>{' '}<span>not edited</span></React.Fragment>
											}
										</td>
										<td className="text-right">
											{ holidayContract.status === 'demand' &&
											<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeContractId: holidayContract.id, removeContractModalOpen: true }) }>
												<i className="fa fa-trash"></i>
											</Button> }
											<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/' + holidayContract.id }>
												<i className="fa fa-search"></i>
											</Button>
										</td>
									</tr>
								);
							}) }
							</tbody>
						</Table>
						<ConfirmationModal
							isOpen={ this.state.removeContractModalOpen }
							text="Are you sure you want to remove this contract ?"
							onConfirm={ () => { this.handleRemoveHolidayContract(this.state.removeContractId); this.setState({ removeContractId: 0, removeContractModalOpen: false }); } }
							onDismiss={ () => { this.setState({ removeContractId: 0, removeContractModalOpen: false }); } }
						/>
						</React.Fragment>
					) } />
					<Route path={ this.props.match.url + '/:contractId' } render={ (props) => {
						const contract = this.state.holidayContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
						const child = this.state.childrenById[contract.ChildId];
						return (
							<React.Fragment>
								<Row>
									<Col>
										<h3 style={{ display: 'inline' }}>{ child.firstname } { child.lastname }</h3>
										<Button style={{ verticalAlign: 'top' }} size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url }>
											<i className="fa fa-list"></i>{' '}Back to list
										</Button>
									</Col>
								</Row>
								<AdminHolidayContractDetail
									holidayContract={ contract }
									updateHolidayContract={ (changes) => this.updateHolidayContract(contract.id, changes) }
									generateHolidayContractInvoice={ () => this.generateHolidayContractInvoice(contract.id) }
									removeHolidayContractInvoice={ (invoiceId) => this.removeHolidayContractInvoice(contract.id, invoiceId) }
									downloadHolidayContractInvoice={ (invoiceId, filename) => this.downloadHolidayContractInvoice(invoiceId, filename) }
									sendHolidayContractInvoice={ (invoiceId) => this.sendHolidayContractInvoice(contract.id, invoiceId) }
									updateHolidayContractInvoice={ (invoiceId, changes) => this.updateHolidayContractInvoice(contract.id, invoiceId, changes) }
									addOfferToContract={ (offer) => this.addHolidayOfferToHolidayContract(contract.id, offer) }
									removeOfferFromContract={ (offerId) => this.removeHolidayOfferFromHolidayContract(contract.id, offerId) }
									addHolidayContractInvoiceModification={ (invoiceId, modif) => this.addHolidayContractInvoiceModification(contract.id, invoiceId, modif) }
									removeHolidayContractInvoiceModification={ (invoiceId, modifId) => this.removeHolidayContractInvoiceModification(contract.id, invoiceId, modifId) }
								/>
							</React.Fragment>
						);
					 } } />
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

AdminHolidayContracts.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminHolidayContracts);

import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Route, Link } from 'react-router-dom';
import {
	Col, Row,
	Badge, Button, Collapse, Table,
	Form, FormGroup, Label, Input,
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
	UncontrolledTooltip
} from 'reactstrap';
import format from 'date-fns/format';
import getYear from 'date-fns/get_year';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfMonth from 'date-fns/start_of_month';
import addMonths from 'date-fns/add_months';
import isBefore from 'date-fns/is_before';
import isWithinRange from 'date-fns/is_within_range';
import compareAsc from 'date-fns/compare_asc';

import { saveAs } from 'file-saver';

import { ConfigContext } from 'contexts/config';

import { fetchParents } from 'actions/parent';
import { fetchChildren } from 'actions/child';
import { fetchSchoolYears, fetchRegularContractsOfSchoolYear, fetchRegularOffersOfSchoolYear } from 'actions/school_year';
import {
	updateRegularContract,
	removeRegularContract,
	addRegularOfferToRegularContract,
	removeRegularOfferFromRegularContract,
	generateRegularContractInvoice
} from 'actions/regular_contract';
import {
	sendRegularContractInvoice,
	fetchRegularContractInvoice,
	removeRegularContractInvoice,
	updateRegularContractInvoice,
	addRegularContractInvoiceModification,
	removeRegularContractInvoiceModification
} from 'actions/regular_contract_invoice';

import { monthNames, isMonthWithinRange } from 'services/calendar';
import { debounce } from 'services/debounce';
import ConfirmationModal from 'components/utils/confirmation_modal';
import Paginate from 'components/utils/paginate';

import AdminRegularContractDetail from 'components/admin/contracts/regular_contract';

class AdminRegularContracts extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		schoolYearsById: null,
		children: [],
		childrenById: null,
		regularOffers: [],
		regularOffersById: null,
		regularContracts: [],
		showFilters: false,
		filters: {
			remote: {
				schoolYearId: this.props.currentSchoolYearId,
			},
			local: {
				contractStatus: 'valid',
				invoiceMonth: -1,
				invoiceStatus: 'any',
				minStartDate: '',
				maxStartDate: '',
				lastname: '',
				birthYear: 0,
				hasScolarite: 'any'
			}
		},
		filteredRegularContracts: [],
		paginationStartIndex: 0,
		paginationEndIndex: 0,
		paginationCurrentPage: 1,
		removeContractId: 0,
		removeContractModalOpen: false,
		exportDropdownOpen: false
	};

	updateRegularContract = (regularContractId, changes) => {
		return updateRegularContract(regularContractId, changes)
		.then((regularContract) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc);
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	generateRegularContractInvoice = (regularContractId, year, month) => {
		return generateRegularContractInvoice(regularContractId, year, month)
		.then((invoice) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.some((i) => i.id === invoice.id) ?
							rc.RegularContractInvoices.map((i) => i.id === invoice.id ? { ...i, ...invoice } : i) :
							[ ...rc.RegularContractInvoices, invoice ]
					} : rc;
				});
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	downloadRegularContractInvoice = (invoiceId, filename) => {
		return fetchRegularContractInvoice(invoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};

	sendRegularContractInvoice = (regularContractId, invoiceId) => {
		return sendRegularContractInvoice(invoiceId)
		.then((invoice) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => i.id === invoiceId ? { ...i, ...invoice } : i)
					} : rc;
				});
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	removeRegularContractInvoice = (regularContractId, invoiceId) => {
		return removeRegularContractInvoice(invoiceId)
		.then(() => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.filter((i) => i.id !== invoiceId)
					} : rc;
				});
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	updateRegularContractInvoice = (regularContractId, invoiceId, changes) => {
		return updateRegularContractInvoice(invoiceId, changes)
		.then((invoice) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => i.id === invoiceId ? { ...i, ...invoice } : i)
					} : rc;
				});
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	addRegularContractInvoiceModification = (regularContractId, invoiceId, modif) => {
		return addRegularContractInvoiceModification(invoiceId, modif)
		.then((invoiceModification) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => {
							return i.id === invoiceId ? {
								...i,
								InvoiceModifications: [ ...i.InvoiceModifications, invoiceModification ]
							} : i;
						})
					} : rc;
				})
			}));
		});
	};

	removeRegularContractInvoiceModification = (regularContractId, invoiceId, modifId) => {
		return removeRegularContractInvoiceModification(invoiceId, modifId)
		.then(() => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => {
							return i.id === invoiceId ? {
								...i,
								InvoiceModifications: i.InvoiceModifications.filter((m) => m.id !== modifId)
							} : i;
						})
					} : rc;
				})
			}));
		});
	};

	addRegularOfferToRegularContract = (regularContractId, regularOfferId) => {
		return addRegularOfferToRegularContract(regularContractId, regularOfferId)
		.then((regularContract) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc);
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};
	
	removeRegularOfferFromRegularContract = (regularContractId, regularOfferId) => {
		return removeRegularOfferFromRegularContract(regularContractId, regularOfferId)
		.then((regularContract) => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc);
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};

	handleRemoveRegularContract = (regularContractId) => {
		return removeRegularContract(regularContractId)
		.then(() => {
			this.setState((prevState) => {
				const regularContracts = prevState.regularContracts.filter((c) => c.id !== regularContractId);
				return {
					regularContracts,
					filteredRegularContracts: this.filteredRegularContracts(regularContracts)
				};
			});
		});
	};
	
	toggleFilters = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
	};
	
	handleFilterChange = (value, prop) => {
		if (prop === 'schoolYearId') { // remote filter
			this.setState((prevState) => ({
				dataReady: false,
				filters: {
					remote: {
						...prevState.filters.remote,
						[prop]: value,
					},
					local: {
						...prevState.filters.local,
						minStartDate: this.state.schoolYearsById[value].startDate,
						maxStartDate: this.state.schoolYearsById[value].endDate
					}
				}
			}), () => {
				Promise.all([
					fetchRegularContractsOfSchoolYear(this.state.filters.remote.schoolYearId),
					fetchRegularOffersOfSchoolYear(this.state.filters.remote.schoolYearId)
				])
				.then(([ regularContracts, regularOffers ]) => {
					const regularOffersById = {};
					regularOffers.forEach((r) => regularOffersById[r.id] = r);
					this.setState({
						regularOffers,
						regularOffersById,
						regularContracts,
						dataReady: true
					}, this.updateFilteredRegularContracts);
				});
			});
		} else { // local filter
			this.setState((prevState) => ({
				filters: {
					remote: { ...prevState.filters.remote },
					local: {
						...prevState.filters.local,
						[prop]: value
					}
				}
			}), this.debouncedUpdateFilteredRegularContracts);
		}
	};

	filteredRegularContracts = (regularContracts) => {
		return regularContracts
		.filter((regularContract) => {
			const child = this.state.childrenById[regularContract.ChildId];
			let invoice = null;
			if (this.state.filters.local.invoiceMonth !== -1) {
				invoice = regularContract.RegularContractInvoices.find((i) => i.month === this.state.filters.local.invoiceMonth);
			}

			return child.lastname.toLowerCase().includes(this.state.filters.local.lastname.toLowerCase()) &&
				(this.state.filters.local.birthYear === 0 || getYear(child.birthdate) === this.state.filters.local.birthYear) &&
				(this.state.filters.local.contractStatus === 'any' || regularContract.status === this.state.filters.local.contractStatus) &&
				(this.state.filters.local.hasScolarite === 'any' ||
					(this.state.filters.local.hasScolarite === 'yes' && regularContract.RegularOffers.some((contractRegularOffer) => this.state.regularOffersById[contractRegularOffer.id].rate > 0)) ||
					(this.state.filters.local.hasScolarite === 'no' && regularContract.RegularOffers.every((contractRegularOffer) => this.state.regularOffersById[contractRegularOffer.id].rate === null))
				) &&
				(this.state.filters.local.invoiceMonth === -1 ||
					(invoice && (this.state.filters.local.invoiceStatus === 'any' || invoice.status === this.state.filters.local.invoiceStatus)) ||
					(!invoice && (this.state.filters.local.invoiceStatus === 'any' || this.state.filters.local.invoiceStatus === 'not edited') && isMonthWithinRange(this.state.filters.local.invoiceMonth, regularContract.startDate, regularContract.endDate))
				) &&
				isWithinRange(regularContract.startDate, this.state.filters.local.minStartDate, this.state.filters.local.maxStartDate);
		})
		.sort((a, b) => {
			const childa = this.state.childrenById[a.ChildId];
			const childb = this.state.childrenById[b.ChildId];
			const lastnameCompare = childa.lastname.localeCompare(childb.lastname);
			return lastnameCompare !== 0 ? lastnameCompare : compareAsc(a.startDate, b.startDate);
		});
	};
	updateFilteredRegularContracts = () => {
		this.setState({
			filteredRegularContracts: this.filteredRegularContracts(this.state.regularContracts)
		});
	};
	debouncedUpdateFilteredRegularContracts = debounce(this.updateFilteredRegularContracts, 500, false);

	pageChanged = (paginationStartIndex, paginationEndIndex, paginationCurrentPage) => {
		this.setState({ paginationStartIndex, paginationEndIndex, paginationCurrentPage });
	};

	exportContractsCSV = () => {
		Promise.all([
			fetchParents()
		])
		.then(([ parents ]) => {
			let result = 'Id;Demand date;Child firstname;Child lastname;Child birthdate;Disabled;Observations;Previous childcare;Home Language;Parent firstname;Parent lastname;Tel home;Tel mobile;Tel pro;Email;Address;Profession;Employer;Mother tongue';

			const parentsById = {};
			parents.forEach((p) => parentsById[p.id] = p);

			this.state.regularOffers.forEach((ro) => {
				result += ';' + ro.title;
			});
			result += '\n';

			this.state.filteredRegularContracts
			.sort((a, b) => compareAsc(a.createdAt, b.createdAt))
			.forEach((contract) => {
				const child = this.state.childrenById[contract.ChildId];
				const parent = parentsById[child.Parents[0].id];
				
				result += contract.id + ';' + format(contract.createdAt, 'DD-MM-YYYY');
				
				result += ';' + child.firstname + ';' + child.lastname + ';' + child.birthdate + ';' +
					(child.disabledStatus ? 'X' : '') + ';' +
					(child.observations ? child.observations.replace(/;/g, '.').replace(/\n/g, '.') : '') + ';' +
					(child.previousChildcare ? child.previousChildcare.replace(/;/g, '.').replace(/\n/g, '.') : '') + ';' +
					(child.homeLanguage ? child.homeLanguage.replace(/;/g, '.').replace(/\n/g, '.') : '');
				
				result += ';' + parent.firstname + ';' + parent.lastname + ';' +
					parent.telHome + ';' + parent.telMobile + ';' + parent.telPro + ';' +
					parent.email + ';' + parent.address + ';' +
					parent.profession + ';' + parent.employer + ';' + parent.motherTongue;
				
				const offers = contract.RegularOffers.map((r) => r.id);
				this.state.regularOffers.forEach((ro) => {
					result += ';' + (offers.includes(ro.id) ? 'X' : '');
				});
				result += '\n';
			});
			const blob = new Blob([ result ], { type: 'text/csv;charset=utf-8' });
			saveAs(blob, 'exportContracts.csv');
		});
	};

	exportParentsCSV = () => {
		let result = 'Id;Child firstname;Child lastname;Child birthdate;Scolarité;Parent firstname;Parent lastname;Tel home;Tel mobile;Tel pro;Email;Address;Committee member;Régime Sécu;N° CAF\n';
		fetchParents()
		.then((parents) => {
			const parentsById = {};
			parents.forEach((p) => parentsById[p.id] = p);
			this.state.filteredRegularContracts
			.sort((a, b) => compareAsc(this.state.childrenById[a.ChildId].birthdate, this.state.childrenById[b.ChildId].birthdate))
			.forEach((contract) => {
				const child = this.state.childrenById[contract.ChildId];
				const hasScolarite = contract.RegularOffers.some((contractRegularOffer) => this.state.regularOffersById[contractRegularOffer.id].rate > 0);
				result += child.id + ';' + child.firstname + ';' + child.lastname + ';' + format(child.birthdate, 'DD-MM-YYYY') + ';' + (hasScolarite ? 'yes' : 'no');
				const parents = child.Parents.map((p) => parentsById[p.id]);
				parents.forEach((parent, idx) => {
					if (idx > 0) {
						result += ';;;;';
					}
					result += ';' + parent.firstname + ';' + parent.lastname + ';"=""' +
						parent.telHome + '""";"=""' + parent.telMobile + '""";"=""' + parent.telPro + '""";' +
						parent.email + ';' + parent.address + ';' + (parent.committeeMember ? 'yes' : 'no') + ';' + parent.regimeSecu + ';' + parent.cafNumber + '\n';
				});
			});
			const blob = new Blob([ result ], { type: 'text/csv;charset=utf-8' });
			saveAs(blob, 'exportParents.csv');
		});
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYears(),
			fetchChildren(),
			fetchRegularContractsOfSchoolYear(this.state.filters.remote.schoolYearId),
			fetchRegularOffersOfSchoolYear(this.state.filters.remote.schoolYearId)
		])
		.then(([ schoolYears, children, regularContracts, regularOffers ]) => {
			const schoolYearsById = {};
			schoolYears.forEach((s) => schoolYearsById[s.id] = s);
			const childrenById = {};
			children.forEach((c) => childrenById[c.id] = c);
			const regularOffersById = {};
			regularOffers.forEach((r) => regularOffersById[r.id] = r);
			this.setState((prevState) => ({
				schoolYears,
				schoolYearsById,
				children,
				childrenById,
				regularOffers,
				regularOffersById,
				regularContracts,
				filters: {
					remote: { ...prevState.filters.remote, },
					local: {
						...prevState.filters.local,
						minStartDate: schoolYearsById[this.props.currentSchoolYearId].startDate,
						maxStartDate: schoolYearsById[this.props.currentSchoolYearId].endDate
					}
				}
			}), () => {
				this.setState({
					dataReady: true,
					filteredRegularContracts: this.filteredRegularContracts(this.state.regularContracts)
				});
			});
		});
	}

	render() {
		const filterParagraphStyle = {
			color: 'white',
			backgroundColor: 'rgba(52, 58, 64, 0.60)',
			cursor: 'pointer'
		};
		const yearMonths = [];
		if (this.state.dataReady) {
			let d = startOfMonth(this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate);
			const end = lastDayOfMonth(this.state.schoolYearsById[this.state.filters.remote.schoolYearId].endDate);
			while (isBefore(d, end)) {
				yearMonths.push(d.getMonth());
				d = addMonths(d, 1);
			}
		}
		const birthYears = [];
		this.state.children.forEach((c) => {
			const y = getYear(c.birthdate);
			if (!birthYears.includes(y)) {
				birthYears.push(y);
			}
		});
		birthYears.sort((a, b) => a - b);

		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row>
				<Col>
					<p style={ filterParagraphStyle } className="p-2 d-flex align-items-center" onClick={ this.toggleFilters }>
						Filters
						<Badge className="ml-3" color="info">{ this.state.filteredRegularContracts.length }</Badge>
						{ this.state.showFilters ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
					</p>
					<Collapse isOpen={ this.state.showFilters }>
						<Form onSubmit={ (e) => e.preventDefault() }>
							<FormGroup row>
								<Label for="schoolYear" sm={2}>School year</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.remote.schoolYearId } onChange={ (e) => this.handleFilterChange(parseInt(e.target.value, 10), 'schoolYearId') } name="schoolYear" id="schoolYear">
										{ this.state.schoolYears.map((schoolYear) => (
											<option key={ schoolYear.id } value={ parseInt(schoolYear.id, 10) }>{ schoolYear.title }</option>
										)) }
									</Input>
								</Col>
								<Label for="birthYear" sm={2}>Birth year</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.local.birthYear } onChange={ (e) => this.handleFilterChange(parseInt(e.target.value, 10), 'birthYear') } name="birthYear" id="birthYear">
										<option value={ 0 }>-- any --</option>
										{ birthYears.map((y) => (
											<option key={ y } value={ y }>{ y }</option>
										)) }
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="lastname" sm={2}>Lastname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.local.lastname } onChange={ (e) => this.handleFilterChange(e.target.value, 'lastname') } name="lastname" id="lastname" placeholder="Lastname" />
								</Col>
								<Label for="hasScolarite" sm={2}>Has scolarité</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.local.hasScolarite } onChange={ (e) => this.handleFilterChange(e.target.value, 'hasScolarite') } name="hasScolarite" id="hasScolarite">
										<option value={ 'any' }>-- any --</option>
										<option value={ 'yes' }>yes</option>
										<option value={ 'no' }>no</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="contractStatus" sm={2}>Contract status</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.local.contractStatus } onChange={ (e) => this.handleFilterChange(e.target.value, 'contractStatus') } name="contractStatus" id="contractStatus">
										<option value="any">-- any --</option>
										<option value="valid">valid</option>
										<option value="demand">demand</option>
									</Input>
								</Col>
								<Label for="invoiceMonth" sm={2}>Invoice status</Label>
								<Col sm={2}>
									<Input type="select" value={ this.state.filters.local.invoiceMonth } onChange={ (e) => this.handleFilterChange(parseInt(e.target.value, 10), 'invoiceMonth') } name="invoiceMonth" id="invoiceMonth">
										<option value={ -1 }>-- any --</option>
										{ yearMonths.map((m) => (
											<option key={ m } value={ m }>{ monthNames[m] }</option>
										)) }
									</Input>
								</Col>
								<Col sm={2}>
									<Input type="select" disabled={ this.state.filters.local.invoiceMonth === -1 } value={ this.state.filters.local.invoiceStatus } onChange={ (e) => this.handleFilterChange(e.target.value, 'invoiceStatus') } name="invoiceStatus" id="invoiceStatus">
										<option value="any">-- any --</option>
										<option value="not edited">not edited</option>
										<option value="edited">edited</option>
										<option value="sent">sent</option>
										<option value="paid">paid</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="minStartDate" sm={2}>Start date between</Label>
								<Col sm={3}>
									<Input type="date"
										min={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate }
										max={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].endDate }
										value={ this.state.filters.local.minStartDate }
										onChange={ (e) => this.handleFilterChange(e.target.value, 'minStartDate') } name="minStartDate" id="minStartDate"
									/>
								</Col>
								<Col sm={3}>
									<Input type="date" 
										min={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].startDate }
										max={ this.state.schoolYearsById[this.state.filters.remote.schoolYearId].endDate }
										value={ this.state.filters.local.maxStartDate }
										onChange={ (e) => this.handleFilterChange(e.target.value, 'maxStartDate') } name="maxStartDate" id="maxStartDate"
									/>
								</Col>
							</FormGroup>
						</Form>
						<hr />
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<Route path={ this.props.match.url } exact render={ () => (
						<React.Fragment>
						<Row>
							<Col>
								<Paginate nbItems={ this.state.filteredRegularContracts.length } onPageChange={ this.pageChanged } initialPage={ this.state.paginationCurrentPage } />
							</Col>
							<Col className="text-right">
								<Dropdown
									isOpen={ this.state.exportDropdownOpen }
									toggle={ () => { this.setState((prevState) => this.setState({ exportDropdownOpen: !prevState.exportDropdownOpen })) } }
									className="mb-2"
								>
									<DropdownToggle caret> Export </DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={ this.exportParentsCSV }> Parents </DropdownItem>
										<DropdownItem onClick={ this.exportContractsCSV }> Contracts </DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</Col>
						</Row>
						<Table striped hover size="sm">
							<thead>
								<tr>
									<th>Child</th>
									<th>Start date</th>
									<th>End date</th>
									<th>Demand date</th>
									<th>Contract status</th>
									<th>Invoices status</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
							{ this.state.filteredRegularContracts.slice(this.state.paginationStartIndex, this.state.paginationEndIndex + 1).map((regularContract) => {
								const child = this.state.childrenById[regularContract.ChildId];
								const contractInvoices = [];
								let d = startOfMonth(regularContract.startDate);
								const end = lastDayOfMonth(regularContract.endDate);
								while (isBefore(d, end)) {
									contractInvoices.push({
										year: d.getFullYear(),
										month: d.getMonth(),
										invoice: regularContract.RegularContractInvoices.find((i) => i.year === d.getFullYear() && i.month === d.getMonth())
									});
									d = addMonths(d, 1);
								}

								return (
									<tr key={ regularContract.id }>
										<td>
											{ child.firstname } { child.lastname }
											<Link to={ '/admin/children/' + child.id + '/info' } className="ml-2"><i className="fa fa-external-link"></i></Link>
										</td>
										<td>{ format(regularContract.startDate, 'DD-MM-YYYY') }</td>
										<td>{ format(regularContract.endDate, 'DD-MM-YYYY') }</td>
										<td>{ format(regularContract.createdAt, 'DD-MM-YYYY') }</td>
										<td>
											{ regularContract.status === 'valid' ?
												<i style={{ color: 'green'}} className="fa fa-check"></i> :
												<i style={{ color: 'red' }} className="fa fa-ban"></i>
											}
										</td>
										<td>
											{ contractInvoices.map((contractInvoice, idx) => {
												const color =
													contractInvoice.invoice && contractInvoice.invoice.status === 'edited' ? '#FF8000' :
													contractInvoice.invoice && contractInvoice.invoice.status === 'sent' ? '#DDDD00' :
													contractInvoice.invoice && contractInvoice.invoice.status === 'paid' ? '#66CC00' :
													'#CC0000';
												const invoiceid = 'invoice_'+regularContract.id+'_'+contractInvoice.year+'_'+contractInvoice.month;
												return (
													<span key={ idx }>
														<Badge style={{ backgroundColor: color }} id={ invoiceid }>
															{ contractInvoice.month + 1 }
														</Badge>
														<UncontrolledTooltip placement="top" delay={{ show: 0, hide: 100 }} autohide={ false } target={ invoiceid }>
															{ contractInvoice.invoice && contractInvoice.invoice.status === 'edited'
																&& <Button color="warning" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
																	onClick={ () => this.sendRegularContractInvoice(regularContract.id, contractInvoice.invoice.id) }
																>
																	<i className="fa fa-envelope-o"></i>
																</Button>
															}
															{ contractInvoice.invoice && contractInvoice.invoice.status === 'sent'
																&& <Button color="success" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
																	onClick={ () => this.updateRegularContractInvoice(regularContract.id, contractInvoice.invoice.id, { status: 'paid', paidAmount: contractInvoice.invoice.amount }) }
																>
																	<i className="fa fa-check"></i> ({ (contractInvoice.invoice.amount - contractInvoice.invoice.paidAmount) / 100 }€)
																</Button>
															}
															{ contractInvoice.invoice && contractInvoice.invoice.status === 'paid'
																&& <Button color="danger" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
																	onClick={ () => this.updateRegularContractInvoice(regularContract.id, contractInvoice.invoice.id, { status: 'sent', paidAmount: 0 }) }
																>
																	<i className="fa fa-times"></i>
																</Button>
															}
															{ contractInvoice.invoice
																&& <Button color="info" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
																	onClick={ () => this.downloadRegularContractInvoice(contractInvoice.invoice.id, contractInvoice.invoice.filename) }
																>
																	<i className="fa fa-download"></i>
																</Button>
															}
															<Button color="primary" size="sm" style={{ cursor: 'pointer' }}
																onClick={ () => this.generateRegularContractInvoice(regularContract.id, contractInvoice.year, contractInvoice.month) }
															>
																<i className="fa fa-file-text"></i>
															</Button>
														</UncontrolledTooltip>
													</span>
												);
											}) }
										</td>
										<td className="text-right">
											{ regularContract.status === 'demand' &&
											<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeContractId: regularContract.id, removeContractModalOpen: true }) }>
												<i className="fa fa-trash"></i>
											</Button> }
											<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/' + regularContract.id }>
												<i className="fa fa-search"></i>
											</Button>
										</td>
									</tr>
								);
							}) }
							</tbody>
						</Table>
						<ConfirmationModal
							isOpen={ this.state.removeContractModalOpen }
							text="Are you sure you want to remove this contract ?"
							onConfirm={ () => { this.handleRemoveRegularContract(this.state.removeContractId); this.setState({ removeContractId: 0, removeContractModalOpen: false }); } }
							onDismiss={ () => { this.setState({ removeContractId: 0, removeContractModalOpen: false }); } }
						/>
						</React.Fragment>
					) } />
					<Route path={ this.props.match.url + '/:contractId' } render={ (props) => {
						const contract = this.state.regularContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
						const child = this.state.childrenById[contract.ChildId];
						return (
							<React.Fragment>
								<Row>
									<Col>
										<h3 style={{ display: 'inline' }}>{ child.firstname } { child.lastname }</h3>
										<Button style={{ verticalAlign: 'top' }} size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url }>
											<i className="fa fa-list"></i>{' '}Back to list
										</Button>
									</Col>
								</Row>
								<AdminRegularContractDetail
									regularContract={ contract }
									updateRegularContract={ (changes) => this.updateRegularContract(contract.id, changes) }
									generateRegularContractInvoice={ (year, month) => this.generateRegularContractInvoice(contract.id, year, month) }
									removeRegularContractInvoice={ (invoiceId) => this.removeRegularContractInvoice(contract.id, invoiceId) }
									downloadRegularContractInvoice={ (invoiceId, filename) => this.downloadRegularContractInvoice(invoiceId, filename) }
									sendRegularContractInvoice={ (invoiceId) => this.sendRegularContractInvoice(contract.id, invoiceId) }
									updateRegularContractInvoice={ (invoiceId, changes) => this.updateRegularContractInvoice(contract.id, invoiceId, changes) }
									addRegularOfferToRegularContract={ (regularOfferId) => this.addRegularOfferToRegularContract(contract.id, regularOfferId) }
									removeRegularOfferFromRegularContract={ (regularOfferId) => this.removeRegularOfferFromRegularContract(contract.id, regularOfferId) }
									addRegularContractInvoiceModification={ (invoiceId, modif) => this.addRegularContractInvoiceModification(contract.id, invoiceId, modif) }
									removeRegularContractInvoiceModification={ (invoiceId, modifId) => this.removeRegularContractInvoiceModification(contract.id, invoiceId, modifId) }
								/>
							</React.Fragment>
						);
					} } />
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

AdminRegularContracts.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminRegularContracts);

import React from 'react';
import PropTypes from 'prop-types';
import {
	Table, Button
} from 'reactstrap';

import { saveAs } from 'file-saver';

import { fetchDocumentsOfChild } from 'actions/child';
import { fetchChildDocument } from 'actions/child_document';

class ParentChildDetailDocuments extends React.Component {
	state = {
		dataReady: false,
		childDocuments: []
	};

	downloadChildDocument = (childDocumentId, filename) => {
		fetchChildDocument(childDocumentId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};

	componentDidMount() {
		fetchDocumentsOfChild(this.props.child.id)
		.then((childDocuments) => {
			this.setState({
				childDocuments,
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Table>
				<thead>
					<tr>
						<th>Title</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
				{ this.state.childDocuments.map((childDocument) => (
					<tr key={ childDocument.id }>
						<td>{ childDocument.title }</td>
						<td className="text-right">
							<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.downloadChildDocument(childDocument.id, childDocument.filename) }>
								<i className="fa fa-download"></i>{' '}Download
							</Button>
						</td>
					</tr>
				)) }
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

ParentChildDetailDocuments.propTypes = {
	child: PropTypes.object.isRequired
};

export default ParentChildDetailDocuments;

import React from 'react';

import DocumentCategory from 'components/documents/document_category';
import { fetchDocumentCategories } from 'actions/document_category';

import './documentsstyle.css';

class DocumentCategories extends React.PureComponent {
	state = {
		dataReady: false,
		documentCategories: []
	};

	componentDidMount() {
		fetchDocumentCategories()
		.then((documentCategories) => {
			this.setState({
				documentCategories,
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			{ this.state.documentCategories.map((documentCategory) => (
				<DocumentCategory key={ documentCategory.id } documentCategory={ documentCategory } />
			)) }
			</React.Fragment>
		);
	}
};

export default DocumentCategories;

import React from 'react';
import PropTypes from 'prop-types';
import {
	Col, Row, Input, ButtonGroup, Button
} from 'reactstrap';

import Week from 'components/utils/week';

import {
	fetchSchoolTimeSlotsOfSchoolYear, fetchHolidayTimeSlotsOfSchoolYear,
	addSchoolTimeSlotToSchoolYear, addHolidayTimeSlotToSchoolYear
} from 'actions/school_year';
import { removeSchoolTimeSlot } from 'actions/school_time_slot';
import { removeHolidayTimeSlot } from 'actions/holiday_time_slot';

import { dayNames } from 'services/calendar';

class AdminSchoolYearDetailTimeSlots extends React.Component {
	state = {
		dataReady: false,
		schoolTimeSlotsByDay: [],
		holidayTimeSlotsByDay: [],
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 },
		schoolSlotForms: [0,1,2,3,4].map((i) => ({ weekday: i, startTime: '08:00', endTime: '09:00', type: 'school' })),
		holidaySlotForms: [0,1,2,3,4].map((i) => ({ weekday: i, startTime: '08:00', endTime: '09:00', type: 'extra' }))
	};

	updateSchoolTimeSlotForm = (weekday, changes) => {
		this.setState((prevState) => ({
			schoolSlotForms: prevState.schoolSlotForms.map((sf) => sf.weekday === weekday ? { ...sf, ...changes } : sf)
		}));
	};

	addSchoolTimeSlot = (weekday) => {
		addSchoolTimeSlotToSchoolYear(this.props.schoolYear.id, this.state.schoolSlotForms.find((sf) => sf.weekday === weekday))
		.then((schoolTimeSlot) => {
			schoolTimeSlot.active = true;
			this.setState((prevState) => ({
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay, idx) =>
					idx === weekday ? { ...stsOfDay, slots: [ ...stsOfDay.slots, schoolTimeSlot ] } : stsOfDay
				)
			}));
		});
	};

	removeSchoolTimeSlot = (schoolTimeSlotId) => {
		removeSchoolTimeSlot(schoolTimeSlotId)
		.then(() => {
			this.setState((prevState) => ({
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: [ ...stsOfDay.slots.filter((s) => s.id !== schoolTimeSlotId) ]
				}))
			}));
		});
	};
	
	updateHolidayTimeSlotForm = (weekday, changes) => {
		this.setState((prevState) => ({
			holidaySlotForms: prevState.holidaySlotForms.map((sf) => sf.weekday === weekday ? { ...sf, ...changes } : sf)
		}));
	};

	addHolidayTimeSlot = (weekday) => {
		addHolidayTimeSlotToSchoolYear(this.props.schoolYear.id, this.state.holidaySlotForms.find((sf) => sf.weekday === weekday))
		.then((holidayTimeSlot) => {
			holidayTimeSlot.active = true;
			this.setState((prevState) => ({
				holidayTimeSlotsByDay: prevState.holidayTimeSlotsByDay.map((htsOfDay, idx) =>
					idx === weekday ? { ...htsOfDay, slots: [ ...htsOfDay.slots, holidayTimeSlot ] } : htsOfDay
				)
			}));
		});
	};

	removeHolidayTimeSlot = (holidayTimeSlotId) => {
		removeHolidayTimeSlot(holidayTimeSlotId)
		.then(() => {
			this.setState((prevState) => ({
				holidayTimeSlotsByDay: prevState.holidayTimeSlotsByDay.map((htsOfDay) => ({
					...htsOfDay,
					slots: [ ...htsOfDay.slots.filter((s) => s.id !== holidayTimeSlotId) ]
				}))
			}));
		});
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolTimeSlotsOfSchoolYear(this.props.schoolYear.id),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.schoolYear.id)
		])
		.then(([ schoolTimeSlots, holidayTimeSlots ]) => {
			this.setState({
				schoolTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
					name: dayNames[i],
					slots: schoolTimeSlots.filter((sts) => sts.weekday === i).map((slot) => ({ ...slot, active: true }))
				})),
				holidayTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
					name: dayNames[i],
					slots: holidayTimeSlots.filter((hts) => hts.weekday === i).map((slot) => ({ ...slot, active: true }))
				})),
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row>
				{ this.state.schoolSlotForms.map((sf) => (
					<Col key={ sf.weekday } className="text-center">
						<Input type="time" value={ sf.startTime } onChange={ (e) => this.updateSchoolTimeSlotForm(sf.weekday, { startTime: e.target.value }) } />
						<Input type="time" value={ sf.endTime } onChange={ (e) => this.updateSchoolTimeSlotForm(sf.weekday, { endTime: e.target.value }) } />
						<ButtonGroup style={{ display: 'flex' }}>
							{ ['school', 'extra'].map((type) => {
								const color = sf.type === type ? 'success' : 'secondary';
								return (
									<Button key={ type } color={ color } className="btn-sm" style={{ flexGrow: 1, cursor: 'pointer' }} onClick={ () => this.updateSchoolTimeSlotForm(sf.weekday, { type }) }>
										{ type }
									</Button>
								);
							}) }
						</ButtonGroup>
						<Button color="primary" className="btn-sm mt-1 mb-1" style={{ cursor: 'pointer' }} onClick={ () => this.addSchoolTimeSlot(sf.weekday) }>
							<i className="fa fa-plus"></i> Add
						</Button>
					</Col>
				)) }
			</Row>
			<Row>
				<Col>
					<Week 
						config={ this.state.weekConfig }
						slotsByDay={ this.state.schoolTimeSlotsByDay }
						onRemoveSlot={ this.removeSchoolTimeSlot }
					/>
				</Col>
			</Row>
			<hr />
			<Row>
				{ this.state.holidaySlotForms.map((sf) => (
					<Col key={ sf.weekday } className="text-center">
						<Input type="time" value={ sf.startTime } onChange={ (e) => this.updateHolidayTimeSlotForm(sf.weekday, { startTime: e.target.value }) } />
						<Input type="time" value={ sf.endTime } onChange={ (e) => this.updateHolidayTimeSlotForm(sf.weekday, { endTime: e.target.value }) } />
						<Button color="primary" className="btn-sm mt-1 mb-1" style={{ cursor: 'pointer' }} onClick={ () => this.addHolidayTimeSlot(sf.weekday) }>
							<i className="fa fa-plus"></i> Add
						</Button>
					</Col>
				)) }
			</Row>
			<Row>
				<Col>
					<Week 
						config={ this.state.weekConfig }
						slotsByDay={ this.state.holidayTimeSlotsByDay }
						onRemoveSlot={ this.removeHolidayTimeSlot }
					/>
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

AdminSchoolYearDetailTimeSlots.propTypes = {
	schoolYear: PropTypes.object.isRequired
};

export default AdminSchoolYearDetailTimeSlots;

import React from 'react';

const Calendar = (props) => {
	return <iframe
		src="https://calendar.google.com/calendar/embed?src=playgroup%40playgroup.fr&ctz=Europe%2FParis"
		title="Playgroup calendar"
		style={{border: '0', width: '100%', height:'75vh'}}
		frameborder="0"
		scrolling="no"
	></iframe>;
}

export default Calendar;

import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Collapse
} from 'reactstrap';

import AdminChildren from 'components/admin/children';
import AdminRegularContracts from 'components/admin/contracts/regular_contracts';
import AdminPunctualContracts from 'components/admin/contracts/punctual_contracts';
import AdminHolidayContracts from 'components/admin/contracts/holiday_contracts';
import AdminDocuments from 'components/admin/documents';
import AdminParents from 'components/admin/parents';
import AdminSchoolYears from 'components/admin/school_years';
import AdminUsers from 'components/admin/users';
import AdminStats from 'components/admin/stats';

class Admin extends React.PureComponent {
	state = {
		contractMenuOpen: false
	};

	render() {
		return (
			<Row>
				<Col xs="2">
					<Nav xs="2" vertical>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/children' }>Children</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#" className="d-flex align-items-center" onClick={ () => this.setState((prevState) => ({ contractMenuOpen: !prevState.contractMenuOpen })) }>
								Contracts
								{' '}
								{ this.state.contractMenuOpen ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
							</NavLink>
						</NavItem>
						<Collapse isOpen={ this.state.contractMenuOpen }>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/regularContracts' }>
									<i className="fa fa-clock-o"></i>
									{' '}Regular contracts
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/punctualContracts' }>
									<i className="fa fa-map-marker"></i>
									{' '}Punctual contracts
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/holidayContracts' }>
									<i className="fa fa-suitcase"></i>
									{' '}Holiday contracts
								</NavLink>
							</NavItem>
						</Collapse>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/documents' }>Documents</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/parents' }>Parents</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/schoolYears' }>School years</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/users' }>Users</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/stats' }>Stats</NavLink>
						</NavItem>
					</Nav>
				</Col>
				<Col xs="10">
					<Route path={ this.props.match.url + '/children' } component={ AdminChildren } />
					<Route path={ this.props.match.url + '/regularContracts' } component={ AdminRegularContracts } />
					<Route path={ this.props.match.url + '/punctualContracts' } component={ AdminPunctualContracts } />
					<Route path={ this.props.match.url + '/holidayContracts' } component={ AdminHolidayContracts } />
					<Route path={ this.props.match.url + '/documents' } component={ AdminDocuments } />
					<Route path={ this.props.match.url + '/parents' } component={ AdminParents } />
					<Route path={ this.props.match.url + '/schoolYears' } component={ AdminSchoolYears } />
					<Route path={ this.props.match.url + '/users' } component={ AdminUsers } />
					<Route path={ this.props.match.url + '/stats' } component={ AdminStats } />
				</Col>
			</Row>
		);
	}
};

export default Admin;

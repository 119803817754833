import React from 'react';
import PropTypes from 'prop-types';
import {
	Table
} from 'reactstrap';
import format from 'date-fns/format';

import InlineEdit from 'components/utils/inline_edit';

class AdminSchoolYearDetailInfo extends React.PureComponent {

	handleSchoolYearEdited = (value, prop) => {
		return this.props.updateSchoolYear({ [prop]: value });
	}

	render() {
		return (
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Title</th>
						<td><InlineEdit type="text" value={ this.props.schoolYear.title } onEdited={ (title) => this.handleSchoolYearEdited(title, 'title') } /></td>
					</tr>
					<tr>
						<th scope="row">Start date</th>
						<td>{ format(this.props.schoolYear.startDate, 'DD-MM-YYYY') }</td>
					</tr>
					<tr>
						<th scope="row">End date</th>
						<td>{ format(this.props.schoolYear.endDate, 'DD-MM-YYYY') }</td>
					</tr>
					<tr>
						<th scope="row">Nb open weeks</th>
						<td>{ this.props.schoolYear.nbOpenWeeks }</td>
					</tr>
				</tbody>
			</Table>
		);
	}
};

AdminSchoolYearDetailInfo.propTypes = {
	schoolYear: PropTypes.object.isRequired,
	updateSchoolYear: PropTypes.func.isRequired
};

export default AdminSchoolYearDetailInfo;

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = (props) => (
	<Modal isOpen={ props.isOpen }>
		<ModalHeader> Confirmation </ModalHeader>
		<ModalBody> { props.text ? props.text : props.children } </ModalBody>
		<ModalFooter>
			<Button color="danger" style={{ cursor: 'pointer' }} onClick={ props.onConfirm }> Yes </Button>{' '}
			<Button color="secondary" style={{ cursor: 'pointer' }} onClick={ props.onDismiss }> No </Button>
		</ModalFooter>
	</Modal>
);

ConfirmationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onDismiss: PropTypes.func.isRequired
};

export default ConfirmationModal;

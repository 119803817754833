import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Collapse, Badge,
	Form, FormGroup, Label, Input
} from 'reactstrap';
import getYear from 'date-fns/get_year';

import { fetchChildren, updateChild, removeChild } from 'actions/child';

import { debounce } from 'services/debounce';
import Paginate from 'components/utils/paginate';

import AdminChildDetail from 'components/admin/children/detail';

class AdminChildren extends React.Component {
	state = {
		dataReady: false,
		children: [],
		showFilters: false,
		filters: {
			firstname: '',
			lastname: '',
			birthYear: 0,
			mealType: 'any',
			disabledOnly: false,
			allergyOnly: false
		},
		filteredChildren: [],
		paginationStartIndex: 0,
		paginationEndIndex: 0
	};

	toggleFilters = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
	};

	handleFilterChange = (value, prop) => {
		this.setState(
			(prevState) => ({ filters: { ...prevState.filters, [prop]: value } }),
			this.debouncedUpdateFilteredChildren
		);
	};

	filteredChildren = (children) => {
		return children.filter((child) => {
			return (!this.state.filters.disabledOnly || child.disabledStatus) &&
				(!this.state.filters.allergyOnly || child.allergy) &&
				(this.state.filters.mealType === 'any' || child.mealType === this.state.filters.mealType) &&
				(this.state.filters.birthYear === 0 || getYear(child.birthdate) === this.state.filters.birthYear) &&
				child.firstname.toLowerCase().includes(this.state.filters.firstname.toLowerCase()) &&
				child.lastname.toLowerCase().includes(this.state.filters.lastname.toLowerCase());
		});
	};
	updateFilteredChildren = () => {
		this.setState({
			filteredChildren: this.filteredChildren(this.state.children)
		});
	};
	debouncedUpdateFilteredChildren = debounce(this.updateFilteredChildren, 500, false);

	pageChanged = (paginationStartIndex, paginationEndIndex) => {
		this.setState({ paginationStartIndex, paginationEndIndex });
	};

	updateChild = (childId, changes) => {
		return updateChild(childId, changes)
		.then((child) => {
			this.setState((prevState) => {
				const children = prevState.children.map((c) => c.id === childId ? { ...c, ...child } : c);
				return {
					children,
					filteredChildren: this.filteredChildren(children)
				};
			});
		});
	};

	removeChild = (childId) => {
		return removeChild(childId)
		.then(() => {
			this.props.history.push(this.props.match.url);
			this.setState((prevState) => ({
				children: prevState.children.filter((c) => c.id !== childId),
				filteredChildren: prevState.filteredChildren.filter((c) => c.id !== childId)
			}));
		});
	};

	componentDidMount() {
		fetchChildren()
		.then((children) => {
			this.setState({
				children,
				dataReady: true,
				filteredChildren: this.filteredChildren(children)
			});
		});
	}

	render() {
		const filterParagraphStyle = {
			color: 'white',
			backgroundColor: 'rgba(52, 58, 64, 0.60)',
			cursor: 'pointer'
		};
		const birthYears = [];
		this.state.children.forEach((c) => {
			const y = getYear(c.birthdate);
			if (!birthYears.includes(y)) {
				birthYears.push(y);
			}
		});
		birthYears.sort((a, b) => a - b);

		return (
			<React.Fragment>
			<Row>
				<Col>
					<p style={ filterParagraphStyle } className="p-2 d-flex align-items-center" onClick={ this.toggleFilters }>
						Filters
						<Badge className="ml-3" color="info">{ this.state.filteredChildren.length }</Badge>
						{ this.state.showFilters ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
					</p>
					<Collapse isOpen={ this.state.showFilters }>
						<Form onSubmit={ (e) => e.preventDefault() }>
							<FormGroup row>
								<Label for="firstname" sm={2}>Firstname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.firstname } onChange={ (e) => this.handleFilterChange(e.target.value, 'firstname') } name="firstname" id="firstname" placeholder="Firstname" />
								</Col>
								<Label for="lastname" sm={2}>Lastname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.lastname } onChange={ (e) => this.handleFilterChange(e.target.value, 'lastname') } name="lastname" id="lastname" placeholder="Lastname" />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="birthYear" sm={2}>Birth year</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.birthYear } onChange={ (e) => this.handleFilterChange(parseInt(e.target.value, 10), 'birthYear') } name="birthYear" id="birthYear">
										<option value={ 0 }>-- any --</option>
										{ birthYears.map((y) => (
											<option key={ y } value={ y }>{ y }</option>
										)) }
									</Input>
								</Col>
								<Label for="mealType" sm={2}>Meal type</Label>
								<Col sm={4}>
									<Input type="select" value={ this.state.filters.mealType } onChange={ (e) => this.handleFilterChange(e.target.value, 'mealType') } name="mealType" id="mealType">
										<option value="any">-- any --</option>
										<option value="Standard">Standard</option>
										<option value="Sans viande">Sans viande</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="disabled" sm={2}>Disabled only</Label>
								<Col sm={4}>
									<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="disabled" checked={ this.state.filters.disabledOnly } onChange={ (e) => this.handleFilterChange(e.target.checked, 'disabledOnly') } />
								</Col>
								<Label for="allergy" sm={2}>Allergy only</Label>
								<Col sm={4}>
									<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="allergy" checked={ this.state.filters.allergyOnly } onChange={ (e) => this.handleFilterChange(e.target.checked, 'allergyOnly') } />
								</Col>
							</FormGroup>
						</Form>
						<hr />
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginate nbItems={ this.state.filteredChildren.length } onPageChange={ this.pageChanged } initialPage={ 1 } />
				</Col>
			</Row>
			<Row>
				<Col xs="3">
					<Nav vertical>
						{ this.state.filteredChildren.slice(this.state.paginationStartIndex, this.state.paginationEndIndex + 1).map((child) => {
							return (
								<NavItem key={ child.id }>
									<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/' + child.id }>
										{ child.firstname } { child.lastname }
									</NavLink>
								</NavItem>
							);
						}) }
					</Nav>
				</Col>
				<Col xs="9">
					{ this.state.dataReady &&
					<Route path={ this.props.match.url + '/:childId' } render={ (props) => (
						<AdminChildDetail
							{ ...props }
							child={ this.state.children.find((c) => c.id === parseInt(props.match.params.childId, 10)) }
							updateChild={ (changes) => this.updateChild(parseInt(props.match.params.childId, 10), changes) }
							removeChild={ () => this.removeChild(parseInt(props.match.params.childId, 10)) }
						/>
					) } /> }
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

export default AdminChildren;

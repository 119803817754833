import React from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col, Button,
	Table, Collapse,
	Form, FormGroup, Label, Input,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import isBefore from 'date-fns/is_before';
import startOfWeek from 'date-fns/start_of_week';
import endOfWeek from 'date-fns/end_of_week';
import addWeeks from 'date-fns/add_weeks';
import subWeeks from 'date-fns/sub_weeks';
import getDay from 'date-fns/get_day';

import InlineEdit from 'components/utils/inline_edit';
import Week from 'components/utils/week';
import ConfirmationModal from 'components/utils/confirmation_modal';

import { dayNames } from 'services/calendar';
import { saveAs } from 'file-saver';

import {
	fetchSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchDaysOfSchoolYearBetweenDates
} from 'actions/school_year';
import { fetchHolidayContractsOfChildOfSchoolYear } from 'actions/child';
import { fetchInvoiceModificationCategories } from 'actions/invoice_modification_category';
import { downloadHolidayContract } from 'actions/holiday_contract';

const getSlotsByDay = (schoolTimeSlots, holidayTimeSlots, days, contract, otherContracts) => {
	return days.map((day) => {
		const weekday = (getDay(day.date) - 1 + 7) % 7;
		let slots = [];
		switch (day.type) {
			case 'school':
				slots = schoolTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: false,
					selected: false
				}));
				break;
			case 'holiday':
				slots = holidayTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: !otherContracts.some((otherContract) => otherContract.HolidayOffers.some((offer) => offer.HolidayTimeSlotId === slot.id && offer.DayId === day.id)),
					selected: contract.HolidayOffers.some((offer) => offer.HolidayTimeSlotId === slot.id && offer.DayId === day.id)
				}));
				break;
			case 'closed':
				slots = [{
					startTime: '08:00:00',
					endTime: '18:00:00',
					active: false,
					selected: false
				}];
				break;
			default: break;
		}
		return {
			day,
			date: day.date,
			name: dayNames[weekday],
			slots
		};
	});
};

class AdminChildDetailHolidayContract extends React.Component {
	state = {
		schoolYear: null,
		schoolTimeSlots: [],
		holidayTimeSlots: [],
		otherHolidayContracts: [],
		invoiceModificationCategories: [],
		invoiceModificationCategoriesById: {},
		changeContractStatusOpen: false,
		showInvoiceModifications: false,
		showNewInvoiceModificationForm: false,
		shouldRegenerateInvoice: false,
		newInvoiceModification: {
			InvoiceModificationCategoryId: null,
			title: '',
			amount: 0
		},
		sendMailToParents: true,
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 },
		contractStartDate: '',
		currentWeekStartDate: '',
		currentWeekSlotsByDay: [],
		downloadingContract: false,
		downloadingInvoice: false
	};

	handleContractEdited = (value, prop) => {
		if (prop === 'status' && value === 'valid') { // validate contract
			return this.props.updateHolidayContract({ [prop]: value, sendMail: this.state.sendMailToParents });
		}
		return this.props.updateHolidayContract({ [prop]: value });
	};

	updateWeekData = (startDate, endDate) => {
		fetchDaysOfSchoolYearBetweenDates(this.props.holidayContract.SchoolYearId, startDate, endDate)
		.then((weekDays) => {
			this.setState({
				currentWeekStartDate: startDate,
				currentWeekSlotsByDay: getSlotsByDay(this.state.schoolTimeSlots, this.state.holidayTimeSlots, weekDays, this.props.holidayContract, this.state.otherHolidayContracts),
			});
		});
	};

	handlePreviousWeek = () => {
		const weekStartDate = subWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleStartWeek = () => {
		const weekStartDate = startOfWeek(this.state.contractStartDate, { weekStartsOn: 1 });
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleNextWeek = () => {
		const weekStartDate = addWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	toggleHolidayTimeSlot = (holidayTimeSlotId) => {
		const dayId = this.state.currentWeekSlotsByDay.find((slotsByDay) => slotsByDay.day.type === 'holiday' && slotsByDay.slots.some((s) => s.id === holidayTimeSlotId)).day.id;
		const offer = this.props.holidayContract.HolidayOffers.find((holidayOffer) => {
			return holidayOffer.HolidayTimeSlotId === holidayTimeSlotId && holidayOffer.DayId === dayId;
		});

		if (offer) {
			this.props.removeOfferFromContract(offer.id);
		} else {
			this.props.addOfferToContract({
				holidayTimeSlotId,
				dayId
			});
		}
	};

	toggleInvoiceModifications = () => {
		if (!this.state.showInvoiceModifications) {
			this.setState({
				showInvoiceModifications: true,
				showNewInvoiceModificationForm: false,
				shouldRegenerateInvoice: false,
				newInvoiceModification: {
					InvoiceModificationCategoryId: null,
					title: '',
					amount: 0
				}
			});
		} else {
			this.setState({
				showInvoiceModifications: false
			}, () => {
				if (this.state.shouldRegenerateInvoice) {
					this.props.generateHolidayContractInvoice();
				}
			});
		}
	};

	toggleShowNewInvoiceModification = () => {
		this.setState((prevState) => ({
			showNewInvoiceModificationForm: !prevState.showNewInvoiceModificationForm
		}));
	};

	handleNewInvoiceModificationEdited = (value, prop) => {
		this.setState((prevState) => ({
			newInvoiceModification: {
				...prevState.newInvoiceModification,
				[prop]: value
			}
		}));
	};

	handleAddHolidayContractInvoiceModification = (e) => {
		e.preventDefault();
		this.props.addHolidayContractInvoiceModification(this.props.holidayContract.HolidayContractInvoice.id, this.state.newInvoiceModification)
		.then(() => {
			this.setState({ shouldRegenerateInvoice: true });
		});
	};

	handleRemoveHolidayContractInvoiceModification = (modifId) => {
		this.props.removeHolidayContractInvoiceModification(this.props.holidayContract.HolidayContractInvoice.id, modifId)
		.then(() => {
			this.setState({ shouldRegenerateInvoice: true });
		});
	};

	downloadContract = () => {
		this.setState({ downloadingContract: true });
		downloadHolidayContract(this.props.holidayContract.id)
		.then((blob) => {
			saveAs(blob, 'holiday_contract.pdf');
			this.setState({ downloadingContract: false });
		});
	};

	downloadInvoice = () => {
		this.setState({ downloadingInvoice: true });
		this.props.downloadHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id, this.props.holidayContract.HolidayContractInvoice.filename)
		.then(() => {
			this.setState({ downloadingInvoice: false });
		});
	};

	componentDidMount() {
		let schoolYear, schoolTimeSlots, holidayTimeSlots, otherHolidayContracts, invoiceModificationCategories, contractStartDate, weekStartDate;
		const invoiceModificationCategoriesById = {};
		Promise.all([
			fetchSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchHolidayContractsOfChildOfSchoolYear(this.props.holidayContract.ChildId, this.props.holidayContract.SchoolYearId),
			fetchInvoiceModificationCategories()
		])
		.then(([ sy, sts, hts, hc, imc ]) => {
			schoolYear = sy;
			schoolTimeSlots = sts;
			holidayTimeSlots = hts;
			otherHolidayContracts = hc.filter((c) => c.id !== this.props.holidayContract.id);
			invoiceModificationCategories = imc;
			invoiceModificationCategories.forEach((c) => invoiceModificationCategoriesById[c.id] = c);
			contractStartDate = this.props.holidayContract.HolidayOffers.length > 0 ?
				this.props.holidayContract.HolidayOffers.reduce(
					(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
					this.props.holidayContract.HolidayOffers[0].Day.date
				) :
				schoolYear.startDate;
			weekStartDate = startOfWeek(contractStartDate, { weekStartsOn: 1 });
			const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
			return fetchDaysOfSchoolYearBetweenDates(this.props.holidayContract.SchoolYearId, weekStartDate, weekEndDate);
		})
		.then((weekDays) => {
			this.setState({
				schoolYear,
				schoolTimeSlots,
				holidayTimeSlots,
				otherHolidayContracts,
				invoiceModificationCategories,
				invoiceModificationCategoriesById,
				contractStartDate,
				currentWeekStartDate: weekStartDate,
				currentWeekSlotsByDay: getSlotsByDay(schoolTimeSlots, holidayTimeSlots, weekDays, this.props.holidayContract, otherHolidayContracts)
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.holidayContract !== this.props.holidayContract) {
			const contractStartDate = this.props.holidayContract.HolidayOffers.length > 0 ?
				this.props.holidayContract.HolidayOffers.reduce(
					(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
					this.props.holidayContract.HolidayOffers[0].Day.date
				) :
				new Date();
			this.setState((prevState) => ({
				contractStartDate,
				currentWeekSlotsByDay: getSlotsByDay(
					prevState.schoolTimeSlots,
					prevState.holidayTimeSlots,
					prevState.currentWeekSlotsByDay.map((slotsByDay) => slotsByDay.day),
					this.props.holidayContract,
					prevState.otherHolidayContracts
				)
			}));
		}
	}
	
	render() {
		return (
			<React.Fragment>
			<Row className="mb-2">
				<Col>
					<h4 className="text-right ml-auto">
						{ this.props.holidayContract.status === 'valid' ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red' }} className="fa fa-ban"></i> }
						{' '}Holiday contract
						{' '}<i className="fa fa-suitcase"></i>
						{ (this.props.holidayContract.status === 'valid' && (!this.props.holidayContract.HolidayContractInvoice || this.props.holidayContract.HolidayContractInvoice.status === 'edited')) &&
							<React.Fragment>
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ changeContractStatusOpen: true }) }>
								Invalidate
							</Button>
							<ConfirmationModal
								isOpen={ this.state.changeContractStatusOpen }
								text="Are you sure you want to invalidate this contract ?"
								onConfirm={ () => { this.setState({ changeContractStatusOpen: false }); this.handleContractEdited('demand', 'status'); } }
								onDismiss={ () => { this.setState({ changeContractStatusOpen: false }); } }
							/>
							</React.Fragment>
						}
						{ this.props.holidayContract.status === 'demand' &&
							<React.Fragment>
							<Button color="success" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ changeContractStatusOpen: true }) }>
								Validate
							</Button>
							<ConfirmationModal
								isOpen={ this.state.changeContractStatusOpen }
								onConfirm={ () => { this.setState({ changeContractStatusOpen: false }); this.handleContractEdited('valid', 'status'); } }
								onDismiss={ () => { this.setState({ changeContractStatusOpen: false }); } }
							>
								<p>Are you sure you want to validate this holiday contract ?</p>
								<Form>
									<FormGroup row>
										<Col sm={1} style={{ textAlign: 'right' }}>
											<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="sendmail" checked={ this.state.sendMailToParents } onChange={ (e) => this.setState({ sendMailToParents: e.target.checked }) } />
										</Col>
										<Label for="sendmail" sm={11}>Send email to the parents</Label>
									</FormGroup>
								</Form>
							</ConfirmationModal>
							</React.Fragment>
						}
						<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '13rem', height: '2rem' }} onClick={ this.downloadContract }>
							{ !this.state.downloadingContract && <React.Fragment>
								<i className="fa fa-download"></i>{' '}Download contract PDF
							</React.Fragment> }
							<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingContract} />
						</Button>
					</h4>
				</Col>
			</Row>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent income</th>
						<td>
						{ this.props.holidayContract.status === 'valid'
							? <span>{ (this.props.holidayContract.parentIncome / 100) + '€' }</span>
							: <InlineEdit type="number" value={ this.props.holidayContract.parentIncome / 100 } onEdited={ (parentIncome) => this.handleContractEdited(parentIncome * 100, 'parentIncome') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent nb children</th>
						<td>
						{ this.props.holidayContract.status === 'valid'
							? <span>{ this.props.holidayContract.parentNbChildren }</span>
							: <InlineEdit type="number" value={ this.props.holidayContract.parentNbChildren } onEdited={ (parentNbChildren) => this.handleContractEdited(parentNbChildren, 'parentNbChildren') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>CAF rate</th>
						<td>
						{ this.props.holidayContract.status === 'valid'
							? <span>{ this.props.holidayContract.CAFrate ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red'}} className="fa fa-ban"></i> }</span>
							: <InlineEdit type="checkbox" value={ this.props.holidayContract.CAFrate } onEdited={ (CAFrate) => this.handleContractEdited(CAFrate, 'CAFrate') } />
						}
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Payment method</th>
						<td>
						{ this.props.holidayContract.status === 'valid'
							? <span>{ this.props.holidayContract.paymentMethod }</span>
							: <InlineEdit type="select" options={ [ 'debit', 'transfer', 'check', 'other' ] } value={ this.props.holidayContract.paymentMethod } onEdited={ (paymentMethod) => this.handleContractEdited(paymentMethod, 'paymentMethod') }/>
						}
						</td>
					</tr>
				</tbody>
			</Table>
			<hr />
			<h4 className="text-right ml-auto">
				Time slots ({ this.props.holidayContract.HolidayOffers.length })
				{' '}<i className="fa fa-calendar-o"></i>
			</h4>
			<Row>
				<Col>
					<Week
						config={ this.state.weekConfig }
						slotsByDay={ this.state.currentWeekSlotsByDay }
						weekStartDate={ this.state.currentWeekStartDate }
						onPreviousWeek={ this.handlePreviousWeek }
						onNextWeek={ this.handleNextWeek }
						onStartWeek={ this.handleStartWeek }
						onToggleSlot={ this.props.holidayContract.status === 'demand' ? this.toggleHolidayTimeSlot : null }
					/>
				</Col>
			</Row>
			<hr />
			<h4 className="text-right ml-auto">
				Invoice{' '}<i className="fa fa-money"></i>
			</h4>
			<Table size="sm">
				<thead>
					<tr>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{ this.props.holidayContract.HolidayContractInvoice ? (this.props.holidayContract.HolidayContractInvoice.amount/100)+'€' : '' }</td>
						<td>{ this.props.holidayContract.HolidayContractInvoice ?
							<InlineEdit type="number" step={0.01} value={ this.props.holidayContract.HolidayContractInvoice.paidAmount/100 } onEdited={ (paidAmount) => this.props.updateHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id, { paidAmount: paidAmount*100 }) } />
							: '' }
						</td>
						<td>{ this.props.holidayContract.HolidayContractInvoice ?
							((this.props.holidayContract.HolidayContractInvoice.amount-this.props.holidayContract.HolidayContractInvoice.paidAmount)/100)+'€' : '' }
						</td>
						<td className="text-right">
							{ this.props.holidayContract.HolidayContractInvoice && this.props.holidayContract.HolidayContractInvoice.status === 'edited'
								&& (
									<React.Fragment>
									<Button color="warning" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.props.sendHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id) }
									>
										<i className="fa fa-envelope-o"></i>{' '}Send
									</Button>
									<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
									onClick={ () => this.props.removeHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id) }
									>
										<i className="fa fa-trash"></i>{' '}Remove
									</Button>
									</React.Fragment>
								)
							}
							{ this.props.holidayContract.HolidayContractInvoice && this.props.holidayContract.HolidayContractInvoice.status === 'sent'
								&& (
									<React.Fragment>
									<Button color="success" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.props.updateHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id, { status: 'paid', paidAmount: this.props.holidayContract.HolidayContractInvoice.amount }) }
									>
										<i className="fa fa-check"></i>{' '}Mark as paid
									</Button>
									<Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-envelope"></i>{' '}Sent</Button>
									</React.Fragment>
								)
							}
							{ this.props.holidayContract.HolidayContractInvoice && this.props.holidayContract.HolidayContractInvoice.status === 'paid'
								&&  (
									<React.Fragment>
									<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.props.updateHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id, { status: 'sent' }) }
									>
										<i className="fa fa-times"></i>{' '}Mark as unpaid
									</Button>
									<Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-check"></i>{' '}Paid</Button>
									</React.Fragment>
								)
							}
							{ this.props.holidayContract.HolidayContractInvoice
								&& <Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '8rem' }}
									onClick={ this.downloadInvoice }
								>
									{ !this.state.downloadingInvoice && <React.Fragment>
										<i className="fa fa-download"></i>{' '}Download
									</React.Fragment> }
									<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingInvoice} />
								</Button>
							}
							{ this.props.holidayContract.HolidayContractInvoice
								&& <Button color="secondary" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
									onClick={ this.toggleInvoiceModifications }
								>
									<i className="fa fa-file-text"></i>{' '}Modifications ({this.props.holidayContract.HolidayContractInvoice.InvoiceModifications.length})
								</Button>
							}
							<Button color="primary" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
								onClick={ this.props.generateHolidayContractInvoice }
							>
								<i className="fa fa-file-text"></i>{' '}Generate
							</Button>
						</td>
					</tr>
				</tbody>
			</Table>
			<Modal isOpen={this.state.showInvoiceModifications}>
				<ModalHeader>Invoice modifications</ModalHeader>
				<ModalBody>
					{ this.props.holidayContract.HolidayContractInvoice && this.state.showInvoiceModifications &&
						<React.Fragment>
						<Table striped hover size="sm">
							<thead>
								<tr>
									<th>Category</th>
									<th>Title</th>
									<th>Amount</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{ this.props.holidayContract.HolidayContractInvoice.InvoiceModifications.map((im) => (
									<tr>
										<td>{im.InvoiceModificationCategoryId ? this.state.invoiceModificationCategoriesById[im.InvoiceModificationCategoryId].title : 'Unknown'}</td>
										<td>{im.title}</td>
										<td>{(im.amount/100)+'€'}</td>
										<td><Button color="danger" size="sm" style={{cursor: 'pointer'}} onClick={() => {
											this.handleRemoveHolidayContractInvoiceModification(im.id);
										}}>
											<i className="fa fa-trash"></i></Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<p style={{ color: 'white', backgroundColor: 'rgb(23, 178, 168)', cursor: 'pointer' }} className="p-2 d-flex align-items-center" onClick={ this.toggleShowNewInvoiceModification }>
							New invoice modification
							{ this.state.showNewInvoiceModificationForm ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
						</p>
						<Collapse isOpen={this.state.showNewInvoiceModificationForm}>
							<Form onSubmit={ this.handleAddHolidayContractInvoiceModification }>
								<FormGroup row>
									<Label for="category" sm={2}>Category</Label>
									<Col sm={4}>
										<Input type="select" value={ this.state.newInvoiceModification.InvoiceModificationCategoryId } onChange={ (e) => this.handleNewInvoiceModificationEdited(parseInt(e.target.value, 10), 'InvoiceModificationCategoryId') }>
											<option value={ 0 }>-- unknown --</option>
											{ this.state.invoiceModificationCategories.map((imc) => (
												<option key={ imc.id } value={ imc.id }>{ imc.title }</option>
											)) }
										</Input>
									</Col>
									<Label for="title" sm={2}>Title</Label>
									<Col sm={4}>
										<Input type="text" value={this.state.newInvoiceModification.title} onChange={(e) => { this.handleNewInvoiceModificationEdited(e.target.value, 'title'); }} />
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="amount" sm={2}>Amount</Label>
									<Col sm={4}>
										<Input type="number" step={0.01} value={this.state.newInvoiceModification.amount/100} onChange={(e) => { this.handleNewInvoiceModificationEdited(parseFloat(e.target.value)*100, 'amount'); }} />
									</Col>
									<Col sm={4}>
										<Button color="primary" style={{ cursor: 'pointer' }}>
											<i className="fa fa-file-text"></i>{' '}Add
										</Button>
									</Col>
								</FormGroup>
							</Form>
						</Collapse>
						</React.Fragment>
					}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={ this.toggleInvoiceModifications }>Ok</Button>
				</ModalFooter>
			</Modal>
			</React.Fragment>
		);
	}
};

AdminChildDetailHolidayContract.propTypes = {
	holidayContract: PropTypes.object.isRequired,
	updateHolidayContract: PropTypes.func.isRequired,
	generateHolidayContractInvoice: PropTypes.func.isRequired,
	removeHolidayContractInvoice: PropTypes.func.isRequired,
	downloadHolidayContractInvoice: PropTypes.func.isRequired,
	sendHolidayContractInvoice: PropTypes.func.isRequired,
	updateHolidayContractInvoice: PropTypes.func.isRequired,
	addOfferToContract: PropTypes.func.isRequired,
	removeOfferFromContract: PropTypes.func.isRequired,
	addHolidayContractInvoiceModification: PropTypes.func.isRequired,
	removeHolidayContractInvoiceModification: PropTypes.func.isRequired
};

export default AdminChildDetailHolidayContract;

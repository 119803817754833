import React from 'react';
import PropTypes from 'prop-types';
import {
	Button
} from 'reactstrap';
import format from 'date-fns/format';

import { timeStringToFloat, floatToTimeString } from 'services/time';

import 'components/utils/weekstyle.css';

const WeekHeaderDayNames = ({ dayNames }) => (
	<div className="d-flex flex-row" style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
		{ dayNames.map((d) => (
			<span key={ d } className="col text-center p-0">{ d }</span>
		)) }
	</div>
);

WeekHeaderDayNames.propTypes = {
	dayNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

const WeekHeaderDayDates = ({ dayDates, onPreviousWeek, onNextWeek, onStartWeek }) => (
	<React.Fragment>
	<div className="mb-2 d-flex flex-row justify-content-center">
		<Button onClick={ onPreviousWeek } color="info" size="sm" className="mr-2" style={{ cursor: 'pointer' }}>{ '<< Previous week' }</Button>
		<Button onClick={ onStartWeek } color="info" size="sm" style={{ cursor: 'pointer' }}>{ '- Start week -' }</Button>
		<Button onClick={ onNextWeek } color="info" size="sm" className="ml-2" style={{ cursor: 'pointer' }}>{ 'Next week >>' }</Button>
	</div>
	<div className="mb-2 d-flex flex-row" style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
		{ dayDates.map((d) => (
			<span key={ d } className="col text-center p-0">{ format(d, 'DD-MM-YYYY') }</span>
		)) }
	</div>
	</React.Fragment>
);

WeekHeaderDayDates.propTypes = {
	dayDates: PropTypes.arrayOf(PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])).isRequired,
	onPreviousWeek: PropTypes.func.isRequired,
	onNextWeek: PropTypes.func.isRequired
};

const WeekDay = ({ config, slots, hourHeight, onRemoveSlot, onToggleSlot }) => (
	<div className="col p-0" style={{ position: 'relative', height: '100%' }}>
		{ slots.map((slot) => {
			let backgroundColor;
			if (!slot.active) { backgroundColor = '#dddddd'; }
			else if (slot.selected) { backgroundColor = '#2cab54'; }
			else { backgroundColor = slot.type === 'school' ? '#5dadd4' : '#ff714d'; }
			const slotstyle = {
				width: '100%',
				position: 'absolute',
				top: ((slot.startTime - config.startTime) * hourHeight) + 'px',
				height: ((slot.endTime - slot.startTime) * hourHeight) + 'px',
				backgroundColor,
				fontWeight: 'bold'
			};
			let slotClassName = 'weekslot';
			if (slot.active && onToggleSlot) {
				slotClassName += ' weektoggable';
			}
			return (
				<div key={ slot.startTime } style={ slotstyle } className={ slotClassName } onClick={ () => { if (slot.active && onToggleSlot) { onToggleSlot(slot.id); } } }>
					<span style={{ position: 'absolute', top: '2px', left: '2px' }}>{ floatToTimeString(slot.startTime) }</span>
					<span style={{ position: 'absolute', bottom: '2px', right: '2px' }}>{ floatToTimeString(slot.endTime) }</span>
					{ onRemoveSlot &&
					<span
						style={{ position: 'absolute', top: '2px', right: '2px', fontWeight: 'bold', cursor: 'pointer' }}
						onClick={ () => onRemoveSlot(slot.id) }
					>
						X
					</span> }
				</div>
			);
		})}
	</div>
);

WeekDay.propTypes = {
	config: PropTypes.shape({
		startTime: PropTypes.number.isRequired
	}).isRequired,
	slots: PropTypes.arrayOf(PropTypes.shape({
		startTime: PropTypes.number.isRequired,
		endTime: PropTypes.number.isRequired,
		active: PropTypes.bool.isRequired,
		selected: PropTypes.bool,
		type: PropTypes.string
	})).isRequired,
	hourHeight: PropTypes.number.isRequired,
	onRemoveSlot: PropTypes.func,
	onToggleSlot: PropTypes.func
};

const Week = ({ config, slotsByDay, onRemoveSlot, onToggleSlot, onPreviousWeek, onNextWeek, onStartWeek }) => {
	slotsByDay = slotsByDay.map((day) => ({
		...day,
		slots: day.slots.map((slot) => ({
			...slot,
			startTime: timeStringToFloat(slot.startTime),
			endTime: timeStringToFloat(slot.endTime)
		}))
	}));
	const dayheight = (config.endTime - config.startTime) * config.hourHeight;
	return (
		<React.Fragment>
		{ onPreviousWeek && onNextWeek && onStartWeek && <WeekHeaderDayDates
			dayDates={ slotsByDay.map((day) => day.date) }
			onPreviousWeek={ onPreviousWeek }
			onNextWeek={ onNextWeek }
			onStartWeek={ onStartWeek }
		/> }
		<WeekHeaderDayNames dayNames={ slotsByDay.map((day) => day.name) } />
		<div className="d-flex flex-row" style={{ height: dayheight+'px' }}>
			{ slotsByDay.map((day) => (
				<WeekDay
					key={ day.name }
					config={ config }
					slots={ day.slots }
					hourHeight={ config.hourHeight }
					onRemoveSlot={ onRemoveSlot }
					onToggleSlot={ onToggleSlot }
				/>
			)) }
		</div>
		</React.Fragment>
	);
}

Week.propTypes = {
	config: PropTypes.shape({
		startTime: PropTypes.number.isRequired,
		endTime: PropTypes.number.isRequired,
		hourHeight: PropTypes.number.isRequired
	}).isRequired,
	slotsByDay: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		date: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		slots: PropTypes.arrayOf(PropTypes.shape({
			startTime: PropTypes.string.isRequired,
			endTime: PropTypes.string.isRequired,
			active: PropTypes.bool.isRequired,
			selected: PropTypes.bool,
			type: PropTypes.string
		}))
	})).isRequired,
	onRemoveSlot: PropTypes.func,
	onToggleSlot: PropTypes.func,
	onPreviousWeek: PropTypes.func,
	onNextWeek: PropTypes.func,
	onStartWeek: PropTypes.func
};

export default Week;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink
} from 'reactstrap';

import ParentChildDetailInfo from 'components/parent/child/info';
import ParentChildDetailContracts from 'components/parent/child/contracts';
import ParentChildDetailDocuments from 'components/parent/child/documents';

class ParentChildDetail extends React.PureComponent {
	
	componentDidMount() {
		if (this.props.match.isExact) {
			this.props.history.push(this.props.match.url  +'/info');
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.child.id !== prevProps.child.id) {
			this.props.history.push(this.props.match.url + '/info');
		}
	}

	render() {
		return (
			<React.Fragment>
			<Nav className="mb-4">
				<li className="font-weight-bold mr-4" style={{ alignSelf: 'center' }}>
					{ this.props.child.firstname } { this.props.child.lastname }
				</li>
				<NavItem style={{ borderLeft: '1px solid #343A40' }}>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/info' }>Info</NavLink>
				</NavItem><NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/contracts' }>Contracts</NavLink>
				</NavItem><NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/documents' }>Documents</NavLink>
				</NavItem>
			</Nav>
			<Row>
				<Col>
					<Route path={ this.props.match.path + '/info' } render={ () => (
						<ParentChildDetailInfo
							child={ this.props.child }
							updateChild={ this.props.updateChild }
						/>
					) } />
					<Route path={ this.props.match.path + '/contracts' } render={ (props) => (
						<ParentChildDetailContracts
							{ ...props }
							child={ this.props.child }
						/>
					) } />
					<Route path={ this.props.match.path + '/documents' } render={ (props) => (
						<ParentChildDetailDocuments
							child={ this.props.child }
						/>
					) } />
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

ParentChildDetail.propTypes = {
	child: PropTypes.object.isRequired,
	updateChild: PropTypes.func.isRequired
};

export default ParentChildDetail;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink
} from 'reactstrap';

import AdminSchoolYearDetailInfo from 'components/admin/school_years/detail/info';
import AdminSchoolYearDetailDays from 'components/admin/school_years/detail/days';
import AdminSchoolYearDetailTimeSlots from 'components/admin/school_years/detail/time_slots';
import AdminSchoolYearDetailRegularOffers from 'components/admin/school_years/detail/regular_offers';

class AdminSchoolYearDetail extends React.PureComponent {

	componentDidMount() {
		if (this.props.match.isExact) {
			this.props.history.push(this.props.match.url + '/info');
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.schoolYear.id !== prevProps.schoolYear.id) {
			this.props.history.push(this.props.match.url + '/info');
		}
	}

	render() {
		return (
			<React.Fragment>
			<Nav className="mb-4">
				<NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/info' }>Info</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/days' }>Days</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/timeslots' }>Time slots</NavLink>
				</NavItem>
				<NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/offers' }>Regular offers</NavLink>
				</NavItem>
			</Nav>
			<Row>
				<Col>
					<Route path={ this.props.match.path + '/info' } render={ () => (
						<AdminSchoolYearDetailInfo
							schoolYear={ this.props.schoolYear }
							updateSchoolYear={ this.props.updateSchoolYear }
						/>
					) } />
					<Route path={ this.props.match.path+'/days' } render={ () => (
						<AdminSchoolYearDetailDays
							schoolYear={ this.props.schoolYear }
						/>
					) } />
					<Route path={ this.props.match.path+'/timeslots' } render={ () => (
						<AdminSchoolYearDetailTimeSlots
							schoolYear={ this.props.schoolYear }
						/>
					) } />
					<Route path={ this.props.match.path+'/offers' } render={ () => (
						<AdminSchoolYearDetailRegularOffers
							schoolYear={ this.props.schoolYear }
						/>
					) } />
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

AdminSchoolYearDetail.propTypes = {
	schoolYear: PropTypes.object.isRequired,
	updateSchoolYear: PropTypes.func.isRequired
};

export default AdminSchoolYearDetail;

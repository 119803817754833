import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

class InlineEdit extends React.PureComponent {
	state = {
		type: this.props.type,
		value: this.props.value,
		lastSave: this.props.value,
		editing: false
	};

	input = null;

	toggleEdit = (event) => {
		event.preventDefault();
		this.setState({ editing: true });
	};

	handleChange = (event) => {
		if (this.props.type === 'checkbox') {
			this.setState({ value: event.target.checked });
		} else {
			this.setState({ value: event.target.value });
		}
	};

	handleKeyUp = (event) => {
		if (event.keyCode === 13) {
			this.applyChange();
		}
	};

	applyChange = () => {
		this.setState({ editing: false });
		let val = this.state.value;
		if (this.props.type === 'number') {
			val = parseFloat(val);
			if (isNaN(val)) { val = null; }
		}
		this.props.onEdited(val)
		.then(() => this.setState({ lastSave: val }))
		.catch(() => this.setState({ value: this.state.lastSave }));
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.type !== prevState.type || nextProps.value !== prevState.lastSave) {
			return {
				type: nextProps.type,
				value: nextProps.value,
				lastSave: nextProps.value,
				editing: false
			};
		} else {
			return null;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.editing && !prevState.editing) {
			this.input.focus();
		}
	}

	render() {
		if (this.state.editing) {
			switch (this.props.type) {
				case 'text':
					return (
						<input
							type={ this.props.type }
							value={ this.state.value }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							onKeyUp={ this.handleKeyUp }
							onBlur={ this.applyChange }
							style={{ width: '100%' }}
						/>
					);
				case 'number':
					return (
						<input
							type={ this.props.type }
							value={ this.state.value }
							min={ this.props.min === undefined ? '' : this.props.min }
							max={ this.props.max === undefined ? '' : this.props.max }
							step={ this.props.step === undefined ? '' : this.props.step }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							onKeyUp={ this.handleKeyUp }
							onBlur={ this.applyChange }
							style={{ width: '100%' }}
						/>
					);
				case 'date':
					return (
						<input
							type={ this.props.type }
							value={ this.state.value }
							min={ this.props.min === undefined ? '' : this.props.min }
							max={ this.props.max === undefined ? '' : this.props.max }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							onKeyUp={ this.handleKeyUp }
							onBlur={ this.applyChange }
							style={{ width: '100%' }}
						/>
					);
				case 'textarea':
					return (
						<textarea 
							value={ this.state.value }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							onKeyUp={ this.handleKeyUp }
							onBlur={ this.applyChange }
							style={{ width: '100%', height: '6rem' }}
						/>
					);
				case 'checkbox':
					return (
						<React.Fragment>
						<input
							type={ this.props.type }
							checked={ this.state.value }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							className="mr-2"
						/>
						<button onClick={ this.applyChange }>Apply</button>
						</React.Fragment>
					);
				case 'select':
					return (
						<React.Fragment>
						<select
							value={ this.state.value }
							ref={ (elem) => this.input = elem }
							onChange={ this.handleChange }
							className="mr-2"
						>
							{ this.props.options.map((o) => (
								<option key={ o } value={ o }>{ o }</option>
							)) }
						</select>
						<button onClick={ this.applyChange }>Apply</button>
						</React.Fragment>
					);
				default:
					return <span>Type is not supported</span>;
			}
		} else {
			let displ = this.state.value;
			if (this.props.type === 'checkbox') {
				displ = this.state.value ?
					<i style={{ color: 'green'}} className="fa fa-check"></i> :
					<i style={{ color: 'red' }} className="fa fa-ban"></i>;
			}
			if (this.props.type === 'date') {
				displ = format(displ, 'DD-MM-YYYY');
			}
			return (
				<span>
					{ displ }
					<button className="btn btn-small text-warning" style={{ display: 'inline', padding: '0 1rem', verticalAlign: 'bottom'}} onClick={ this.toggleEdit }>
						<i className="fa fa-pencil"></i>
					</button>
				</span>
			);
		}
	}
}

InlineEdit.propTypes = {
	type: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.bool ]),
	min: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	max: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	onEdited: PropTypes.func.isRequired
};

export default InlineEdit;

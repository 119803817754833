import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Paginate extends React.Component {
	state = {
		nbPagerLinks: 16,
		nbItemsPerPage: 20,
		nbPages: 0,
		startPage: 0,
		currentPage: 0
	};

	componentDidMount() {
		this.setPage(this.props.initialPage);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.nbItems !== prevProps.nbItems) {
			this.setPage(this.props.initialPage);
		}
	}

	setPage(page) {
		const nbPages = Math.ceil(this.props.nbItems / this.state.nbItemsPerPage);
		if (nbPages > 0) {
			let currentPage = page > 0 && page <= nbPages ? page : 1;
			let startPage, endPage;
			if (nbPages <= this.state.nbPagerLinks) {
				startPage = 1;
				endPage = nbPages;
			} else {
				if (currentPage <= (this.state.nbPagerLinks / 2 + 1)) {
					startPage = 1;
					endPage = this.state.nbPagerLinks;
				} else if (currentPage + (this.state.nbPagerLinks / 2 - 1) >= nbPages) {
					startPage = nbPages - (this.state.nbPagerLinks - 1);
					endPage = nbPages;
				} else {
					startPage = currentPage - (this.state.nbPagerLinks / 2);
					endPage = currentPage + (this.state.nbPagerLinks / 2 - 1);
				}
			}
			this.setState({
				nbPages,
				startPage,
				endPage,
				currentPage
			}, () => {
				const startIndex = (currentPage - 1) * this.state.nbItemsPerPage;
				const endIndex = Math.min(startIndex + this.state.nbItemsPerPage - 1, this.props.nbItems - 1);
				this.props.onPageChange(startIndex, endIndex, currentPage);
			});
		} else {
			this.setState({
				nbPages: 0
			});
		}
	}

	render() {
		if (this.state.nbPages < 2) {
			return null;
		}

		const pages = [];
		for (let i = 0; i < this.state.nbPagerLinks; ++i) {
			const pageIdx = this.state.startPage + i;
			if (pageIdx <= this.state.nbPages) {
				pages.push(pageIdx);
			}
		}

		return (
			<Pagination>
				<PaginationItem disabled={ this.state.currentPage === 1 }>
					<PaginationLink href="#" previous onClick={ (e) => { e.preventDefault(); this.setPage(this.state.currentPage - 1); } } />
				</PaginationItem>
				{ pages.map((page) =>
					<PaginationItem key={ page } active={ this.state.currentPage === page }>
						<PaginationLink href="#" onClick={ (e) => { e.preventDefault(); this.setPage(page); } }>{ page }</PaginationLink>
					</PaginationItem>
				)}
				<PaginationItem disabled={ this.state.currentPage === this.state.nbPages }>
					<PaginationLink href="#" next onClick={ (e) => { e.preventDefault(); this.setPage(this.state.currentPage + 1); } } />
				</PaginationItem>
			</Pagination>
		);
	}
};

Paginate.propTypes = {
	nbItems: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	initialPage: PropTypes.number.isRequired
};

export default Paginate;

import React from 'react';
import PropTypes from 'prop-types';
import {
	Col, Row
} from 'reactstrap';

import { fetchDocumentsOfDocumentCategory } from 'actions/document_category';

class DocumentCategory extends React.PureComponent {
	state = {
		dataReady: false,
		documents: []
	}

	componentDidMount() {
		fetchDocumentsOfDocumentCategory(this.props.documentCategory.id)
		.then((documents) => this.setState({
			documents,
			dataReady: true
		}));
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<h2 style={{ borderBottom: '2px solid black', marginBottom: '20px' }}>{ this.props.documentCategory.title }</h2>
			<Row>
				{ this.state.documents.map((doc) => (
					<Col key={ doc.id } className="text-center" xs="12" sm="6" md="4" lg="3">
						<a href={ doc.path }>
							<img src={ doc.thumbPath } alt={ doc.title } className="pg-thumbnail" />
						</a>
						<a href={ doc.path }>
							<p>{ doc.title }</p>
						</a>
					</Col>
				)) }
			</Row>
			</React.Fragment>
		);
	}
};

DocumentCategory.propTypes = {
	documentCategory: PropTypes.object.isRequired
};

export default DocumentCategory;

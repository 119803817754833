import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const addHolidayTimeSlot = (schoolYearId, holidayTimeSlot) => {
	holidayTimeSlot = {
		...holidayTimeSlot,
		SchoolYearId: schoolYearId
	};
	return fetch(apiBasename + '/holidayTimeSlot', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(holidayTimeSlot)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeHolidayTimeSlot = (id) => {
	return fetch(apiBasename + '/holidayTimeSlot/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

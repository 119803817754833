import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Link } from 'react-router-dom';
import { Row, Col, Form, FormGroup, Label, Input, ButtonGroup, Button } from 'reactstrap';
import format from 'date-fns/format';
import isBefore from 'date-fns/is_before';
import compareDesc from 'date-fns/compare_desc';

import { ConfigContext } from 'contexts/config';
import { AuthContext } from 'contexts/auth';
import Week from 'components/utils/week';

import { dayNames } from 'services/calendar';
import { strasbourgDistricts } from 'services/strasbourg_districts';
import { regimesSecu } from 'services/regimes_secu';

import {
	fetchSchoolYears,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchRegularOffersOfSchoolYear
} from 'actions/school_year';
import { fetchParentOfUser } from 'actions/user';
import { fetchChildrenOfParent } from 'actions/parent';
import { fetchRegularContractsOfChild } from 'actions/child';
import {
	submitRegularContractEnrolmentForm,
	submitRegularContractEnrolmentFormAuth
} from 'actions/regular_contract';

class RegularContractEnrolment extends React.Component {
	state = {
		children: [],
		childInfo: {
			id: -1,
			firstname: '',
			lastname: '',
			birthdate: format(new Date(), 'YYYY-MM-DD'),
			disabledStatus: false,
			observations: '',
			previousChildcare: '',
			homeLanguage: ''
		},
		parentInfo: {
			id: 0,
			firstname: '',
            lastname: '',
            telMobile: '',
            telHome: '',
            telPro: '',
            email: '',
			address: '',
			district: 'Autre',
			profession: '',
			employer: '',
			employerGrant: false,
			motherTongue: '',
			regimeSecu: 'Général/Local',
			cafNumber: '0'
		},
		contractInfo: {
			startDate: null,
			endDate: null,
			parentIncome: 0,
			parentNbChildren: 1,
			paymentMethod: 'debit',
			schoolYearId: null,
			selectedRegularOffersId: []
		},
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 },
		schoolYears: [],
		schoolYearsReady: false,
		selectedSchoolYearSchoolTimeSlotsByDay: [],
		selectedSchoolYearRegularOffers: [],
		selectedSchoolYearDataReady: false,
		submissionStatus: 'unsubmitted'
	}

	handleChildInfoChange = (value, prop) => {
		this.setState((prevState) => ({
			childInfo: { ...prevState.childInfo, [prop]: value }
		}));
	};

	handleParentInfoChange = (value, prop) => {
		this.setState((prevState) => ({
			parentInfo: { ...prevState.parentInfo, [prop]: value }
		}));
	};

	handleContractInfoChange = (value, prop) => {
		this.setState((prevState) => ({
			contractInfo: { ...prevState.contractInfo, [prop]: value }
		}));
	};

	toggleChild = (childId) => {
		if (childId === 0) {
			this.setState({
				childInfo: {
					id: 0,
					firstname: '',
					lastname: '',
					birthdate: format(new Date(), 'YYYY-MM-DD'),
					disabledStatus: false,
					observations: '',
					previousChildcare: '',
					homeLanguage: ''
				}
			})
		} else {
			fetchRegularContractsOfChild(childId)
			.then((childRegularContracts) => {
				childRegularContracts.sort((a, b) => compareDesc(a.startDate, b.startDate));

				let parentIncome = childRegularContracts.length > 0 ?
					childRegularContracts[0].parentIncome / 100 :
					0;
				let parentNbChildren = childRegularContracts.length > 0 ?
					childRegularContracts[0].parentNbChildren :
					1;
				let paymentMethod = childRegularContracts.length > 0 ?
					childRegularContracts[0].paymentMethod :
					'debit';
				
				this.setState((prevState) => ({
					childInfo: prevState.children.find((c) => c.id === childId),
					contractInfo: {
						...prevState.contractInfo,
						parentIncome,
						parentNbChildren,
						paymentMethod
					}
				}));
			});
		}
	};

	toggleSchoolYear = (schoolYearId) => {
		const schoolYear = this.state.schoolYears.find((s) => s.id === schoolYearId);
		this.setState(
			{ selectedSchoolYearDataReady: false },
			() => {
				Promise.all([
					fetchSchoolTimeSlotsOfSchoolYear(schoolYearId),
					fetchRegularOffersOfSchoolYear(schoolYearId)
				])
				.then(([ schoolTimeSlots, regularOffers ]) => {
					this.setState((prevState) => ({
						contractInfo: { 
							...prevState.contractInfo,
							startDate: schoolYear.startDate,
							endDate: schoolYear.endDate,
							schoolYearId,
							selectedRegularOffersId: []
						},
						selectedSchoolYearSchoolTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
							name: dayNames[i],
							slots: schoolTimeSlots.filter((sts) => sts.weekday === i).map((slot) => ({ ...slot, selected: false, active: true }))
						})),
						selectedSchoolYearRegularOffers: regularOffers,
						selectedSchoolYearDataReady: true
					}));
				});
			}
		);
	};

	toggleRegularOffer = (regularOfferId) => {
		this.setState((prevState) => {
			const selectedRegularOffersId = prevState.contractInfo.selectedRegularOffersId.includes(regularOfferId) ?
				prevState.contractInfo.selectedRegularOffersId.filter((sroid) => sroid !== regularOfferId) :
				[ ...prevState.contractInfo.selectedRegularOffersId, regularOfferId ];
			
			const selectedSchoolYearSchoolTimeSelectedSlotsId = [].concat(...selectedRegularOffersId.map((sroid) =>
				this.state.selectedSchoolYearRegularOffers
				.find((regularOffer) => regularOffer.id === sroid)
				.SchoolTimeSlots
				.map((sts) => sts.id)
			));

			return {
				contractInfo: { ...prevState.contractInfo, selectedRegularOffersId },
				selectedSchoolYearSchoolTimeSlotsByDay: prevState.selectedSchoolYearSchoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: selectedSchoolYearSchoolTimeSelectedSlotsId.includes(slot.id) }))
				}))
			};
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		let { id: childid, ...child } = this.state.childInfo;
		let { id: parentid, ...parent } = this.state.parentInfo;
		this.setState({ submissionStatus: 'submitted' });

		const data = {
			child: childid === 0 ? child : { id: childid },
			parent: parentid === 0 ? parent : { id: parentid },
			contract: {
				...this.state.contractInfo,
				parentIncome: this.state.contractInfo.parentIncome * 100
			}
		};
		let submissionPromise = this.props.user ?
			submitRegularContractEnrolmentFormAuth(data) :
			submitRegularContractEnrolmentForm(data);
		submissionPromise
		.then(() => {
			this.setState({ submissionStatus: 'success' });
		})
		.catch((err) => {
			this.setState({ submissionStatus: 'error' });
			setTimeout(() => { this.setState({ submissionStatus: 'unsubmitted' }); }, 4000);
		});
	};

	componentDidMount() {
		fetchSchoolYears()
		.then((schoolYears) => {
			if (this.props.user) {
				fetchParentOfUser(this.props.user.id)
				.then((parent) => {
					if (parent) { // user & parent
						fetchChildrenOfParent(parent.id)
						.then((children) => {
							this.setState((prevState) => ({
								children,
								parentInfo: parent,
								schoolYears: schoolYears.filter((s) => isBefore(new Date(), s.endDate)),
								schoolYearsReady: true
							}));
						});
					} else { // user & !parent
						this.setState((prevState) => ({
							childInfo: { ...prevState.childInfo, id: 0 },
							schoolYears: schoolYears.filter((s) => isBefore(new Date(), s.endDate)),
							schoolYearsReady: true
						}));
					}
				});
			} else { // not even user
				this.setState((prevState) => ({
					childInfo: { ...prevState.childInfo, id: 0 },
					schoolYears: schoolYears.filter((s) => isBefore(new Date(), s.endDate)),
					schoolYearsReady: true
				}));
			}
		});
	}

	render() {
		const parentDisabled = this.state.parentInfo.id !== 0;
		const childDisabled = this.state.childInfo.id !== 0;
		return (
			<React.Fragment>
			<h2 className="text-center">
				<i className="fa fa-clock-o"></i>{' '}
				Regular contract enrolment form
			</h2>
			<Row>
				<Col>
					<Form onSubmit={ this.handleSubmit }>
						<FormGroup tag="fieldset">
							<legend style={{ borderBottom: '1px solid gray' }}>Child information</legend>
							<p>Please provide here information about your child</p>
						</FormGroup>
						{/* { this.state.children.length > 0 && */
						<FormGroup className="text-center mb-4">
							<p>If you want to fill an enrolment form for one of your already registered child, select it in the following list, otherwise select "New child"</p>
							<ButtonGroup>
								{ this.state.children.map((child) => {
									const active = this.state.childInfo.id === child.id;
									const color = active ? 'success' : 'info';
									return (
										<Button key={ child.id } color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.toggleChild(child.id) }>
											{ child.firstname } { child.lastname }
										</Button>
									);
								}) }
							</ButtonGroup>
							<Button color="danger" className="ml-2" active={ this.state.childInfo.id === 0 } style={{ cursor: 'pointer' }} onClick={ () => this.toggleChild(0) }>
								New child
							</Button>
						</FormGroup> }
						{ this.state.childInfo.id !== -1 &&
						<FormGroup tag="fieldset">
							<FormGroup row>
								<Label for="firstname" className="text-right" xs={2}>Firstname</Label>
								<Col xs={4}>
									<Input type="text" disabled={ childDisabled } id="firstname" value={ this.state.childInfo.firstname } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'firstname') } />
								</Col>
								<Label for="lastname" className="text-right" xs={2}>Lastname</Label>
								<Col xs={4}>
									<Input type="text" disabled={ childDisabled } id="lastname" value={ this.state.childInfo.lastname } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'lastname') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="birthdate" className="text-right" xs={2}>Birthdate</Label>
								<Col xs={4}>
									<Input type="date" disabled={ childDisabled } id="birthdate" value={ this.state.childInfo.birthdate } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'birthdate') } />
								</Col>
								<Label for="disabledStatus" className="text-right" xs={2}>Disabled status</Label>
								<Col xs={4}>
									<Input type="checkbox" disabled={ childDisabled } style={{ marginLeft: '0.1rem'}} id="disabledStatus" checked={ this.state.childInfo.disabledStatus } onChange={ (e) => this.handleChildInfoChange(e.target.checked, 'disabledStatus') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="observations" className="text-right" xs={2}>Observations / Particular attentions</Label>
								<Col xs={10}>
									<Input type="textarea" disabled={ childDisabled } id="observations" value={ this.state.childInfo.observations } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'observations') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="previousChildcare" className="text-right" xs={2}>Previous childcare</Label>
								<Col xs={10}>
									<Input type="text" disabled={ childDisabled } id="previousChildcare" value={ this.state.childInfo.previousChildcare } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'previousChildcare') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="homeLanguage" className="text-right" xs={2}>Home language</Label>
								<Col xs={10}>
									<Input type="text" disabled={ childDisabled } id="homeLanguage" value={ this.state.childInfo.homeLanguage } onChange={ (e) => this.handleChildInfoChange(e.target.value, 'homeLanguage') } />
								</Col>
							</FormGroup>
						</FormGroup> }
						<FormGroup tag="fieldset">
							<legend style={{ borderBottom: '1px solid gray' }}>Parent information</legend>
							<p>Please provide here information about you (you will be able to provide information about a second parent later)</p>
							<FormGroup row>
								<Label for="parentfirstname" className="text-right" xs={2}>Firstname</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="parentfirstname" value={ this.state.parentInfo.firstname } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'firstname') } />
								</Col>
								<Label for="parentlastname" className="text-right" xs={2}>Lastname</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="parentlastname" value={ this.state.parentInfo.lastname } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'lastname') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="telMobile" className="text-right" xs={2}>Phone (mobile)</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="telMobile" value={ this.state.parentInfo.telMobile } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'telMobile') } />
								</Col>
								<Label for="telHome" className="text-right" xs={2}>Phone (home)</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="telHome" value={ this.state.parentInfo.telHome } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'telHome') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="telPro" className="text-right" xs={2}>Phone (pro)</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="telPro" value={ this.state.parentInfo.telPro } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'telPro') } />
								</Col>
								<Label for="email" className="text-right" xs={2}>Email address</Label>
								<Col xs={4}>
									<Input type="email" disabled={ parentDisabled } id="email" value={ this.state.parentInfo.email } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'email') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="address" className="text-right" xs={2}>Postal address</Label>
								<Col xs={10}>
									<Input type="text" disabled={ parentDisabled } id="address" value={ this.state.parentInfo.address } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'address') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="district" className="text-right" xs={2}>District</Label>
								<Col xs={10}>
									<Input type="select" disabled={ parentDisabled } id="district" value={ this.state.parentInfo.district } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'district') }>
										{ strasbourgDistricts.map((d) => <option key={ d }>{ d }</option>) }
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="profession" className="text-right" xs={2}>Profession</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="profession" value={ this.state.parentInfo.profession } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'profession') } />
								</Col>
								<Label for="employer" className="text-right" xs={2}>Employer</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="employer" value={ this.state.parentInfo.employer } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'employer') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="motherTongue" className="text-right" xs={2}>Mother tongue</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="motherTongue" value={ this.state.parentInfo.motherTongue } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'motherTongue') } />
								</Col>
								<Label for="employerGrant" className="text-right" xs={2}>Your employer gives you a grant for child care</Label>
								<Col xs={4}>
									<Input type="checkbox" disabled={ parentDisabled } style={{ marginLeft: '0.1rem'}} id="employerGrant" value={ this.state.parentInfo.employerGrant } onChange={ (e) => this.handleParentInfoChange(e.target.checked, 'employerGrant') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="regimeSecu" className="text-right" xs={2}>Régime Sécu</Label>
								<Col xs={4}>
									<Input type="select" disabled={ parentDisabled } id="regimeSecu" value={ this.state.parentInfo.regimeSecu } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'regimeSecu') }>
										{ regimesSecu.map((r) => <option key={r}>{ r }</option>) }
									</Input>
								</Col>
								<Label for="cafNumber" className="text-right" xs={2}>CAF number (if you have one)</Label>
								<Col xs={4}>
									<Input type="text" disabled={ parentDisabled } id="cafNumber" value={ this.state.parentInfo.cafNumber } onChange={ (e) => this.handleParentInfoChange(e.target.value, 'cafNumber') } />
								</Col>
							</FormGroup>
						</FormGroup>
						<hr />
						{ this.state.schoolYearsReady &&
						<FormGroup className="text-center">
							<p>Select the school year for which you want to fill an enrolment form</p>
							<ButtonGroup className="mb-2">
								{ this.state.schoolYears.map((schoolYear) => {
									const active = this.state.contractInfo.schoolYearId === schoolYear.id;
									const color = active ? 'success' : 'info';
									return (
										<Button key={ schoolYear.id } color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.toggleSchoolYear(schoolYear.id) }>
											{ schoolYear.title }
										</Button>
									);
								}) }
							</ButtonGroup>
						</FormGroup> }
						{ this.state.selectedSchoolYearDataReady &&
						<FormGroup className="text-center">
							<p>Select the offers you want to include in the contract</p>
							<ButtonGroup className="mb-2">
								{ this.state.selectedSchoolYearRegularOffers.map((regularOffer) => {
									const active = this.state.contractInfo.selectedRegularOffersId.includes(regularOffer.id);
									const color = active ? 'success' : 'info';
									return (
										<Button key={ regularOffer.id } color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.toggleRegularOffer(regularOffer.id) }>
											{ regularOffer.title }
										</Button>
									);
								}) }
							</ButtonGroup>
							<Week
								config={ this.state.weekConfig }
								slotsByDay={ this.state.selectedSchoolYearSchoolTimeSlotsByDay }
							/>
						</FormGroup> }
						<hr />
						<p className="text-center">Please update the following information if needed</p>
						<FormGroup row>
							<Label for="parentIncome" className="text-right" xs={2}>Annual gross income (year N-2)</Label>
							<Col xs={4}>
								<Input type="number" id="parentIncome" min={ 0 } value={ this.state.contractInfo.parentIncome } onChange={ (e) => this.handleContractInfoChange(e.target.value, 'parentIncome') } />
							</Col>
							<Label for="parentNbChildren" className="text-right" xs={2}>Number of dependant children</Label>
							<Col xs={4}>
								<Input type="number" id="parentNbChildren" min={ 1 } value={ this.state.contractInfo.parentNbChildren } onChange={ (e) => this.handleContractInfoChange(e.target.value, 'parentNbChildren') } />
							</Col>
						</FormGroup>
						<hr />
						{ this.state.submissionStatus === 'unsubmitted' &&
						<FormGroup className="text-center">
							<Button
								color="primary" style={{ cursor: 'pointer' }}
								disabled={ this.state.schoolYearId === null || this.state.contractInfo.selectedRegularOffersId.length === 0 }
							>
								<i className="fa fa-bolt"></i> Submit form
							</Button>
						</FormGroup> }
						{ this.state.submissionStatus === 'submitted' &&
						<FormGroup className="text-center">
							<p className="text-info">
								Waiting...
							</p>
						</FormGroup> }
						{ this.state.submissionStatus === 'success' &&
						<FormGroup className="text-center">
							<p className="text-success">
								The form has been successfully sent! You should receive an acknowledgment email in a short time.
								Please remind that this is not a registration but only a demand that has to be accepted by the Playgroup.
							</p>
							<p>
								<Link to={ '/' }>Go back home</Link>
							</p>
						</FormGroup> }
						{ this.state.submissionStatus === 'error' &&
						<FormGroup className="text-center">
							<p className="text-danger">
								Whoops.. A problem occured during the submission of the form.
							</p>
						</FormGroup> }
					</Form>
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

RegularContractEnrolment.propTypes = {
	user: PropTypes.object
};

export default subscribe(
	[ ConfigContext, AuthContext ],
	(config, auth) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId'),
		user: auth.user
	})
)(RegularContractEnrolment);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Table, Button
} from 'reactstrap';

import InlineEdit from 'components/utils/inline_edit';

import { fetchParentsOfChild } from 'actions/child';

class AdminChildDetailInfo extends React.Component {
	state = {
		dataReady: false,
		parents: []
	};

	handleChildEdited = (value, prop) => {
		return this.props.updateChild({ [prop]: value });
	};

	componentDidMount() {
		fetchParentsOfChild(this.props.child.id)
		.then((parents) => {
			this.setState({
				parents,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.child.id !== prevProps.child.id) {
			this.setState({ dataReady: false }, () => {
				fetchParentsOfChild(this.props.child.id)
				.then((parents) => {
					this.setState({
						parents,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Firstname</th>
						<td><InlineEdit type="text" value={ this.props.child.firstname } onEdited={ (firstname) => this.handleChildEdited(firstname, 'firstname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Lastname</th>
						<td><InlineEdit type="text" value={ this.props.child.lastname } onEdited={ (lastname) => this.handleChildEdited(lastname, 'lastname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Birthdate</th>
						<td><InlineEdit type="date" value={ this.props.child.birthdate } onEdited={ (birthdate) => this.handleChildEdited(birthdate, 'birthdate') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Disabled status</th>
						<td><InlineEdit type="checkbox" value={ this.props.child.disabledStatus } onEdited={ (disabledStatus) => this.handleChildEdited(disabledStatus, 'disabledStatus') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Observations</th>
						<td><InlineEdit type="textarea" value={ this.props.child.observations } onEdited={ (observations) => this.handleChildEdited(observations, 'observations') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Previous childcare</th>
						<td><InlineEdit type="text" value={ this.props.child.previousChildcare } onEdited={ (previousChildcare) => this.handleChildEdited(previousChildcare, 'previousChildcare') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Home language</th>
						<td><InlineEdit type="text" value={ this.props.child.homeLanguage } onEdited={ (homeLanguage) => this.handleChildEdited(homeLanguage, 'homeLanguage') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Allergy</th>
						<td><InlineEdit type="checkbox" value={ this.props.child.allergy } onEdited={ (allergy) => this.handleChildEdited(allergy, 'allergy') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Allergy description</th>
						<td><InlineEdit type="text" value={ this.props.child.allergyDesc } onEdited={ (allergyDesc) => this.handleChildEdited(allergyDesc, 'allergyDesc') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Meal type</th>
						<td><InlineEdit type="select" options={ [ 'Standard', 'Sans viande' ] } value={ this.props.child.mealType } onEdited={ (mealType) => this.handleChildEdited(mealType, 'mealType') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Doctor name</th>
						<td><InlineEdit type="text" value={ this.props.child.doctorName } onEdited={ (doctorName) => this.handleChildEdited(doctorName, 'doctorName') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Doctor address</th>
						<td><InlineEdit type="text" value={ this.props.child.doctorAddress } onEdited={ (doctorAddress) => this.handleChildEdited(doctorAddress, 'doctorAddress') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Doctor tel</th>
						<td><InlineEdit type="text" value={ this.props.child.doctorTel } onEdited={ (doctorTel) => this.handleChildEdited(doctorTel, 'doctorTel') }/></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Pending reduc</th>
						<td>{ this.props.child.pendingReduc / 100 }€</td>
					</tr>
					<tr>
						<th scope="row">Parents</th>
						<td>
							{ this.state.parents.map((parent) => (
								<Button key={ parent.id }
									tag={ Link } to={ '/admin/parents/' + parent.id }
									color="primary" size="sm" className="mr-2"
									style={{ cursor: 'pointer' }}>
									{ parent.firstname } { parent.lastname }
								</Button>
							)) }
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
};

AdminChildDetailInfo.propTypes = {
	child: PropTypes.object.isRequired,
	updateChild: PropTypes.func.isRequired
};

export default AdminChildDetailInfo;

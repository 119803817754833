import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Table, Input, Button, Alert
} from 'reactstrap';

import ConfirmationModal from 'components/utils/confirmation_modal';
import InlineEdit from 'components/utils/inline_edit';

import { fetchParentOfUser } from 'actions/user';

class AdminUserDetail extends React.Component {
	state = {
		dataReady: false,
		parent: null,
		newPassword: '',
		removeUserModalOpen: false,
		message: ''
	};

	handleUserEdited = (value, prop) => {
		return this.props.updateUser({ [prop]: value });
	};

	handleNewPasswordChange = (e) => {
		this.setState({
			newPassword: e.target.value
		});
	};

	changePassword = () => {
		this.props.updateUser({ password: this.state.newPassword })
		.then(() => {
			this.setState({
				newPassword: '',
				message: 'Password successfully changed'
			});
		});
	};

	componentDidMount() {
		fetchParentOfUser(this.props.user.id)
		.then((parent) => {
			this.setState({
				parent,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.user.id !== prevProps.user.id) {
			this.setState({ dataReady: false }, () => {
				fetchParentOfUser(this.props.user.id)
				.then((parent) => {
					this.setState({
						parent,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<div className="text-right">
				<Button size="sm" color="danger" className="mb-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeUserModalOpen: true }) }>
					Remove user{' '}<i className="fa fa-trash"></i>
				</Button>
			</div>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Username</th>
						<td><InlineEdit type="text" value={ this.props.user.username } onEdited={ (username) => this.handleUserEdited(username, 'username') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Password</th>
						<td className="d-flex">
							<Input type="password" value={ this.state.newPassword } onChange={ this.handleNewPasswordChange } />
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ this.changePassword }>Set password</Button>
						</td>
					</tr>
					{ this.state.message !== '' &&
						<tr>
							<td></td>
							<td><Alert color="success"> { this.state.message } </Alert></td>
						</tr>
					}
					<tr>
						<th scope="row">Parent</th>
						<td>
							{ this.state.parent ?
							<Button key={ this.state.parent.id }
								tag={ Link } to={ '/admin/parents/' + this.state.parent.id }
								color="primary" size="sm" className="mr-2"
								style={{ cursor: 'pointer' }}>
								{ this.state.parent.firstname } { this.state.parent.lastname }
							</Button> :
							null }
						</td>
					</tr>
				</tbody>
			</Table>
			<ConfirmationModal
				isOpen={ this.state.removeUserModalOpen }
				text={ 'Are you sure you want to remove this user (' + this.props.user.username + ') ?' }
				onConfirm={ () => { this.setState({ removeUserModalOpen: false }); this.props.removeUser(); } }
				onDismiss={ () => { this.setState({ removeUserModalOpen: false }); } }
			/>
			</React.Fragment>
		);
	}
};

AdminUserDetail.propTypes = {
	user: PropTypes.object.isRequired,
	updateUser: PropTypes.func.isRequired,
	removeUser: PropTypes.func.isRequired
};

export default AdminUserDetail;

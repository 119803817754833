import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Alert, Button, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import { AuthContext } from 'contexts/auth';

class UserSignin extends React.PureComponent {
	state = {
		username: '',
		password: '',
		message: ''
	};

	handleChange = (value, prop) => {
		this.setState({ [prop]: value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.signin({
			username: this.state.username,
			password: this.state.password
		})
		.catch((err) => {
			this.setState({
				password: '',
				message: err.message
			});
		});
	};
	
	render() {
		return (
			<Col sm="12" md={{ size: 6, offset: 3 }}>
				<Form onSubmit={ this.handleSubmit }>
					<FormGroup>
						<InputGroup>
							<InputGroupAddon addonType="prepend"><InputGroupText><i className="fa fa-user"></i></InputGroupText></InputGroupAddon>
							<Input type="text" value={ this.state.username } onChange={ (e) => this.handleChange(e.target.value, 'username') } name="username" id="username" placeholder="Username" autoFocus />
						</InputGroup>
					</FormGroup>
					<FormGroup>
						<InputGroup>
							<InputGroupAddon addonType="prepend"><InputGroupText><i className="fa fa-key"></i></InputGroupText></InputGroupAddon>
							<Input type="password" value={ this.state.password } onChange={ (e) => this.handleChange(e.target.value, 'password') } name="password" id="password" placeholder="Password" />
						</InputGroup>
					</FormGroup>
					<FormGroup>
						<Button color="primary" style={{ cursor: 'pointer' }}>
							<i className="fa fa-sign-in"></i> Signin
						</Button>
					</FormGroup>
					{ this.state.message !== '' &&
						<Alert color="danger"> { this.state.message } </Alert>
					}
				</Form>
			</Col>
		);
	}
}

UserSignin.propTypes = {
	signin: PropTypes.func.isRequired
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		signin: auth.signin
	})
)(UserSignin);

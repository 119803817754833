import React from 'react';
import PropTypes from 'prop-types';
import {
	Table, Button, Col,
	Form, FormGroup, Label, Input
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';

import ConfirmationModal from 'components/utils/confirmation_modal';
import InlineEdit from 'components/utils/inline_edit';

import { saveAs } from 'file-saver';

import { fetchDocumentsOfChild, addDocumentToChild } from 'actions/child';
import { updateChildDocument, fetchChildDocument, removeChildDocument } from 'actions/child_document';

class AdminChildDetailDocuments extends React.Component {
	state = {
		dataReady: false,
		childDocuments: [],
		removeChildDocumentId: 0,
		removeChildDocumentModalOpen: false,
		newDocumentForm: {
			title: '',
			file: null
		},
		uploadingDocument: false
	};

	handleChildDocumentEdited = (childDocumentId, value, prop) => {
		return updateChildDocument(childDocumentId, { [prop]: value })
		.then((childDocument) => {
			this.setState((prevState) => ({
				childDocuments: prevState.childDocuments.map((d) => d.id === childDocumentId ? { ...d, ...childDocument } : d)
			}));
		});
	};

	downloadChildDocument = (childDocumentId, filename) => {
		fetchChildDocument(childDocumentId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};

	handleRemoveChildDocument = () => {
		return removeChildDocument(this.state.removeChildDocumentId)
		.then(() => {
			this.setState((prevState) => ({
				childDocuments: prevState.childDocuments.filter((d) => d.id !== prevState.removeChildDocumentId),
				removeChildDocumentId: 0,
				removeChildDocumentModalOpen: false
			}));
		});
	};

	handleNewDocumentFormUpdate = (value, prop) => {
		this.setState((prevState) => ({
			newDocumentForm: { ...prevState.newDocumentForm, [prop]: value }
		}));
	};

	addDocumentToChild = (e) => {
		e.preventDefault();
		this.setState({ uploadingDocument: true });
		return addDocumentToChild(this.props.child.id, this.state.newDocumentForm)
		.then((doc) => {
			this.setState((prevState) => ({
				childDocuments: [ ...prevState.childDocuments, doc ],
				newDocumentForm: {
					title: ''
				},
				uploadingDocument: false
			}));
		});
	};

	componentDidMount() {
		fetchDocumentsOfChild(this.props.child.id)
		.then((childDocuments) => {
			this.setState({
				childDocuments,
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Table>
				<thead>
					<tr>
						<th>Title</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
				{ this.state.childDocuments.map((childDocument) => (
					<tr key={ childDocument.id }>
						<td><InlineEdit type="text" value={ childDocument.title } onEdited={ (title) => this.handleChildDocumentEdited(childDocument.id, title, 'title') } /></td>
						<td className="text-right">
							<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.downloadChildDocument(childDocument.id, childDocument.filename) }>
								<i className="fa fa-download"></i>{' '}Download
							</Button>
							<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeChildDocumentId: childDocument.id, removeChildDocumentModalOpen: true }) }>
								<i className="fa fa-trash"></i>{' '}Delete
							</Button>
						</td>
					</tr>
				)) }
				</tbody>
			</Table>
			<hr />
			<h4>Add document</h4>
			<Form onSubmit={ this.addDocumentToChild }>
				<FormGroup row>
					<Label for="documentTitle" sm={3}>Title</Label>
					<Col sm={9}>
						<Input type="text" value={ this.state.newDocumentForm.title } onChange={ (e) => this.handleNewDocumentFormUpdate(e.target.value, 'title') } name="documentTitle" id="documentTitle" />
					</Col>
				</FormGroup>
				<FormGroup>
					<Input type="file" onChange={ (e) => this.handleNewDocumentFormUpdate(e.target.files[0], 'file') } name="documentFile" id="documentFile" />
				</FormGroup>
				<FormGroup>
					<Button color="primary" style={{ cursor: 'pointer', width: '5rem', height: '2.5rem' }}>
						{ !this.state.uploadingDocument && <React.Fragment>
							<i className="fa fa-file-text"></i> Add
						</React.Fragment> }
						<PulseLoader size={10} color={'#ffffff'} loading={this.state.uploadingDocument} />
					</Button>
				</FormGroup>
			</Form>
			<ConfirmationModal
				isOpen={ this.state.removeChildDocumentModalOpen }
				text="Are you sure you want to remove this child document ?"
				onConfirm={ this.handleRemoveChildDocument }
				onDismiss={ () => { this.setState({ removeChildDocumentId: 0, removeChildDocumentModalOpen: false }); } }
			/>
			</React.Fragment>
		);
	}
};

AdminChildDetailDocuments.propTypes = {
	child: PropTypes.object.isRequired
};

export default AdminChildDetailDocuments;

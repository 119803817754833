import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import {
	Table, Input, Button, Alert
} from 'reactstrap';

import { AuthContext } from 'contexts/auth';

import { updateUser } from 'actions/user';

class UserProfile extends React.Component {
	state = {
		newPassword: '',
		newPasswordRepeat: '',
		message: '',
		messageColor: ''
	};

	handleChange = (value, prop) => {
		this.setState({ [prop]: value });
	};

	changePassword = () => {
		if (this.state.newPassword === this.state.newPasswordRepeat) {
			updateUser(this.props.user.id, { password: this.state.newPassword })
			.then(() => {
				this.setState({
					newPassword: '',
					newPasswordRepeat: '',
					message: 'Password successfully changed',
					messageColor: 'success'
				});
			});
		} else {
			this.setState({
				message: 'Entered passwords are different',
				messageColor: 'danger'
			});
		}
	};

	render() {
		return (
			<React.Fragment>
			<h2>User profile</h2>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Username</th>
						<td> { this.props.user.username } </td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Roles</th>
						<td> { this.props.user.roles.join(', ') } </td>
					</tr>
				</tbody>
			</Table>
			<h4>Change password</h4>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>New password</th>
						<td>
							<Input type="password" value={ this.state.newPassword } onChange={ (e) => this.handleChange(e.target.value, 'newPassword') } />
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Retype new password</th>
						<td>
							<Input type="password" value={ this.state.newPasswordRepeat } onChange={ (e) => this.handleChange(e.target.value, 'newPasswordRepeat') } />
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<Button color="danger" size="sm" style={{ cursor: 'pointer' }} onClick={ this.changePassword }>Change password</Button>
						</td>
					</tr>
					{ this.state.message !== '' &&
						<tr>
							<td></td>
							<td><Alert color={ this.state.messageColor }> { this.state.message } </Alert></td>
						</tr>
					}
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

UserProfile.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		username: PropTypes.string.isRequired,
		roles: PropTypes.arrayOf(PropTypes.string).isRequired
	}).isRequired
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		user: auth.user
	})
)(UserProfile);

export const timeStringToFloat = (time) => {
	const hm = time.split(/[.:]/);
	const h = parseInt(hm[0], 10);
	const m = hm[1] ? parseInt(hm[1], 10) : 0;
	return h + m / 60;
}

export const floatToTimeString = (f) => {
	const h = Math.trunc(f);
	const m = (f-h) * 60;
	return (h < 10 ? '0'+h : h) + ':' + (m < 10 ? '0'+m : m);
}

export const timeStringToHH = (time) => {
	const hms = time.split(/[.:]/);
	return hms[0];
};

export const timeStringToHHMM = (time) => {
	const hms = time.split(/[.:]/);
	return hms[0] + ':' + hms[1];
};

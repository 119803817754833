import React from 'react';
import PropTypes from 'prop-types';
import {
	Table
} from 'reactstrap';

import InlineEdit from 'components/utils/inline_edit';

import { strasbourgDistricts } from 'services/strasbourg_districts';
import { regimesSecu } from 'services/regimes_secu';

class ParentInfo extends React.PureComponent {

	handleParentEdited = (value, prop) => {
		return this.props.updateParent({ [prop]: value });
	};

	render() {
		return (
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Firstname</th>
						<td><InlineEdit type="text" value={ this.props.parent.firstname } onEdited={ (firstname) => this.handleParentEdited(firstname, 'firstname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Lastname</th>
						<td><InlineEdit type="text" value={ this.props.parent.lastname } onEdited={ (lastname) => this.handleParentEdited(lastname, 'lastname') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel home</th>
						<td><InlineEdit type="text" value={ this.props.parent.telHome } onEdited={ (telHome) => this.handleParentEdited(telHome, 'telHome') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel mobile</th>
						<td><InlineEdit type="text" value={ this.props.parent.telMobile } onEdited={ (telMobile) => this.handleParentEdited(telMobile, 'telMobile') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Tel pro</th>
						<td><InlineEdit type="text" value={ this.props.parent.telPro } onEdited={ (telPro) => this.handleParentEdited(telPro, 'telPro') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Email</th>
						<td><InlineEdit type="text" value={ this.props.parent.email } onEdited={ (email) => this.handleParentEdited(email, 'email') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Address</th>
						<td><InlineEdit type="text" value={ this.props.parent.address } onEdited={ (address) => this.handleParentEdited(address, 'address') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>District</th>
						<td><InlineEdit type="select" options={ strasbourgDistricts } value={ this.props.parent.district } onEdited={ (district) => this.handleParentEdited(district, 'district') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Profession</th>
						<td><InlineEdit type="text" value={ this.props.parent.profession } onEdited={ (profession) => this.handleParentEdited(profession, 'profession') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Employer</th>
						<td><InlineEdit type="text" value={ this.props.parent.employer } onEdited={ (employer) => this.handleParentEdited(employer, 'employer') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Mother tongue</th>
						<td><InlineEdit type="text" value={ this.props.parent.motherTongue } onEdited={ (motherTongue) => this.handleParentEdited(motherTongue, 'motherTongue') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Régime Sécu</th>
						<td><InlineEdit type="select" options={ regimesSecu } value={ this.props.parent.regimeSecu } onEdited={ (regimeSecu) => this.handleParentEdited(regimeSecu, 'regimeSecu') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>CAF number</th>
						<td><InlineEdit type="text" value={ this.props.parent.cafNumber } onEdited={ (cafNumber) => this.handleParentEdited(cafNumber, 'cafNumber') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Committee member</th>
						<td><InlineEdit type="checkbox" value={ this.props.parent.committeeMember } onEdited={ (committeeMember) => this.handleParentEdited(committeeMember, 'committeeMember') } /></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>Référence Unique de Mandat (RUM)</th>
						<td>{ this.props.parent.rum }</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%' }}>IBAN</th>
						<td>{ this.props.parent.iban }</td>
					</tr>
				</tbody>
			</Table>
		);
	}
};

ParentInfo.propTypes = {
	parent: PropTypes.object.isRequired,
	updateParent: PropTypes.func.isRequired
};

export default ParentInfo;

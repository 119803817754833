import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { subscribe } from 'react-contextual';
import {
	Col, Form, FormGroup, Label, Input, Table
} from 'reactstrap';
import format from 'date-fns/format';
import getDay from 'date-fns/get_day';
import getYear from 'date-fns/get_year';
import compareAsc from 'date-fns/compare_asc';

import { ConfigContext } from 'contexts/config';
import { timeStringToFloat, timeStringToHHMM } from 'services/time';

import {
	fetchSchoolYears,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchDayOfSchoolYearAtDate,
	fetchRegularOffersOfSchoolYear,
	fetchRegularContractsOfSchoolYearAtDate,
	fetchPunctualContractsOfSchoolYearAtDate,
	fetchHolidayContractsOfSchoolYearAtDate
} from 'actions/school_year';
import { fetchAbsencesOfDay } from 'actions/day';
import { createAbsence, deleteAbsence } from 'actions/absence';

class AdminAbsences extends React.Component {
	state = {
		dataReady: false,
		processingData: false,
		schoolYears: [],
		selectedSchoolYearId: 0,
		selectedSchoolYear: null,
		selectedSchoolYearSchoolTimeSlots: [],
		selectedSchoolYearSchoolTimeSlotsById: {},
		selectedSchoolYearHolidayTimeSlots: [],
		selectedSchoolYearHolidayTimeSlotsById: {},
		selectedSchoolYearRegularOffers: [],
		selectedSchoolYearRegularOffersById: {},
		selectedDate: '',
		selectedDay: null,
		selectedDaySlots: [],
		selectedDayChildrenSlots: []
	};

	updateSelectedDayData = () => {
		const weekday = (getDay(this.state.selectedDay.date) - 1 + 7) % 7;
		switch (this.state.selectedDay.type) {
			case 'school': {
				const selectedDaySlots = this.state.selectedSchoolYearSchoolTimeSlots
					.filter((s) => s.weekday === weekday)
					.map((slot) => ({ ...slot, nbRegistered: 0, nbAbsent: 0 }))
					.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime));
				
				Promise.all([
					fetchRegularContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, this.state.selectedDate),
					fetchPunctualContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, this.state.selectedDate),
					fetchAbsencesOfDay(this.state.selectedDay.id)
				])
				.then(([regularContracts, punctualContracts, absences]) => {
					const selectedDayChildrenSlots = regularContracts
						.map((rc) => {
							const offers = rc.RegularOffers.map((o) => this.state.selectedSchoolYearRegularOffersById[o.id]);
							const offerSlots = [].concat(...offers.map((o) => o.SchoolTimeSlots.map((slot) => this.state.selectedSchoolYearSchoolTimeSlotsById[slot.id])));
							const daySlots = offerSlots
								.filter((offerSlot) => offerSlot.weekday === weekday)
								.map((offerSlot) => {
									const slot = selectedDaySlots.find((s) => s.id === offerSlot.id);
									slot.nbRegistered++;
									return slot;
								})
								.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime));
							return {
								child: rc.Child,
								slots: daySlots,
								abs: absences.filter((a) => a.ChildId === rc.Child.id)
							};
						})
						.filter((cs) => cs.slots.length > 0)
						.sort((a,b) => compareAsc(a.child.birthdate, b.child.birthdate));
					
					punctualContracts.forEach((pc) => {
						const daySlots = pc.PunctualOffers
							.filter((o) => o.DayId === this.state.selectedDay.id)
							.map((o) => {
								const slot = selectedDaySlots.find((s) => s.id === o.SchoolTimeSlotId);
								slot.nbRegistered++;
								return slot;
							});
						const childSlots = selectedDayChildrenSlots.find((cs) => cs.child.id === pc.Child.id);
						if (childSlots) {
							daySlots.forEach((s) => childSlots.slots.push(s));
						} else {
							selectedDayChildrenSlots.push({
								child: pc.Child,
								slots: daySlots,
								abs: absences.filter((a) => a.ChildId === pc.Child.id)
							});
						}
					});
					
					selectedDaySlots.forEach((sds) => {
						sds.nbAbsent = absences.reduce((accu, abs) => abs.SchoolTimeSlotId === sds.id ? accu+1 : accu, 0);
					});
					
					this.setState({ selectedDaySlots, selectedDayChildrenSlots, processingData: false });
				});
				break;
			}
			case 'holiday': {
				const selectedDaySlots = this.state.selectedSchoolYearHolidayTimeSlots
					.filter((slot) => slot.weekday === weekday)
					.map((slot) => ({ ...slot, nbRegistered: 0, nbAbsent: 0 }))
					.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime));
				
				Promise.all([
					fetchHolidayContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, this.state.selectedDate),
					fetchAbsencesOfDay(this.state.selectedDay.id)
				])
				.then(([holidayContracts, absences]) => {
					const selectedDayChildrenSlots = holidayContracts
						.map((hc) => {
							const daySlots = hc.HolidayOffers
								.filter((o) => o.DayId === this.state.selectedDay.id)
								.map((o) => {
									const slot = selectedDaySlots.find((s) => s.id === o.HolidayTimeSlotId);
									slot.nbRegistered++;
									return slot;
								});
							return {
								child: hc.Child,
								slots: daySlots,
								abs: absences.filter((a) => a.ChildId === hc.Child.id)
							}
						});
					
					selectedDaySlots.forEach((sds) => {
						sds.nbAbsent = absences.reduce((accu, abs) => abs.HolidayTimeSlotId === sds.id ? accu+1 : accu, 0);
					});

					this.setState({ selectedDaySlots, selectedDayChildrenSlots, processingData: false });
				});
				break;
			}
			case 'closed': {
				this.setState({ selectedDaySlots: [], selectedDayChildrenSlots: [], processingData: false });
				break;
			}
			default: break;
		}
	};

	handleSelectedSchoolYearChange = (e) => {
		const selectedSchoolYearId = parseInt(e.target.value, 10);
		const selectedSchoolYear = this.state.schoolYears.find((s) => s.id === selectedSchoolYearId);
		const selectedDate = selectedSchoolYear.startDate;
		Promise.all([
			fetchSchoolTimeSlotsOfSchoolYear(selectedSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(selectedSchoolYearId),
			fetchRegularOffersOfSchoolYear(selectedSchoolYearId),
			fetchDayOfSchoolYearAtDate(selectedSchoolYearId, selectedDate)
		])
		.then(([sts, hts, ro, day]) => {
			const selectedSchoolYearSchoolTimeSlotsById = {};
			sts.forEach((s) => { selectedSchoolYearSchoolTimeSlotsById[s.id] = s; });
			const selectedSchoolYearHolidayTimeSlotsById = {};
			hts.forEach((s) => { selectedSchoolYearHolidayTimeSlotsById[s.id] = s; });
			const selectedSchoolYearRegularOffersById = {};
			ro.forEach((o) => { selectedSchoolYearRegularOffersById[o.id] = o; });
			this.setState({
				processingData: true,
				selectedSchoolYearId,
				selectedSchoolYear,
				selectedSchoolYearSchoolTimeSlots: sts,
				selectedSchoolYearSchoolTimeSlotsById,
				selectedSchoolYearHolidayTimeSlots: hts,
				selectedSchoolYearHolidayTimeSlotsById,
				selectedSchoolYearRegularOffers: ro,
				selectedSchoolYearRegularOffersById,
				selectedDate,
				selectedDay: day
			}, () => this.updateSelectedDayData());
		});
	};

	handleSelectedDateChange = (e) => {
		const selectedDate = e.target.value;
		fetchDayOfSchoolYearAtDate(this.state.selectedSchoolYearId, selectedDate)
		.then((day) => {
			this.setState({
				processingData: true,
				selectedDate,
				selectedDay: day
			}, () => this.updateSelectedDayData());
		});
	};

	addAbsence = (childid, slotid) => {
		createAbsence({
			SchoolYearId: this.state.selectedSchoolYear.id,
			ChildId: childid,
			DayId: this.state.selectedDay.id,
			SchoolTimeSlotId: this.state.selectedDay.type === 'school' ? slotid : null,
			HolidayTimeSlotId: this.state.selectedDay.type === 'holiday' ? slotid : null
		})
		.then((absence) => {
			this.setState((prevState) => ({
				selectedDayChildrenSlots: prevState.selectedDayChildrenSlots.map((cs) => cs.child.id === childid ?
					{ ...cs, abs: [ ...cs.abs, absence ] } :
					cs
				),
				selectedDaySlots: prevState.selectedDaySlots.map((s) => s.id === slotid ?
					{ ...s, nbAbsent: s.nbAbsent+1 } :
					s
				)
			}));
		});
	};

	removeAbsence = (childid, slotid, absenceid) => {
		deleteAbsence(absenceid)
		.then(() => {
			this.setState((prevState) => ({
				selectedDayChildrenSlots: prevState.selectedDayChildrenSlots.map((cs) => cs.child.id === childid ?
					{ ...cs, abs: cs.abs.filter((a) => a.id !== absenceid) } :
					cs
				),
				selectedDaySlots: prevState.selectedDaySlots.map((s) => s.id === slotid ?
					{ ...s, nbAbsent: s.nbAbsent-1 } :
					s
				)
			}));
		});
	};

	componentDidMount() {
		const selectedDate = format(new Date(), 'YYYY-MM-DD');
		Promise.all([
			fetchSchoolYears(),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.currentSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.currentSchoolYearId),
			fetchRegularOffersOfSchoolYear(this.props.currentSchoolYearId),
			fetchDayOfSchoolYearAtDate(this.props.currentSchoolYearId, selectedDate)
		])
		.then(([schoolYears, sts, hts, ro, day]) => {
			const selectedSchoolYearSchoolTimeSlotsById = {};
			sts.forEach((s) => { selectedSchoolYearSchoolTimeSlotsById[s.id] = s; });
			const selectedSchoolYearHolidayTimeSlotsById = {};
			hts.forEach((s) => { selectedSchoolYearHolidayTimeSlotsById[s.id] = s; });
			const selectedSchoolYearRegularOffersById = {};
			ro.forEach((o) => { selectedSchoolYearRegularOffersById[o.id] = o; });
			this.setState({
				dataReady: true,
				processingData: true,
				schoolYears,
				selectedSchoolYearId: this.props.currentSchoolYearId,
				selectedSchoolYear: schoolYears.find((s) => s.id === this.props.currentSchoolYearId),
				selectedSchoolYearSchoolTimeSlots: sts,
				selectedSchoolYearSchoolTimeSlotsById,
				selectedSchoolYearHolidayTimeSlots: hts,
				selectedSchoolYearHolidayTimeSlotsById,
				selectedSchoolYearRegularOffers: ro,
				selectedSchoolYearRegularOffersById,
				selectedDate,
				selectedDay: day
			}, () => this.updateSelectedDayData());
		});
	}

	render() {
		const byBirthYear = {};
		this.state.selectedDayChildrenSlots.forEach((cs) => {
			const birthyear = getYear(cs.child.birthdate);
			if (!byBirthYear.hasOwnProperty(birthyear)) { byBirthYear[birthyear] = []; }
			byBirthYear[birthyear].push(cs);
		});
		
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Form onSubmit={(e) => e.preventDefault()}>
				<FormGroup row>
					<Label for="schoolYear" xs={2} className="text-right">School year</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.selectedSchoolYearId} onChange={this.handleSelectedSchoolYearChange}>
							{this.state.schoolYears.map((schoolYear) => (
								<option key={schoolYear.id} value={parseInt(schoolYear.id, 10)}>{schoolYear.title}</option>
							))}
						</Input>
					</Col>
					<Label for="birthdate" xs={2} className="text-right">Date</Label>
					<Col xs={4}>
						<Input
							type="date" id="day" value={this.state.selectedDate} onChange={this.handleSelectedDateChange}
							min={this.state.selectedSchoolYear.startDate} max={this.state.selectedSchoolYear.endDate}
						/>
					</Col>
				</FormGroup>
			</Form>

			{ this.state.processingData ?
			'Processing data...' :
			this.state.selectedDay.type === 'closed' ?
			<p className="text-center">Playgroup is closed!</p> :
			<Table striped hover size="sm">
				<thead>
					<tr>
						<th>Child</th>
						{ this.state.selectedDaySlots.map((slot) => (
							<th key={slot.id} className="text-center">
								{timeStringToHHMM(slot.startTime)} - {timeStringToHHMM(slot.endTime)}
								<br />
								{slot.nbRegistered - slot.nbAbsent} / {slot.nbRegistered}
							</th>
						)) }
					</tr>
				</thead>
				<tbody>
					{ Object.keys(byBirthYear).map((birthyear) => (
						<React.Fragment key={birthyear}>
						<tr>
							<td className="text-center font-weight-bold" style={{backgroundColor: "#212529", color: "white"}}>
								{birthyear}
							</td>
						</tr>
						<React.Fragment>
						{ byBirthYear[birthyear].map((childSlots) => (
							<tr key={childSlots.child.id} style={{height: '120%'}}>
								<td>
									{childSlots.child.firstname} {childSlots.child.lastname}
									<Link to={ '/admin/children/' + childSlots.child.id + '/info' } className="ml-2"><i className="fa fa-external-link"></i></Link>
								</td>
								{ this.state.selectedDaySlots.map((slot) => {
									const isRegistered = childSlots.slots.some((s) => s.id === slot.id);
									if (isRegistered) {
										const absence = this.state.selectedDay.type === 'school' ?
											childSlots.abs.find((a) => a.SchoolTimeSlotId === slot.id) :
											childSlots.abs.find((a) => a.HolidayTimeSlotId === slot.id);
										if (absence) {
											return (
												<td key={slot.id} onClick={() => this.removeAbsence(childSlots.child.id, slot.id, absence.id)}>
													<div style={{display: 'flex'}}>
														<span className="text-center" style={{flex: 1, backgroundColor: 'lightgray', cursor: 'pointer'}}>
															<i style={{color: 'gray'}} className="fa fa-check"></i>
														</span>
														<span className="text-center" style={{flex: 1, backgroundColor: 'darkorange', cursor: 'pointer'}}>
															<i style={{color: 'red'}} className="fa fa-ban"></i>
														</span>
													</div>
												</td>
											);
										} else {
											return (
												<td key={slot.id} onClick={() => this.addAbsence(childSlots.child.id, slot.id)}>
													<div style={{display: 'flex'}}>
														<span className="text-center" style={{flex: 1, backgroundColor: 'lightgreen', cursor: 'pointer'}}>
															<i style={{color: 'green'}} className="fa fa-check"></i>
														</span>
														<span className="text-center" style={{flex: 1, backgroundColor: 'lightgray', cursor: 'pointer'}}>
															<i style={{color: 'gray'}} className="fa fa-ban"></i>
														</span>
													</div>
												</td>
											);
										}
									} else {
										return (
											<td key={slot.id}>
												<div style={{backgroundColor: 'lightsteelblue', minHeight: '1.5em'}}></div>
											</td>
										);
									}
								}) }
							</tr>
						)) }
						</React.Fragment>
						</React.Fragment>
					)) }
				</tbody>
			</Table> }
			</React.Fragment>
		);
	}
};

AdminAbsences.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminAbsences);

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const updateHolidayContract = (id, changes) => {
	return fetch(apiBasename + '/holidayContract/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeHolidayContract = (id, sendMail) => {
	return fetch(apiBasename + '/holidayContract/' + id, authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ sendMail })
	}))
	.then(checkStatus);
};

export const downloadHolidayContract = (id) => {
	return fetch(apiBasename + '/holidayContract/' + id + '/pdf', authorization())
	.then(checkStatus)
	.then((res) => res.blob());
};

export const addHolidayOfferToHolidayContract = (id, holidayOffer) => {
	return fetch(apiBasename + '/holidayContract/' + id + '/holidayOffers', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(holidayOffer)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeHolidayOfferFromHolidayContract = (id, holidayOfferId) => {
	return fetch(apiBasename + '/holidayContract/' + id + '/holidayOffers', authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ holidayOfferId })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const generateHolidayContractInvoice = (id) => {
	return fetch(apiBasename + '/holidayContract/' + id + '/invoice', authorization({
		method: 'POST'
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const submitHolidayContractEnrolmentForm = (formData) => {
	return fetch(apiBasename + '/holidayContract/demand', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(formData)
	})
	.then(checkStatus);
};

export const submitHolidayContractEnrolmentFormAuth = (formData) => {
	return fetch(apiBasename + '/holidayContract/demandAuth', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(formData)
	}))
	.then(checkStatus);
};

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const updateRegularContract = (id, changes) => {
	return fetch(apiBasename + '/regularContract/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeRegularContract = (id, sendMail) => {
	return fetch(apiBasename + '/regularContract/' + id, authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ sendMail })
	}))
	.then(checkStatus);
};

export const downloadRegularContract = (id) => {
	return fetch(apiBasename + '/regularContract/' + id + '/pdf', authorization())
	.then(checkStatus)
	.then((res) => res.blob());
};

export const addRegularOfferToRegularContract = (id, regularOfferId) => {
	return fetch(apiBasename + '/regularContract/' + id + '/regularOffers', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id: regularOfferId })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeRegularOfferFromRegularContract = (id, regularOfferId) => {
	return fetch(apiBasename + '/regularContract/' + id + '/regularOffers', authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id: regularOfferId })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const generateRegularContractInvoice = (id, year, month) => {
	return fetch(apiBasename + '/regularContract/' + id + '/invoice', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ year, month })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const submitRegularContractEnrolmentForm = (formData) => {
	return fetch(apiBasename + '/regularContract/demand', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(formData)
	})
	.then(checkStatus);
};

export const submitRegularContractEnrolmentFormAuth = (formData) => {
	return fetch(apiBasename + '/regularContract/demandAuth', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(formData)
	}))
	.then(checkStatus);
};

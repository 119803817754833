import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { subscribe } from 'react-contextual';
import {
	Row, Col, Form, FormGroup, Label, Input, Table, Button
} from 'reactstrap';
import compareAsc from 'date-fns/compare_asc';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import format from 'date-fns/format';

import { ConfigContext } from 'contexts/config';

import { saveAs } from 'file-saver';
import { monthNames } from 'services/calendar';

import {
	fetchSchoolYears,
	fetchRegularContractInvoicesOfSchoolYear,
	fetchPunctualContractInvoicesOfSchoolYear,
	fetchHolidayContractInvoicesOfSchoolYear
} from 'actions/school_year';
import { fetchChildren } from 'actions/child';
import { fetchParents } from 'actions/parent';
import {
	fetchRegularContractInvoice,
	updateRegularContractInvoice
} from 'actions/regular_contract_invoice';
import {
	fetchPunctualContractInvoice,
	updatePunctualContractInvoice
} from 'actions/punctual_contract_invoice';
import {
	fetchHolidayContractInvoice,
	updateHolidayContractInvoice
} from 'actions/holiday_contract_invoice';

class AccountantInvoices extends React.Component {
	state = {
		dataReady: false,
		processingData: false,
		schoolYears: [],
		childrenById: {},
		parentsById: {},
		regularContractInvoices: [],
		punctualContractInvoices: [],
		holidayContractInvoices: [],
		filters: {
			schoolYearId: this.props.currentSchoolYearId,
			contractType: 'regular',
			invoiceStatus: 'sent',
			paymentMethod: 'debit'
		},
		filteredInvoices: []
	};

	handleSelectedSchoolYearChange = (e) => {
		const selectedSchoolYearId = parseInt(e.target.value, 10);
		Promise.all([
			fetchRegularContractInvoicesOfSchoolYear(selectedSchoolYearId),
			fetchPunctualContractInvoicesOfSchoolYear(selectedSchoolYearId),
			fetchHolidayContractInvoicesOfSchoolYear(selectedSchoolYearId)
		])
		.then(([regularContractInvoices, punctualContractInvoices, holidayContractInvoices]) => {
			punctualContractInvoices.forEach((pci) => {
				pci.PunctualContract.startDate = pci.PunctualContract.PunctualOffers.length > 0 ?
					pci.PunctualContract.PunctualOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						pci.PunctualContract.PunctualOffers[0].Day.date
					) :
					this.state.schoolYears.find((s) => s.id === selectedSchoolYearId).startDate;
				pci.PunctualContract.endDate = pci.PunctualContract.PunctualOffers.length > 0 ?
					pci.PunctualContract.PunctualOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						pci.PunctualContract.PunctualOffers[0].Day.date
					) :
					this.state.schoolYears.find((s) => s.id === selectedSchoolYearId).startDate;
			});
			holidayContractInvoices.forEach((hci) => {
				hci.HolidayContract.startDate = hci.HolidayContract.HolidayOffers.length > 0 ?
					hci.HolidayContract.HolidayOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						hci.HolidayContract.HolidayOffers[0].Day.date
					) :
					this.state.schoolYears.find((s) => s.id === selectedSchoolYearId).startDate;
				hci.HolidayContract.endDate = hci.HolidayContract.HolidayOffers.length > 0 ?
					hci.HolidayContract.HolidayOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						hci.HolidayContract.HolidayOffers[0].Day.date
					) :
					this.state.schoolYears.find((s) => s.id === selectedSchoolYearId).startDate;
			});
			this.setState((prevState) => ({
				processingData: true,
				regularContractInvoices,
				punctualContractInvoices,
				holidayContractInvoices,
				filters: {
					...prevState.filters,
					schoolYearId: selectedSchoolYearId
				}
			}), () => {
				this.setState({
					processingData: false,
					filteredInvoices: this.filteredInvoices()
				});
			});
		});
	};

	handleFilterChange = (value, prop) => {
		this.setState((prevState) => ({
			processingData: true,
			filters: {
				...prevState.filters,
				[prop]: value
			}
		}), () => {
			this.setState({
				processingData: false,
				filteredInvoices: this.filteredInvoices()
			});
		});
	};

	filteredInvoices = () => {
		switch (this.state.filters.contractType) {
			case 'regular': {
				return this.state.regularContractInvoices
				.filter((i) => {
					return (this.state.filters.invoiceStatus === 'any' || i.status === this.state.filters.invoiceStatus) &&
						(this.state.filters.paymentMethod === 'any' || i.RegularContract.paymentMethod === this.state.filters.paymentMethod);
				})
				.sort((a, b) => {
					const childa = this.state.childrenById[a.RegularContract.ChildId];
					const childb = this.state.childrenById[b.RegularContract.ChildId];
					const dateCompare = compareAsc(new Date(a.year, a.month), new Date(b.year, b.month));
					return dateCompare !== 0 ? dateCompare : childa.lastname.localeCompare(childb.lastname);
				});
			}
			case 'punctual': {
				return this.state.punctualContractInvoices
				.filter((i) => {
					return (this.state.filters.invoiceStatus === 'any' || i.status === this.state.filters.invoiceStatus) &&
						(this.state.filters.paymentMethod === 'any' || i.PunctualContract.paymentMethod === this.state.filters.paymentMethod);
				})
				.sort((a,b) => {
					const childa = this.state.childrenById[a.PunctualContract.ChildId];
					const childb = this.state.childrenById[b.PunctualContract.ChildId];
					return childa.lastname.localeCompare(childb.lastname);
				});
			}
			case 'holiday': {
				return this.state.holidayContractInvoices
				.filter((i) => {
					return (this.state.filters.invoiceStatus === 'any' || i.status === this.state.filters.invoiceStatus) &&
						(this.state.filters.paymentMethod === 'any' || i.HolidayContract.paymentMethod === this.state.filters.paymentMethod);
				})
				.sort((a,b) => {
					const childa = this.state.childrenById[a.HolidayContract.ChildId];
					const childb = this.state.childrenById[b.HolidayContract.ChildId];
					return childa.lastname.localeCompare(childb.lastname);
				});
			}
			default: return [];
		}
	};

	updateInvoice = (invoiceId, changes) => {
		switch (this.state.filters.contractType) {
			case 'regular': {
				return updateRegularContractInvoice(invoiceId, changes)
				.then((invoice) => {
					this.setState((prevState) => ({
						processingData: true,
						regularContractInvoices: prevState.regularContractInvoices.map((i) => {
							return i.id === invoiceId ? { ...i, ...invoice } : i;
						})
					}), () => {
						this.setState({
							processingData: false,
							filteredInvoices: this.filteredInvoices()
						});
					});
				});
			}
			case 'punctual': {
				return updatePunctualContractInvoice(invoiceId, changes)
				.then((invoice) => {
					this.setState((prevState) => ({
						processingData: true,
						punctualContractInvoices: prevState.punctualContractInvoices.map((i) => {
							return i.id === invoiceId ? { ...i, ...invoice } : i;
						})
					}), () => {
						this.setState({
							processingData: false,
							filteredInvoices: this.filteredInvoices()
						});
					});
				});
			}
			case 'holiday': {
				return updateHolidayContractInvoice(invoiceId, changes)
				.then((invoice) => {
					this.setState((prevState) => ({
						processingData: true,
						holidayContractInvoices: prevState.holidayContractInvoices.map((i) => {
							return i.id === invoiceId ? { ...i, ...invoice } : i;
						})
					}), () => {
						this.setState({
							processingData: false,
							filteredInvoices: this.filteredInvoices()
						});
					});
				});
			}
			default: break;
		}
	};

	fetchInvoice = (invoiceId, filename) => {
		switch (this.state.filters.contractType) {
			case 'regular': {
				fetchRegularContractInvoice(invoiceId)
				.then((blob) => { saveAs(blob, filename); });
				break;
			}
			case 'punctual': {
				fetchPunctualContractInvoice(invoiceId)
				.then((blob) => { saveAs(blob, filename); });
				break;
			}
			case 'holiday': {
				fetchHolidayContractInvoice(invoiceId)
				.then((blob) => { saveAs(blob, filename); });
				break;
			}
			default: break;
		}
	};

	export = () => {
		const invoicesData = [];
		this.state.filteredInvoices.forEach((invoice) => {
			const invoiceData = {};
			let childId = 0;
			switch (this.state.filters.contractType) {
				case 'regular':
					childId = invoice.RegularContract.ChildId;
					invoiceData.type = 'regular';
					invoiceData.paymentMethod = invoice.RegularContract.paymentMethod;
					invoiceData.month = format(new Date(invoice.year, invoice.month), 'YYYY_MM');
					invoiceData.amountSchool = invoice.amountSchool;
					invoiceData.amountExtra = invoice.amountExtra;
					break;
				case 'punctual':
					childId = invoice.PunctualContract.ChildId;
					invoiceData.type = 'punctual';
					invoiceData.paymentMethod = invoice.PunctualContract.paymentMethod;
					invoiceData.month = format(invoice.PunctualContract.startDate, 'YYYY_MM');
					invoiceData.amountSchool = 0;
					invoiceData.amountExtra = invoice.amountExtra;
					break;
				case 'holiday':
					childId = invoice.HolidayContract.ChildId;
					invoiceData.type = 'holiday';
					invoiceData.paymentMethod = invoice.HolidayContract.paymentMethod;
					invoiceData.month = format(invoice.HolidayContract.startDate, 'YYYY_MM');
					invoiceData.amountSchool = 0;
					invoiceData.amountExtra = invoice.amountExtra;
					break;
				default:
					break;
			}
			invoiceData.child = this.state.childrenById[childId];
			invoiceData.parents = invoiceData.child.Parents.map((p) => this.state.parentsById[p.id]);
			invoiceData.address = '';
			if (invoiceData.parents[0] && invoiceData.parents[0].address) {
				invoiceData.address = invoiceData.parents[0].address;
			} else if (invoiceData.parents[1] && invoiceData.parents[1].address) {
				invoiceData.address = invoiceData.parents[1].address;
			}
			invoiceData.rum = '';
			if (invoiceData.parents[0] && invoiceData.parents[0].rum) {
				invoiceData.rum = invoiceData.parents[0].rum;
			} else if (invoiceData.parents[1] && invoiceData.parents[1].rum) {
				invoiceData.rum = invoiceData.parents[1].rum;
			}
			invoiceData.iban = '';
			if (invoiceData.parents[0] && invoiceData.parents[0].iban) {
				invoiceData.iban = invoiceData.parents[0].iban;
			} else if (invoiceData.parents[1] && invoiceData.parents[1].iban) {
				invoiceData.iban = invoiceData.parents[1].iban;
			}
			invoiceData.amount = invoice.amount;
			invoiceData.num = invoice.num;
			invoiceData.status = invoice.status;
			
			invoicesData.push(invoiceData);
		});
		
		let result = 'Child firstname;Child lastname;Parent 1 firstname;Parent 1 lastname;Parent 2 firstname;Parent 2 lastname;Parent address;Contract type;Payment method;RUM;IBAN;Amount;School amount;Extra amount;Number;Month;Status\n';
		invoicesData.forEach((i) => {
			result += i.child.firstname + ';' + i.child.lastname
				+ ';' + (i.parents[0] ? i.parents[0].firstname : '') + ';' + (i.parents[0] ? i.parents[0].lastname : '')
				+ ';' + (i.parents[1] ? i.parents[1].firstname : '') + ';' + (i.parents[1] ? i.parents[1].lastname : '')
				+ ';' + i.address
				+ ';' + i.type
				+ ';' + i.paymentMethod
				+ ';' + i.rum
				+ ';' + i.iban
				+ ';' + (i.amount / 100).toString().replace('.', ',')
				+ ';' + (i.amountSchool / 100).toString().replace('.', ',')
				+ ';' + (i.amountExtra / 100).toString().replace('.', ',')
				+ ';' + i.num
				+ ';' + i.month
				+ ';' + i.status
				+ '\n';
		});
		
		const today = new Date();
		const blob = new Blob([ result ], { type : 'text/csv;charset=utf-8' });
		saveAs(blob, 'invoices_extract_' + today.getFullYear() + '_' + (today.getMonth()+1) + '_' + today.getDate() + '.csv');
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYears(),
			fetchChildren(),
			fetchParents(),
			fetchRegularContractInvoicesOfSchoolYear(this.props.currentSchoolYearId),
			fetchPunctualContractInvoicesOfSchoolYear(this.props.currentSchoolYearId),
			fetchHolidayContractInvoicesOfSchoolYear(this.props.currentSchoolYearId)
		])
		.then(([schoolYears, children, parents, regularContractInvoices, punctualContractInvoices, holidayContractInvoices]) => {
			const childrenById = {};
			children.forEach((c) => childrenById[c.id] = c);
			const parentsById = {};
			parents.forEach((p) => parentsById[p.id] = p);

			punctualContractInvoices.forEach((pci) => {
				pci.PunctualContract.startDate = pci.PunctualContract.PunctualOffers.length > 0 ?
					pci.PunctualContract.PunctualOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						pci.PunctualContract.PunctualOffers[0].Day.date
					) :
					schoolYears.find((s) => s.id === this.props.currentSchoolYearId).startDate;
				pci.PunctualContract.endDate = pci.PunctualContract.PunctualOffers.length > 0 ?
					pci.PunctualContract.PunctualOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						pci.PunctualContract.PunctualOffers[0].Day.date
					) :
					schoolYears.find((s) => s.id === this.props.currentSchoolYearId).startDate;
			});
			holidayContractInvoices.forEach((hci) => {
				hci.HolidayContract.startDate = hci.HolidayContract.HolidayOffers.length > 0 ?
					hci.HolidayContract.HolidayOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						hci.HolidayContract.HolidayOffers[0].Day.date
					) :
					schoolYears.find((s) => s.id === this.props.currentSchoolYearId).startDate;
				hci.HolidayContract.endDate = hci.HolidayContract.HolidayOffers.length > 0 ?
					hci.HolidayContract.HolidayOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						hci.HolidayContract.HolidayOffers[0].Day.date
					) :
					schoolYears.find((s) => s.id === this.props.currentSchoolYearId).startDate;
			});
			this.setState((prevState) => ({
				dataReady: true,
				processingData: true,
				schoolYears,
				childrenById,
				parentsById,
				regularContractInvoices,
				punctualContractInvoices,
				holidayContractInvoices,
				filters: {
					...prevState.filters,
					schoolYearId: this.props.currentSchoolYearId
				}
			}), () => {
				this.setState({
					processingData: false,
					filteredInvoices: this.filteredInvoices()
				});
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Form onSubmit={(e) => e.preventDefault()}>
				<FormGroup row>
					<Label for="schoolYear" xs={2} className="text-right">School year</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.filters.schoolYearId} onChange={this.handleSelectedSchoolYearChange}>
							{this.state.schoolYears.map((schoolYear) => (
								<option key={schoolYear.id} value={parseInt(schoolYear.id, 10)}>{schoolYear.title}</option>
							))}
						</Input>
					</Col>
					<Label for="contractType" xs={2} className="text-right">Contract type</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.filters.contractType} onChange={(e) => this.handleFilterChange(e.target.value, 'contractType')}>
							<option value={'regular'}>Regular</option>
							<option value={'punctual'}>Punctual</option>
							<option value={'holiday'}>Holiday</option>
						</Input>
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="invoiceStatus" xs={2} className="text-right">Invoice status</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.filters.invoiceStatus} onChange={(e) => this.handleFilterChange(e.target.value, 'invoiceStatus')}>
							<option value={'any'}>-- any --</option>
							<option value={'edited'}>Edited</option>
							<option value={'sent'}>Sent</option>
							<option value={'paid'}>Paid</option>
						</Input>
					</Col>
					<Label for="paymentMethod" xs={2} className="text-right">Payment method</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.filters.paymentMethod} onChange={(e) => this.handleFilterChange(e.target.value, 'paymentMethod')}>
							<option value={'any'}>-- any --</option>
							<option value={'debit'}>Debit</option>
							<option value={'transfer'}>Transfer</option>
							<option value={'check'}>Check</option>
							<option value={'other'}>Other</option>
						</Input>
					</Col>
				</FormGroup>
			</Form>
			<Row className="mb-2">
				<Col className="text-right">
					<Button color="secondary" onClick={this.export}>Export</Button>
				</Col>
			</Row>
			{ this.state.processingData ?
			'Processing data...' :
			<Table striped hover size="sm">
				<thead>
					<tr>
						<th>Child</th>
						<th>Period</th>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th>Payment method</th>
						<th>Status</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ this.state.filteredInvoices.map((i) => {
						let child, period, paymentMethod;
						switch (this.state.filters.contractType) {
							case 'regular':
								child = this.state.childrenById[i.RegularContract.ChildId];
								period = monthNames[i.month] + ' ' + i.year;
								paymentMethod = i.RegularContract.paymentMethod;
								break;
							case 'punctual':
								child = this.state.childrenById[i.PunctualContract.ChildId];
								period = format(i.PunctualContract.startDate, 'DD-MM-YYYY') + ' -> ' + format(i.PunctualContract.endDate,  'DD-MM-YYYY');
								paymentMethod = i.PunctualContract.paymentMethod;
								break;
							case 'holiday':
								child = this.state.childrenById[i.HolidayContract.ChildId];
								period = format(i.HolidayContract.startDate, 'DD-MM-YYYY') + ' -> ' + format(i.HolidayContract.endDate,  'DD-MM-YYYY');
								paymentMethod = i.HolidayContract.paymentMethod;
								break;
							default:
								break;
						}
						return (
							<tr key={i.id}>
								<td>	
									{child.firstname} {child.lastname}
									<Link to={ '/accountant/children/' + child.id } className="ml-2"><i className="fa fa-external-link"></i></Link>
								</td>
								<td> {period} </td>
								<td> {i.amount/100}€ </td>
								<td> {i.paidAmount/100}€ </td>
								<td> {(i.amount-i.paidAmount)/100}€ </td>
								<td> {paymentMethod} </td>
								<td>
								{
									i.status === 'edited' ?
									<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
									i.status === 'sent' ?
									<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
									<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment>
								}
								</td>
								<td className="text-right">
									<React.Fragment>
									{ i.status === 'sent'
										&& (
											<Button color="success" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
												onClick={ () => this.updateInvoice(i.id, { status: 'paid', paidAmount: i.amount }) }
											>
												<i className="fa fa-check"></i>{' '}Mark as paid
											</Button>
										)
									}
									{ i.status === 'paid'
										&& (
											<Button color="danger" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
												onClick={ () => this.updateInvoice(i.id, { status: 'sent', paidAmount: 0 }) }
											>
												<i className="fa fa-times"></i>{' '}Mark as unpaid
											</Button>
										)
									}
									<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer' }}
										onClick={ () => this.fetchInvoice(i.id, i.filename) }
									>
										<i className="fa fa-download"></i>{' '}Download
									</Button>
									</React.Fragment>
								</td>
							</tr>
						);
					}) }
				</tbody>
			</Table> }
			</React.Fragment>
		);
	}
};

AccountantInvoices.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ConfigContext],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AccountantInvoices);

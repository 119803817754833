import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const createAbsence = (absence) => {
	return fetch(apiBasename + '/absence', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(absence)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const deleteAbsence = (id) => {
	return fetch(apiBasename + '/absence/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

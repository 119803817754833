import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import {
	Row, Col,
	Form, FormGroup, Label, Input,
	Table,
	Collapse, Button
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import format from 'date-fns/format';
import getDay from 'date-fns/get_day';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isEqual from 'date-fns/is_equal';
import isWithinRange from 'date-fns/is_within_range';
import differenceInYears from 'date-fns/difference_in_years';

import { saveAs } from 'file-saver';

import { strasbourgDistricts } from 'services/strasbourg_districts';
import { isMonthWithinRange } from 'services/calendar';
import { timeStringToFloat } from 'services/time';

import { ConfigContext } from 'contexts/config';

import {
	fetchSchoolYears,
	fetchDaysOfSchoolYearBetweenDates,
	fetchRegularContractsOfSchoolYear,
	fetchPunctualContractsOfSchoolYear,
	fetchHolidayContractsOfSchoolYear,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchRegularOffersOfSchoolYear,
	fetchAbsencesOfSchoolYearBetweenDates
} from 'actions/school_year';
import { fetchChildren } from 'actions/child';
import { fetchParents } from 'actions/parent';

class AdminStats extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		selectedSchoolYearId: 0,
		selectedSchoolYear: null,
		startDate: null,
		endDate: null,
		dayStats: {
			nbTotal: 0,
			nbSchool: 0,
			nbExtraOnly: 0
		},
		childStats: {
			nbTotal: 0,
			nbCAF: 0,
			nbStrasbourg: 0,
			nbStrasbourgByDistrict: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
			nbSchool: 0,
			nbExtra: 0,
			nbExtraHoliday: 0,
			nb12_14: 0,
			nb16_18: 0
		},
		amountStats: {
			schoolHoursCharged: 0,
			schoolHoursDone: 0,
			schoolAmount: 0,
			extraHoursCharged: 0,
			extraHoursDone: 0,
			extraAmount: 0,
			extraHoursCharged_CAFrate: 0,
			extraHoursDone_CAFrate: 0,
			extraAmount_CAFrate: 0
		},
		childrenDataCSV: '',
		computing: false,
		computed: false
	}

	handleSelectedSchoolYearChange = (e) => {
		const selectedSchoolYearId = parseInt(e.target.value, 10);
		const selectedSchoolYear = this.state.schoolYears.find((s) => s.id === selectedSchoolYearId);
		const startDate = selectedSchoolYear.startDate;
		const endDate = selectedSchoolYear.endDate;
		this.setState({
			selectedSchoolYearId,
			selectedSchoolYear,
			startDate,
			endDate
		});
	};

	handleStartDateChange = (e) => {
		this.setState({ startDate: e.target.value });
	};

	handleEndDateChange = (e) => {
		this.setState({ endDate: e.target.value });
	};

	compute = () => {
		this.setState({ computing: true, computed: false });

		Promise.all([
			fetchDaysOfSchoolYearBetweenDates(this.state.selectedSchoolYearId, this.state.startDate, this.state.endDate),
			fetchChildren(),
			fetchParents(),
			fetchRegularContractsOfSchoolYear(this.state.selectedSchoolYearId),
			fetchPunctualContractsOfSchoolYear(this.state.selectedSchoolYearId),
			fetchHolidayContractsOfSchoolYear(this.state.selectedSchoolYearId),
			fetchSchoolTimeSlotsOfSchoolYear(this.state.selectedSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.state.selectedSchoolYearId),
			fetchRegularOffersOfSchoolYear(this.state.selectedSchoolYearId),
			fetchAbsencesOfSchoolYearBetweenDates(this.state.selectedSchoolYearId, this.state.startDate, this.state.endDate)
		])
		.then(([ days, children, parents, regularContracts, punctualContracts, holidayContracts, schoolTimeSlots, holidayTimeSlots, regularOffers, absences ]) => {
			const childrenById = {};
			children.forEach((c) => childrenById[c.id] = c);
			const parentsById = {};
			parents.forEach((p) => parentsById[p.id] = p);
			const schoolTimeSlotsById = {};
			schoolTimeSlots.forEach((sts) => schoolTimeSlotsById[sts.id] = sts);
			const holidayTimeSlotsById = {};
			holidayTimeSlots.forEach((hts) => holidayTimeSlotsById[hts.id] = hts);
			const regularOffersById = {};
			regularOffers.forEach((ro) => regularOffersById[ro.id] = ro);

			const dayStats = {
				nbTotal: 0,
				nbSchool: 0,
				nbExtraOnly: 0
			};
			const childStats = {
				nbTotal: 0,
				nbCAF: 0,
				nbStrasbourg: 0,
				nbStrasbourgByDistrict: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
				nbSchool: 0,
				nbSchool_Strasbourg: 0,
				nbExtra: 0,
				nbExtra_Strasbourg: 0,
				nbExtraHoliday: 0,
				nbExtraHoliday_Strasbourg: 0,
				nbExtra12_14: 0,
				nbExtra12_14_Strasbourg: 0,
				nbExtra16_18: 0,
				nbExtra16_18_Strasbourg: 0
			};
			const amountStats = {
				schoolHoursCharged: 0,
				schoolHoursDone: 0,
				schoolAmount: 0,
				extraHoursCharged: 0,
				extraHoursDone: 0,
				extraAmount: 0,
				extraHoursCharged_CAFrate: 0,
				extraHoursDone_CAFrate: 0,
				extraAmount_CAFrate: 0
			};

			days.forEach((day) => {
				dayStats.nbTotal++;

				if (day.type === 'closed') { return; }

				const weekday = (getDay(day.date) - 1 + 7) % 7;
				
				if (day.type === 'school') {
					const extraOnly = schoolTimeSlots
						.filter((s) => s.weekday === weekday)
						.every((s) => s.type === 'extra');
					if (extraOnly) {
						dayStats.nbExtraOnly++;
					} else {
						dayStats.nbSchool++;
					}
				}

				if (day.type === 'holiday') {
					dayStats.nbExtraOnly++;
				}
			});

			const filteredRegularContracts = regularContracts.filter((rc) => {
				return rc.status === 'valid'
					&& (isEqual(rc.startDate, this.state.endDate) || isBefore(rc.startDate, this.state.endDate))
					&& (isEqual(rc.endDate, this.state.startDate) || isAfter(rc.endDate, this.state.startDate));
			});

			const filteredPunctualContracts = punctualContracts.map((pc) => ({
				...pc,
				startDate: pc.PunctualOffers.length > 0 ?
					pc.PunctualOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						pc.PunctualOffers[0].Day.date
					) :
					null,
				endDate: pc.PunctualOffers.length > 0 ?
					pc.PunctualOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						pc.PunctualOffers[0].Day.date
					) :
					null
			}))
			.filter((pc) => {
				return pc.status === 'valid'
					&& (isEqual(pc.startDate, this.state.endDate) || isBefore(pc.startDate, this.state.endDate))
					&& (isEqual(pc.endDate, this.state.startDate) || isAfter(pc.endDate, this.state.startDate));
			});

			const filteredHolidayContracts = holidayContracts.map((hc) => ({
				...hc,
				startDate: hc.HolidayOffers.length > 0 ?
					hc.HolidayOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						hc.HolidayOffers[0].Day.date
					) :
					null,
				endDate: hc.HolidayOffers.length > 0 ?
					hc.HolidayOffers.reduce(
						(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
						hc.HolidayOffers[0].Day.date
					) :
					null
			}))
			.filter((hc) => {
				return hc.status === 'valid'
					&& (isEqual(hc.startDate, this.state.endDate) || isBefore(hc.startDate, this.state.endDate))
					&& (isEqual(hc.endDate, this.state.startDate) || isAfter(hc.endDate, this.state.startDate));
			});

			const childDataById = {};
			const initChildData = (id) => {
				if (!childDataById.hasOwnProperty(id)) {
					const child = childrenById[id];
					const parents = child.Parents.map((p) => parentsById[p.id]);
					childDataById[id] = {
						id: id,

						child,
						parents,
						
						regularContracts: [],
						punctualContracts: [],
						holidayContracts: [],

						absences: {
							schoolTime: [],
							holidayTime: []
						},

						CAFrate: false,
						
						StrasbourgDistrict: parents[0].district,

						hasSchool: false,
						hasExtra: false,
						hasExtra12_14: false,
						hasExtra16_18: false,
						hasExtraHoliday: false,
						hasExtraSchool: false,
		
						schoolHoursCharged: 0,
						schoolHoursDone: 0,
						schoolAmount: 0,
						extraHoursCharged: 0,
						extraHoursDone: 0,
						extraAmount: 0,
						extraHoursCharged_CAFrate: 0,
						extraHoursDone_CAFrate: 0,
						extraAmount_CAFrate: 0
					};
				}
			};

			filteredRegularContracts.forEach((rc) => {
				initChildData(rc.ChildId);
				const childData = childDataById[rc.ChildId];
				childData.regularContracts.push(rc);
				if (rc.CAFrate) { childData.CAFrate = true; }
			});
			filteredPunctualContracts.forEach((pc) => {
				initChildData(pc.ChildId);
				const childData = childDataById[pc.ChildId];
				childData.punctualContracts.push(pc);
				if (pc.CAFrate) { childData.CAFrate = true; }
			});
			filteredHolidayContracts.forEach((hc) => {
				initChildData(hc.ChildId);
				const childData = childDataById[hc.ChildId];
				childData.holidayContracts.push(hc);
				if (hc.CAFrate) { childData.CAFrate = true; }
			});

			const filteredAbsences = absences.filter((a) => isWithinRange(a.Day.date, this.state.startDate, this.state.endDate));
			filteredAbsences.forEach((a) => {
				if (childDataById.hasOwnProperty(a.ChildId)) {
					const childData = childDataById[a.ChildId];
					if (a.SchoolTimeSlotId) {
						childData.absences.schoolTime.push(a);
					}
					if (a.HolidayTimeSlotId) {
						childData.absences.holidayTime.push(a);
					}
				}
			});

			Object.keys(childDataById).forEach((childId) => {
				childStats.nbTotal++;

				const childData = childDataById[childId];

				if (childData.CAFrate) { childStats.nbCAF++; }
				if (childData.StrasbourgDistrict !== 'Autre') { childStats.nbStrasbourg++; }
				const districtIndex = strasbourgDistricts.findIndex((d) => d === childData.StrasbourgDistrict);
				if (districtIndex >= 0) {
					childStats.nbStrasbourgByDistrict[districtIndex]++;
				}

				childData.regularContracts.forEach((rc) => {
					days.filter((d) => d.type === 'school' && isWithinRange(d.date, rc.startDate, rc.endDate))
					.forEach((d) => {
						const weekday = (getDay(d.date) - 1 + 7) % 7;
						const regularOffers = rc.RegularOffers.map((ro) => regularOffersById[ro.id]);
						const dayRegularOfferSlots =
							[].concat(...regularOffers.map((ro) => ro.SchoolTimeSlots.map((sts) => schoolTimeSlotsById[sts.id])))
							.filter((sts) => sts.weekday === weekday);

						dayRegularOfferSlots.forEach((sts) => {
							const slotDuration = timeStringToFloat(sts.endTime) - timeStringToFloat(sts.startTime);
							if (sts.type === 'school') {
								childData.hasSchool = true;
								childData.schoolHoursCharged += slotDuration;
							}
							if (sts.type === 'extra') {
								childData.hasExtra = true;
								childData.hasExtraSchool = true;
								let startTime = timeStringToFloat(sts.startTime);
								let endTime = timeStringToFloat(sts.endTime);
								if ((startTime >= 12 && startTime < 14) || (endTime > 12 && endTime <= 14)) { childData.hasExtra12_14 = true; }
								if ((startTime >= 16 && startTime < 18) || (endTime > 16 && endTime <= 18)) { childData.hasExtra16_18 = true; }
								childData.extraHoursCharged += slotDuration;
								if (rc.CAFrate) {
									childData.extraHoursCharged_CAFrate += slotDuration;
								}
							}
						});

						childData.absences.schoolTime.filter((a) => a.Day.id === d.id && dayRegularOfferSlots.some((ros) => ros.id === a.SchoolTimeSlotId))
						.forEach((a) => {
							const sts = schoolTimeSlotsById[a.SchoolTimeSlotId];
							const slotDuration = timeStringToFloat(sts.endTime) - timeStringToFloat(sts.startTime);
							if (sts.type === 'school') {
								childData.schoolHoursDone -= slotDuration;
							}
							if (sts.type === 'extra') {
								childData.extraHoursDone -= slotDuration;
								if (rc.CAFrate) {
									childData.extraHoursDone_CAFrate -= slotDuration;
								}
							}
						});
					});

					rc.RegularContractInvoices.forEach((rci) => {
						if (rci.status === 'paid' && isMonthWithinRange(rci.month, this.state.startDate, this.state.endDate)) {
							childData.schoolAmount += rci.amountSchool;
							childData.extraAmount += rci.amountExtra;
							if (rc.CAFrate) {
								childData.extraAmount_CAFrate += rci.amountExtra;
							}
						}
					});
				});

				childData.punctualContracts.forEach((pc) => {
					let contractHours = 0;
					let contractHoursWithinRange = 0;
					pc.PunctualOffers.forEach((po) => {
						const sts = schoolTimeSlotsById[po.SchoolTimeSlotId];
						const slotDuration = timeStringToFloat(sts.endTime) - timeStringToFloat(sts.startTime);
						contractHours += slotDuration;
						if (isWithinRange(po.Day.date, this.state.startDate, this.state.endDate)) {
							childData.hasExtra = true;
							childData.hasExtraSchool = true;
							let startTime = timeStringToFloat(sts.startTime);
							let endTime = timeStringToFloat(sts.endTime);
							if ((startTime >= 12 && startTime < 14) || (endTime > 12 && endTime <= 14)) { childData.hasExtra12_14 = true; }
							if ((startTime >= 16 && startTime < 18) || (endTime > 16 && endTime <= 18)) { childData.hasExtra16_18 = true; }
							contractHoursWithinRange += slotDuration;
							if (childData.absences.schoolTime.some((a) => a.Day.id === po.Day.id && a.SchoolTimeSlotId === sts.id)) {
								childData.extraHoursDone -= slotDuration;
								if (pc.CAFrate) {
									childData.extraHoursDone_CAFrate -= slotDuration;
								}
							}
						}
					});
					childData.extraHoursCharged += contractHoursWithinRange;
					if (pc.CAFrate) {
						childData.extraHoursCharged_CAFrate += contractHoursWithinRange;
					}

					if (pc.PunctualContractInvoice && pc.PunctualContractInvoice.status === 'paid') {
						let amount = Math.round((contractHoursWithinRange / contractHours) * pc.PunctualContractInvoice.amountExtra);
						childData.extraAmount += amount;
						if (pc.CAFrate) {
							childData.extraAmount_CAFrate += amount;
						}
					}
				});

				childData.holidayContracts.forEach((hc) => {
					let contractHours = 0;
					let contractHoursWithinRange = 0;
					hc.HolidayOffers.forEach((ho) => {
						const hts = holidayTimeSlotsById[ho.HolidayTimeSlotId];
						const slotDuration = timeStringToFloat(hts.endTime) - timeStringToFloat(hts.startTime);
						contractHours += slotDuration;
						if (isWithinRange(ho.Day.date, this.state.startDate, this.state.endDate)) {
							childData.hasExtra = true;
							childData.hasExtraHoliday = true;
							let startTime = timeStringToFloat(hts.startTime);
							let endTime = timeStringToFloat(hts.endTime);
							if ((startTime >= 12 && startTime < 14) || (endTime > 12 && endTime <= 14)) { childData.hasExtra12_14 = true; }
							if ((startTime >= 16 && startTime < 18) || (endTime > 16 && endTime <= 18)) { childData.hasExtra16_18 = true; }
							contractHoursWithinRange += slotDuration;
							if (childData.absences.holidayTime.some((a) => a.Day.id === ho.Day.id && a.HolidayTimeSlotId === hts.id)) {
								childData.extraHoursDone -= slotDuration;
								if (hc.CAFrate) {
									childData.extraHoursDone_CAFrate -= slotDuration;
								}
							}
						}
					});
					childData.extraHoursCharged += contractHoursWithinRange;
					if (hc.CAFrate) {
						childData.extraHoursCharged_CAFrate += contractHoursWithinRange;
					}

					if (hc.HolidayContractInvoice && hc.HolidayContractInvoice.status === 'paid') {
						let amount = Math.round((contractHoursWithinRange / contractHours) * hc.HolidayContractInvoice.amountExtra);
						childData.extraAmount += amount;
						if (hc.CAFrate) {
							childData.extraAmount_CAFrate += amount;
						}
					}
				});

				childData.schoolHoursDone += childData.schoolHoursCharged;
				childData.extraHoursDone += childData.extraHoursCharged;
				childData.extraHoursDone_CAFrate += childData.extraHoursCharged_CAFrate;

				if (childData.hasSchool) {
					childStats.nbSchool++;
					if (childData.StrasbourgDistrict !== 'Autre') {
						childStats.nbSchool_Strasbourg++;
					}
				}
				if (childData.hasExtra) {
					childStats.nbExtra++;
					if (childData.StrasbourgDistrict !== 'Autre') {
						childStats.nbExtra_Strasbourg++;
					}
				}
				if (childData.hasExtraHoliday) {
					childStats.nbExtraHoliday++;
					if (childData.StrasbourgDistrict !== 'Autre') {
						childStats.nbExtraHoliday_Strasbourg++;
					}
				}
				if (childData.hasExtra12_14) {
					childStats.nbExtra12_14++;
					if (childData.StrasbourgDistrict !== 'Autre') {
						childStats.nbExtra12_14_Strasbourg++;
					}
				}
				if (childData.hasExtra16_18) {
					childStats.nbExtra16_18++;
					if (childData.StrasbourgDistrict !== 'Autre') {
						childStats.nbExtra16_18_Strasbourg++;
					}
				}

				amountStats.schoolHoursCharged += childData.schoolHoursCharged;
				amountStats.schoolHoursDone += childData.schoolHoursDone;
				amountStats.schoolAmount += childData.schoolAmount;
				amountStats.extraHoursCharged += childData.extraHoursCharged;
				amountStats.extraHoursCharged_CAFrate += childData.extraHoursCharged_CAFrate;
				amountStats.extraHoursDone += childData.extraHoursDone;
				amountStats.extraHoursDone_CAFrate += childData.extraHoursDone_CAFrate;
				amountStats.extraAmount += childData.extraAmount;
				amountStats.extraAmount_CAFrate += childData.extraAmount_CAFrate;
			});

			const childrenDataSorted = Object.keys(childDataById)
				.map((childId) => childDataById[childId])
				.sort((a, b) => a.child.lastname.localeCompare(b.child.lastname));
			let childrenDataCSV = 'id;firstname;lastname;age;district;school;extra;holidays;12h-14h;16h-18h;CAFRate;extraHoursCharged;extraHoursCharged (CAFrate);extraHoursDone;extraHoursDone (CAFrate);extraAmount;extraAmount (CAFrate);schoolHoursCharged;schoolHoursDone;schoolAmount\n';
			childrenDataSorted.forEach((childData) => {
				const { child } = childData;
				childrenDataCSV += child.id + ';' + child.firstname + ';' + child.lastname + ';' + differenceInYears(this.state.endDate, child.birthdate) + ';'
							+ childData.StrasbourgDistrict + ';' + childData.hasSchool + ';' + childData.hasExtraSchool + ';' + childData.hasExtraHoliday + ';'
							+ childData.hasExtra12_14 + ';' + childData.hasExtra16_18 + ';' + childData.CAFrate + ';'
							+ childData.extraHoursCharged + ';' + childData.extraHoursCharged_CAFrate + ';' + childData.extraHoursDone + ';' + childData.extraHoursDone_CAFrate + ';' + (childData.extraAmount / 100).toString().replace('.', ',') + ';' + (childData.extraAmount_CAFrate / 100).toString().replace('.', ',') + ';'
							+ childData.schoolHoursCharged + ';' + childData.schoolHoursDone + ';' + (childData.schoolAmount / 100).toString().replace('.', ',') + '\n';
			});

			this.setState({
				dayStats,
				childStats,
				amountStats,
				childrenDataCSV,
				computing: false,
				computed: true
			});
		});
	};

	export = () => {
		const blob = new Blob([ this.state.childrenDataCSV ], { type: 'text/csv;charset=utf-8' });
		saveAs(blob, 'export.csv');
	};
	
	componentDidMount() {
		fetchSchoolYears()
		.then((schoolYears) => {
			const currentSchoolYear = schoolYears.find((s) => s.id === this.props.currentSchoolYearId);
			this.setState({
				schoolYears,
				selectedSchoolYearId: this.props.currentSchoolYearId,
				selectedSchoolYear: currentSchoolYear,
				startDate: format(currentSchoolYear.startDate, 'YYYY-MM-DD'),
				endDate: format(currentSchoolYear.endDate, 'YYYY-MM-DD'),
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Form onSubmit={(e) => e.preventDefault()}>
				<FormGroup row>
					<Label for="schoolYear" xs={1} className="text-right">School year</Label>
					<Col xs={3}>
						<Input type="select" value={this.state.selectedSchoolYearId} onChange={this.handleSelectedSchoolYearChange}>
							{this.state.schoolYears.map((schoolYear) => (
								<option key={schoolYear.id} value={parseInt(schoolYear.id, 10)}>{schoolYear.title}</option>
							))}
						</Input>
					</Col>
					<Label for="startdate" xs={1} className="text-right">Start date</Label>
					<Col xs={3}>
						<Input
							type="date" id="startdate" value={this.state.startDate} onChange={this.handleStartDateChange}
							min={this.state.selectedSchoolYear.startDate} max={this.state.selectedSchoolYear.endDate}
						/>
					</Col>
					<Label for="enddate" xs={1} className="text-right">End date</Label>
					<Col xs={3}>
						<Input
							type="date" id="enddate" value={this.state.endDate} onChange={this.handleEndDateChange}
							min={this.state.selectedSchoolYear.startDate} max={this.state.selectedSchoolYear.endDate}
						/>
					</Col>
				</FormGroup>
			</Form>
			<Row>
				<Col xs={9}>
					<Button block color="primary" onClick={this.compute}>
						{ !this.state.computing && <React.Fragment>
							<i className="fa fa-bolt"></i> Compute
						</React.Fragment> }
						<PulseLoader size={10} color={'#ffffff'} loading={this.state.computing} />
					</Button>
				</Col>
				{ this.state.computed &&
				<Col xs={3}>
					<Button block color="secondary" onClick={this.export}>Export</Button>
				</Col> }
			</Row>
			
			<hr />
			<h3>Calendrier</h3>
			<Table striped hover size="sm">
				<tbody>
					<tr>
						<td style={{ width: '80%' }}>Nombre total de jours sur la période</td>
						<td style={{ width: '20%' }}>{ this.state.computed ? this.state.dayStats.nbTotal : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont jours d'accueil scolaire</span></td>
						<td>{ this.state.computed ? this.state.dayStats.nbSchool : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont jours d'accueil exclusivement périscolaire</span></td>
						<td>{ this.state.computed ? this.state.dayStats.nbExtraOnly : '-' }</td>
					</tr>
				</tbody>
			</Table>

			<hr />
			<h3>Enfants</h3>
			<Table striped hover size="sm">
				<tbody>
					<tr>
						<td style={{ width: '80%' }}>Nombre total d'enfants accueillis sur la période</td>
						<td style={{ width: '20%' }}>{ this.state.computed ? this.state.childStats.nbTotal : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont tarif CAF</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbCAF : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbStrasbourg : '-' }</td>
					</tr>
				</tbody>
			</Table>
			<p style={{ cursor: 'pointer', color: 'white', backgroundColor: 'rgba(52, 58, 64, 0.60)' }} className="p-2 d-flex align-items-center" onClick={ () => this.setState((prevState) => ({ showNbChildrenByDistrict: !prevState.showNbChildrenByDistrict })) }>
				Détail par quartier
				{ this.state.showNbChildrenByDistrict ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
			</p>
			<Collapse isOpen={this.state.showNbChildrenByDistrict}>
				<Table>
					<tbody>
						{ strasbourgDistricts.map((d, idx) => (
							<tr>
								<td><span className="ml-5">{ d }</span></td>
								<td>{ this.state.computed ? this.state.childStats.nbStrasbourgByDistrict[idx] : '-' }</td>
							</tr>
						)) }
					</tbody>
				</Table>
			</Collapse>

			<hr />
			<h3>Accueil scolaire</h3>
			<Table striped hover size="sm">
				<tbody>
					<tr>
						<td style={{ width: '80%' }}>Nombre total d'enfants accueillis en temps scolaire</td>
						<td style={{ width: '20%' }}>{ this.state.computed ? this.state.childStats.nbSchool : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbSchool_Strasbourg : '-' }</td>
					</tr>
					<tr>
						<td>Nombre d'heures facturées</td>
						<td>{ (this.state.computed ? this.state.amountStats.schoolHoursCharged : '-') + ' h' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont effectuées</span></td>
						<td>{ (this.state.computed ? this.state.amountStats.schoolHoursDone : '-') + ' h' }</td>
					</tr>
					<tr>
						<td>Montant total scolarité facturé</td>
						<td>{ (this.state.computed ? (this.state.amountStats.schoolAmount / 100) : '-') + ' €' }</td>
					</tr>
				</tbody>
			</Table>

			<hr />
			<h3>Accueil périscolaire</h3>
			<Table striped hover size="sm">
				<tbody>
					<tr>
						<td style={{ width: '80%' }}>Nombre total d'enfants accueillis en temps périscolaire</td>
						<td style={{ width: '20%' }}>{ this.state.computed ? this.state.childStats.nbExtra : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtra_Strasbourg : '-' }</td>
					</tr>

					<tr>
						<td><span className="ml-3">Dont en période de vacances</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtraHoliday : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-5">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtraHoliday_Strasbourg : '-' }</td>
					</tr>

					<tr>
						<td><span className="ml-3">Dont sur des créneaux 12h-14h</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtra12_14 : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-5">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtra12_14_Strasbourg : '-' }</td>
					</tr>

					<tr>
						<td><span className="ml-3">Dont sur des créneaux 16h-18h</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtra16_18 : '-' }</td>
					</tr>
					<tr>
						<td><span className="ml-5">Dont résidents strasbourgeois</span></td>
						<td>{ this.state.computed ? this.state.childStats.nbExtra16_18_Strasbourg : '-' }</td>
					</tr>

					<tr>
						<td>Nombre d'heures facturées</td>
						<td>{ (this.state.computed ? this.state.amountStats.extraHoursCharged : '-') + ' h' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont tarif CAF</span></td>
						<td>{ (this.state.computed ? this.state.amountStats.extraHoursCharged_CAFrate : '-') + ' h' }</td>
					</tr>
					<tr>
						<td>Nombre d'heures effectuées</td>
						<td>{ (this.state.computed ? this.state.amountStats.extraHoursDone : '-') + ' h' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont tarif CAF</span></td>
						<td>{ (this.state.computed ? this.state.amountStats.extraHoursDone_CAFrate : '-') + ' h' }</td>
					</tr>

					<tr>
						<td>Montant total périscolaire facturé</td>
						<td>{ (this.state.computed ? (this.state.amountStats.extraAmount / 100) : '-') + ' €' }</td>
					</tr>
					<tr>
						<td><span className="ml-3">Dont tarif CAF</span></td>
						<td>{ (this.state.computed ? (this.state.amountStats.extraAmount_CAFrate / 100) : '-') + ' €' }</td>
					</tr>
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

AdminStats.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminStats);

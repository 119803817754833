import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	ButtonGroup, Button
} from 'reactstrap';
import getDay from 'date-fns/get_day';
import format from 'date-fns/format';

import { dayNames } from 'services/calendar';

import { fetchDaysOfSchoolYear } from 'actions/school_year';
import { updateDay } from 'actions/day';

class AdminSchoolYearDetailDays extends React.Component {
	state = {
		dataReady: false,
		days: []
	};

	handleDayTypeChange = (dayId, type) => {
		updateDay(dayId, { type })
		.then((day) => {
			this.setState((prevState) => ({
				days: prevState.days.map((d) => d.id === dayId ? { ...d, ...day } : d)
			}));
		});
	};

	componentDidMount() {
		fetchDaysOfSchoolYear(this.props.schoolYear.id)
		.then((days) => {
			this.setState({
				days,
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Table>
				<tbody>
					{ this.state.days.map((day) => (
						<tr key={ day.id }>
							<td style={{ verticalAlign: 'middle' }}>
								{ format(day.date, 'DD-MM-YYYY') }
							</td>
							<td style={{ verticalAlign: 'middle' }}>
								{ dayNames[(getDay(day.date) - 1 + 7) % 7] }
							</td>
							<td>
								<ButtonGroup>
								{ ['school', 'holiday', 'closed'].map((t) => {
									const active = day.type === t;
									const color = active && t === 'school' ? 'success' :
									              active && t === 'holiday' ? 'info' :
									              active && t === 'closed' ? 'danger' :
									              'secondary';
									return (
										<Button key={t} size="sm" color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.handleDayTypeChange(day.id, t) }>
											{ t }
										</Button>
									);
								}) }
								</ButtonGroup>
							</td>
						</tr>
					)) }
				</tbody>
			</Table>
		);
	}
};

AdminSchoolYearDetailDays.propTypes = {
	schoolYear: PropTypes.object.isRequired
};

export default AdminSchoolYearDetailDays;

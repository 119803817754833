import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Route, NavLink } from 'react-router-dom';
import {
	ButtonGroup, Button
} from 'reactstrap';

import { AuthContext } from 'contexts/auth';

import { fetchParentOfUser } from 'actions/user';
import { fetchChildrenOfParent, updateParent } from 'actions/parent';
import { updateChild } from 'actions/child';

import ParentInfo from 'components/parent/info';
import ParentChildDetail from 'components/parent/child';

class Parent extends React.PureComponent {
	state = {
		parent: null,
		children: [],
		dataReady: false
	};

	updateParent = (changes) => {
		return updateParent(this.state.parent.id, changes)
		.then((parent) => {
			this.setState((prevState) => ({
				parent: { ...prevState.parent, ...parent }
			}));
		});
	};

	updateChild = (childId, changes) => {
		return updateChild(childId, changes)
		.then((child) => {
			this.setState((prevState) => ({
				children: prevState.children.map((c) => c.id === childId ? { ...c, ...child } : c)
			}));
		});
	};

	componentDidMount() {
		let parent = null;
		fetchParentOfUser(this.props.user.id)
		.then((p) => {
			parent = p;
			return fetchChildrenOfParent(parent.id);
		})
		.then((children) => {
			this.setState((prevState) => ({
				parent,
				children,
				dataReady: true
			}));
			if (this.props.match.isExact) {
				this.props.history.push(this.props.match.url  +'/info');
			}
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<div className="text-center mb-4">
				<Button
					className="mr-2" color="primary"
					style={{ cursor: 'pointer' }}
					tag={ NavLink } activeClassName="active" to={ this.props.match.url + '/info' }
				>
					My info
				</Button>
				<ButtonGroup>
					{ this.state.children.map((child) => {
						// const active = this.state.selectedChildId === child.id;
						// const color = active ? 'success' : 'info';
						return (
							<Button
								key={ child.id } color="info"
								style={{ cursor: 'pointer' }}
								tag={ NavLink } activeClassName="active" to={ this.props.match.url + '/child/' + child.id }
							>
								{ child.firstname } { child.lastname }
							</Button>
						);
					}) }
				</ButtonGroup>
			</div>
			<Route path={ this.props.match.url + '/info' } render={ (props) => (
				<ParentInfo
					parent={ this.state.parent }
					updateParent={ this.updateParent }
				/>
			) } />
			<Route path={ this.props.match.url + '/child/:childId' } render={ (props) => (
				<ParentChildDetail
					{ ...props }
					child={ this.state.children.find((c) => c.id === parseInt(props.match.params.childId, 10)) }
					updateChild={ (changes) => this.updateChild(parseInt(props.match.params.childId, 10), changes) }
				/>
			) } />
			</React.Fragment>
		);
	}
};

Parent.propTypes = {
	user: PropTypes.object
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		user: auth.user
	})
)(Parent);

import React from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col, Button, Table
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import isBefore from 'date-fns/is_before';
import startOfWeek from 'date-fns/start_of_week';
import endOfWeek from 'date-fns/end_of_week';
import addWeeks from 'date-fns/add_weeks';
import subWeeks from 'date-fns/sub_weeks';
import getDay from 'date-fns/get_day';

import Week from 'components/utils/week';

import { dayNames } from 'services/calendar';

import { 
	fetchSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchDaysOfSchoolYearBetweenDates
} from 'actions/school_year';

const getSlotsByDay = (schoolTimeSlots, holidayTimeSlots, days, offers) => {
	return days.map((day) => {
		const weekday = (getDay(day.date) - 1 + 7) % 7;
		let slots = [];
		switch (day.type) {
			case 'school':
				slots = schoolTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: true,
					selected: offers.some((offer) => offer.SchoolTimeSlotId === slot.id && offer.DayId === day.id),
				}));
				break;
			case 'holiday':
				slots = holidayTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: false,
					selected: false
				}));
				break;
			case 'closed':
				slots = [{
					startTime: '08:00:00',
					endTime: '18:00:00',
					active: false,
					selected: false
				}];
				break;
			default: break;
		}
		return {
			day,
			date: day.date,
			name: dayNames[weekday],
			slots
		};
	});
};

class ParentChildDetailPunctualContract extends React.Component {
	state = {
		schoolYear: null,
		schoolTimeSlots: [],
		holidayTimeSlots: [],
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 },
		contractStartDate: '',
		currentWeekStartDate: '',
		currentWeekSlotsByDay: [],
		downloadingInvoice: false
	};

	updateWeekData = (startDate, endDate) => {
		fetchDaysOfSchoolYearBetweenDates(this.props.punctualContract.SchoolYearId, startDate, endDate)
		.then((weekDays) => {
			this.setState({
				currentWeekStartDate: startDate,
				currentWeekSlotsByDay: getSlotsByDay(this.state.schoolTimeSlots, this.state.holidayTimeSlots, weekDays, this.props.punctualContract.PunctualOffers)
			});
		});
	};

	handlePreviousWeek = () => {
		const weekStartDate = subWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleStartWeek = () => {
		const weekStartDate = startOfWeek(this.state.contractStartDate, { weekStartsOn: 1 });
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleNextWeek = () => {
		const weekStartDate = addWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	downloadInvoice = () => {
		this.setState({ downloadingInvoice: true });
		this.props.downloadPunctualContractInvoice(this.props.punctualContract.PunctualContractInvoice.id, this.props.punctualContract.PunctualContractInvoice.filename)
		.then(() => {
			this.setState({ downloadingInvoice: false });
		});
	};
	
	componentDidMount() {
		const contractStartDate = this.props.punctualContract.PunctualOffers.length > 0 ?
			this.props.punctualContract.PunctualOffers.reduce(
				(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
				this.props.punctualContract.PunctualOffers[0].Day.date
			) :
			new Date();
		const weekStartDate = startOfWeek(contractStartDate, { weekStartsOn: 1 });
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });

		if (!['sent', 'paid'].includes(this.props.punctualContract.PunctualContractInvoice.status)) {
			this.props.punctualContract.PunctualContractInvoice = undefined;
		}

		Promise.all([
			fetchSchoolYear(this.props.punctualContract.SchoolYearId),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.punctualContract.SchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.punctualContract.SchoolYearId),
			fetchDaysOfSchoolYearBetweenDates(this.props.punctualContract.SchoolYearId, weekStartDate, weekEndDate)
		])
		.then(([ schoolYear, schoolTimeSlots, holidayTimeSlots, weekDays ]) => {
			this.setState({
				schoolYear,
				schoolTimeSlots,
				holidayTimeSlots,
				contractStartDate,
				currentWeekStartDate: weekStartDate,
				currentWeekSlotsByDay: getSlotsByDay(schoolTimeSlots, holidayTimeSlots, weekDays, this.props.punctualContract.PunctualOffers)
			});
		});
	}
	
	render() {
		return (
			<React.Fragment>
			<Row className="mb-2">
				<Col>
					<h4 className="text-right ml-auto">
						{ this.props.punctualContract.status === 'valid' ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red' }} className="fa fa-ban"></i> }
						{' '}Punctual contract
						{' '}<i className="fa fa-map-marker"></i>
					</h4>
				</Col>
			</Row>
			<Table>
				<tbody>
				<tr>
					<th scope="row" style={{ width: '25%'}}>Parent income</th>
					<td>
						{ (this.props.punctualContract.parentIncome/100)+'€' }
					</td>
				</tr>
				<tr>
					<th scope="row" style={{ width: '25%'}}>Parent nb children</th>
					<td>
						{ this.props.punctualContract.parentNbChildren }
					</td>
				</tr>
				<tr>
					<th scope="row" style={{ width: '25%'}}>CAF rate</th>
					<td>
						{ this.props.punctualContract.CAFrate ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red'}} className="fa fa-ban"></i> }
					</td>
				</tr>
				<tr>
					<th scope="row" style={{ width: '25%'}}>Payment method</th>
					<td>
						{ this.props.punctualContract.paymentMethod }
					</td>
				</tr>
				</tbody>
			</Table>
			<hr/>
			<h4 className="text-right ml-auto">
				Time slots ({ this.props.punctualContract.PunctualOffers.length })
				{' '}<i className="fa fa-calendar-o"></i>
			</h4>
			<Row>
				<Col>
					<Week
						config={ this.state.weekConfig }
						slotsByDay={ this.state.currentWeekSlotsByDay }
						weekStartDate={ this.state.currentWeekStartDate }
						onPreviousWeek={ this.handlePreviousWeek }
						onNextWeek={ this.handleNextWeek }
						onStartWeek={ this.handleStartWeek }
					/>
				</Col>
			</Row>
			<hr />
			<h4 className="text-right ml-auto">
				Invoice{' '}<i className="fa fa-money"></i>
			</h4>
			<Table size="sm">
				<thead>
					<tr>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{ this.props.punctualContract.PunctualContractInvoice ? (this.props.punctualContract.PunctualContractInvoice.amount/100)+'€' : '' }</td>
						<td>{ this.props.punctualContract.PunctualContractInvoice ? (this.props.punctualContract.PunctualContractInvoice.paidAmount/100)+'€' : '' }</td>
						<td>{ this.props.punctualContract.PunctualContractInvoice ?
							((this.props.punctualContract.PunctualContractInvoice.amount-this.props.punctualContract.PunctualContractInvoice.paidAmount)/100)+'€' : '' }
						</td>
						<td className="text-right">
							{ this.props.punctualContract.PunctualContractInvoice && this.props.punctualContract.PunctualContractInvoice.status === 'sent'
								&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-envelope"></i>{' '}Sent</Button>
							}
							{ this.props.punctualContract.PunctualContractInvoice && this.props.punctualContract.PunctualContractInvoice.status === 'paid'
								&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-check"></i>{' '}Paid</Button>
							}
							{ this.props.punctualContract.PunctualContractInvoice
								&& <Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '8rem' }}
									onClick={ this.downloadInvoice }
								>
									{ !this.state.downloadingInvoice && <React.Fragment>
										<i className="fa fa-download"></i>{' '}Download
									</React.Fragment> }
									<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingInvoice} />
								</Button>
							}
						</td>
					</tr>
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

ParentChildDetailPunctualContract.propTypes = {
	punctualContract: PropTypes.object.isRequired,
	downloadPunctualContractInvoice: PropTypes.func.isRequired
};

export default ParentChildDetailPunctualContract;

import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink
} from 'reactstrap';

import AccountantInvoices from 'components/accountant/invoices';
import AccountantChildren from 'components/accountant/children';
import AccountantParents from 'components/accountant/parents';

class Accountant extends React.PureComponent {
	state = {
		contractMenuOpen: false
	};

	render() {
		return (
			<Row>
				<Col xs="2">
					<Nav xs="2" vertical>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/invoices' }>Invoices</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/children' }>Children</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/parents' }>Parents</NavLink>
						</NavItem>
					</Nav>
				</Col>
				<Col xs="10">
					<Route path={ this.props.match.url + '/invoices' } component={ AccountantInvoices } />
					<Route path={ this.props.match.url + '/children' } component={ AccountantChildren } />
					<Route path={ this.props.match.url + '/parents' } component={ AccountantParents } />
				</Col>
			</Row>
		);
	}
};

export default Accountant;

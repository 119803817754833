import React from 'react';
import { subscribe } from 'react-contextual';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { AuthContext } from 'contexts/auth';

const SigninNav = (props) => {
	if (props.user) {
		return (
			<Nav className="ml-auto" navbar>
				<NavItem style={{ padding: '0.5rem 1rem', color: 'white' }}>
					<i className="fa fa-user-circle"></i>
					{' '}
					{ props.user.username }
				</NavItem>
				<NavItem>
					<NavLink tag={ RRNavLink } to="/user/profile">Profile</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} onClick={ props.signout }>Signout</NavLink>
				</NavItem>
			</Nav>
		);
	} else {
		return (
			<Nav className="ml-auto" navbar>
				<NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active" to="/user/signin">Signin</NavLink>
				</NavItem>
			</Nav>
		);
	}
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		user: auth.user,
		signout: auth.signout
	})
)(SigninNav);

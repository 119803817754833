import React from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col, Button, Table
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import isBefore from 'date-fns/is_before';
import startOfWeek from 'date-fns/start_of_week';
import endOfWeek from 'date-fns/end_of_week';
import addWeeks from 'date-fns/add_weeks';
import subWeeks from 'date-fns/sub_weeks';
import getDay from 'date-fns/get_day';

import Week from 'components/utils/week';

import { dayNames } from 'services/calendar';
import { saveAs } from 'file-saver';

import {
	fetchSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchDaysOfSchoolYearBetweenDates
} from 'actions/school_year';
import { downloadHolidayContract } from 'actions/holiday_contract';

const getSlotsByDay = (schoolTimeSlots, holidayTimeSlots, days, offers) => {
	return days.map((day) => {
		const weekday = (getDay(day.date) - 1 + 7) % 7;
		let slots = [];
		switch (day.type) {
			case 'school':
				slots = schoolTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: false,
					selected: false,
				}));
				break;
			case 'holiday':
				slots = holidayTimeSlots.filter((slot) => slot.weekday === weekday).map((slot) => ({
					...slot,
					active: true,
					selected: offers.some((offer) => offer.HolidayTimeSlotId === slot.id && offer.DayId === day.id)
				}));
				break;
			case 'closed':
				slots = [{
					startTime: '08:00:00',
					endTime: '18:00:00',
					active: false,
					selected: false
				}];
				break;
			default: break;
		}
		return {
			day,
			date: day.date,
			name: dayNames[weekday],
			slots
		};
	});
};

class ParentChildDetailHolidayContract extends React.Component {
	state = {
		schoolYear: null,
		schoolTimeSlots: [],
		holidayTimeSlots: [],
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 },
		contractStartDate: '',
		currentWeekStartDate: '',
		currentWeekSlotsByDay: [],
		downloadingContract: false,
		downloadingInvoice: false
	};

	updateWeekData = (startDate, endDate) => {
		fetchDaysOfSchoolYearBetweenDates(this.props.holidayContract.SchoolYearId, startDate, endDate)
		.then((weekDays) => {
			this.setState({
				currentWeekStartDate: startDate,
				currentWeekSlotsByDay: getSlotsByDay(this.state.schoolTimeSlots, this.state.holidayTimeSlots, weekDays, this.props.holidayContract.HolidayOffers)
			});
		});
	};

	handlePreviousWeek = () => {
		const weekStartDate = subWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleStartWeek = () => {
		const weekStartDate = startOfWeek(this.state.contractStartDate, { weekStartsOn: 1 });
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	handleNextWeek = () => {
		const weekStartDate = addWeeks(this.state.currentWeekStartDate, 1);
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });
		this.updateWeekData(weekStartDate, weekEndDate);
	};

	downloadContract = () => {
		this.setState({ downloadingContract: true });
		downloadHolidayContract(this.props.holidayContract.id)
		.then((blob) => {
			saveAs(blob, 'holiday_contract.pdf');
			this.setState({ downloadingContract: false });
		});
	};

	downloadInvoice = () => {
		this.setState({ downloadingInvoice: true });
		this.props.downloadHolidayContractInvoice(this.props.holidayContract.HolidayContractInvoice.id, this.props.holidayContract.HolidayContractInvoice.filename)
		.then(() => {
			this.setState({ downloadingInvoice: false });
		});
	};

	componentDidMount() {
		const contractStartDate = this.props.holidayContract.HolidayOffers.length > 0 ?
			this.props.holidayContract.HolidayOffers.reduce(
				(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
				this.props.holidayContract.HolidayOffers[0].Day.date
			) :
			new Date();
		const weekStartDate = startOfWeek(contractStartDate, { weekStartsOn: 1 });
		const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 });

		if (!['sent', 'paid'].includes(this.props.holidayContract.HolidayContractInvoice.status)) {
			this.props.holidayContract.HolidayContractInvoice = undefined;
		}
		
		Promise.all([
			fetchSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.holidayContract.SchoolYearId),
			fetchDaysOfSchoolYearBetweenDates(this.props.holidayContract.SchoolYearId, weekStartDate, weekEndDate)
		])
		.then(([ schoolYear, schoolTimeSlots, holidayTimeSlots, weekDays ]) => {
			this.setState({
				schoolYear,
				schoolTimeSlots,
				holidayTimeSlots,
				contractStartDate,
				currentWeekStartDate: weekStartDate,
				currentWeekSlotsByDay: getSlotsByDay(schoolTimeSlots, holidayTimeSlots, weekDays, this.props.holidayContract.HolidayOffers)
			});
		});
	}
	
	render() {
		return (
			<React.Fragment>
			<Row className="mb-2">
				<Col>
					<h4 className="text-right ml-auto">
						{ this.props.holidayContract.status === 'valid' ?
							<i style={{ color: 'green'}} className="fa fa-check"></i> :
							<i style={{ color: 'red' }} className="fa fa-ban"></i>
						}
						{' '}Holiday contract
						{' '}<i className="fa fa-suitcase"></i>
						<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '13rem', height: '2rem' }} onClick={ this.downloadContract }>
							{ !this.state.downloadingContract && <React.Fragment>
								<i className="fa fa-download"></i>{' '}Download contract PDF
							</React.Fragment> }
							<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingContract} />
						</Button>
					</h4>
				</Col>
			</Row>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent income</th>
						<td>
							{ (this.props.holidayContract.parentIncome/100)+'€' }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent nb children</th>
						<td>
							{ this.props.holidayContract.parentNbChildren }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>CAF rate</th>
						<td>
							{ this.props.holidayContract.CAFrate ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red'}} className="fa fa-ban"></i> }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Payment method</th>
						<td>
							{ this.props.holidayContract.paymentMethod }
						</td>
					</tr>
				</tbody>
			</Table>
			<hr />
			<h4 className="text-right ml-auto">
				Time slots ({ this.props.holidayContract.HolidayOffers.length })
				{' '}<i className="fa fa-calendar-o"></i>
			</h4>
			<Row>
				<Col>
					<Week
						config={ this.state.weekConfig }
						slotsByDay={ this.state.currentWeekSlotsByDay }
						weekStartDate={ this.state.currentWeekStartDate }
						onPreviousWeek={ this.handlePreviousWeek }
						onNextWeek={ this.handleNextWeek }
						onStartWeek={ this.handleStartWeek }
					/>
				</Col>
			</Row>
			<hr />
			<h4 className="text-right ml-auto">
				Invoice{' '}<i className="fa fa-money"></i>
			</h4>
			<Table size="sm">
				<thead>
					<tr>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{ this.props.holidayContract.HolidayContractInvoice ? (this.props.holidayContract.HolidayContractInvoice.amount/100)+'€' : '' }</td>
						<td>{ this.props.holidayContract.HolidayContractInvoice ? (this.props.holidayContract.HolidayContractInvoice.paidAmount/100)+'€' : '' }</td>
						<td>{ this.props.holidayContract.HolidayContractInvoice ?
							((this.props.holidayContract.HolidayContractInvoice.amount-this.props.holidayContract.HolidayContractInvoice.paidAmount)/100)+'€' : '' }
						</td>
						<td className="text-right">
							{ this.props.holidayContract.HolidayContractInvoice && this.props.holidayContract.HolidayContractInvoice.status === 'sent'
								&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-envelope"></i>{' '}Sent</Button>
							}
							{ this.props.holidayContract.HolidayContractInvoice && this.props.holidayContract.HolidayContractInvoice.status === 'paid'
								&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-check"></i>{' '}Paid</Button>
							}
							{ this.props.holidayContract.HolidayContractInvoice
								&& <Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '8rem' }}
									onClick={ this.downloadInvoice }
								>
									{ !this.state.downloadingInvoice && <React.Fragment>
										<i className="fa fa-download"></i>{' '}Download
									</React.Fragment> }
									<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingInvoice} />
								</Button>
							}
						</td>
					</tr>
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

ParentChildDetailHolidayContract.propTypes = {
	holidayContract: PropTypes.object.isRequired,
	downloadHolidayContractInvoice: PropTypes.func.isRequired
};

export default ParentChildDetailHolidayContract;

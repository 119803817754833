import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Form, FormGroup, Label, Input, Button
} from 'reactstrap';

import { fetchDocumentCategories, addDocumentCategory, updateDocumentCategory, removeDocumentCategory } from 'actions/document_category';

import AdminDocumentCategoryDetail from 'components/admin/documents/detail';

class AdminDocuments extends React.Component {
	state = {
		dataReady: false,
		documentCategories: [],
		newCategoryForm: {
			title: ''
		}
	};

	handleNewCategoryFormUpdate = (value, prop) => {
		this.setState((prevState) => ({
			newCategoryForm: { ...prevState.newCategoryForm, [prop]: value }
		}));
	};

	addDocumentCategory = (e) => {
		e.preventDefault();
		return addDocumentCategory(this.state.newCategoryForm)
		.then((documentCategory) => {
			this.setState((prevState) => ({
				documentCategories: [ ...prevState.documentCategories, documentCategory ]
			}));
		});
	};

	updateDocumentCategory = (documentCategoryId, changes) => {
		return updateDocumentCategory(documentCategoryId, changes)
		.then((documentCategory) => {
			this.setState((prevState) => ({
				documentCategories: prevState.documentCategories.map((c) => c.id === documentCategoryId ? { ...c, ...documentCategory } : c)
			}));
		});
	};

	removeDocumentCategory = (documentCategoryId) => {
		return removeDocumentCategory(documentCategoryId)
		.then(() => {
			this.props.history.push('/admin/documents');
			this.setState((prevState) => ({
				documentCategories: prevState.documentCategories.filter((c) => c.id !== documentCategoryId)
			}));
		});
	}

	componentDidMount() {
		fetchDocumentCategories()
		.then((documentCategories) => {
			this.setState({
				documentCategories,
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Row>
				<Col xs="3">
					<Nav vertical>
						{ this.state.documentCategories.map((documentCategory) => {
							return (
								<NavItem key={ documentCategory.id }>
									<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/' + documentCategory.id }>
										{ documentCategory.title }
									</NavLink>
								</NavItem>
							);
						}) }
					</Nav>
					<hr />
					<h4>Add category</h4>
					<Form onSubmit={ this.addDocumentCategory }>
						<FormGroup>
							<Label for="categoryTitle">Title</Label>
							<Input type="text" value={ this.state.newCategoryForm.title } onChange={ (e) => this.handleNewCategoryFormUpdate(e.target.value, 'title') } name="categoryTitle" id="categoryTitle" />
						</FormGroup>
						<FormGroup>
							<Button color="primary" style={{ cursor: 'pointer' }}>
								<i className="fa fa-book"></i> Add
							</Button>
						</FormGroup>
					</Form>
				</Col>
				<Col xs="9">
					<Route path={ this.props.match.url + '/:documentCategoryId' } render={ (props) => (
						<AdminDocumentCategoryDetail
							documentCategory={ this.state.documentCategories.find((c) => c.id === parseInt(props.match.params.documentCategoryId, 10)) }
							updateDocumentCategory={ (changes) => this.updateDocumentCategory(parseInt(props.match.params.documentCategoryId, 10), changes) }
							removeDocumentCategory={ () => this.removeDocumentCategory(parseInt(props.match.params.documentCategoryId, 10)) }
						/>
					) } />
				</Col>
			</Row>
		);
	}
};

export default AdminDocuments;

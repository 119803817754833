import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import {
	Col, Row,
	Form, FormGroup, Input, Label,
	ButtonGroup, Button
} from 'reactstrap';

import Week from 'components/utils/week';

import { ConfigContext } from 'contexts/config';
import {
	fetchSchoolYear,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchRegularOffersOfSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear
} from 'actions/school_year';

import { dayNames } from 'services/calendar';
import { timeStringToFloat } from 'services/time';

// const effortTable = [ 0.0006, 0.0005, 0.0004, 0.0003, 0.0003, 0.0003, 0.0003, 0.0002, 0.0002, 0.0002 ];

class Rates extends React.PureComponent {
	state = {
		dataReady: false,
		income: 1,
		children: 1,
		caf: true,
		employerGrant: false,
		schoolYear: null,
		schoolTimeSlots: [],
		schoolTimeSlotsByDay: [],
		regularOffers: [],
		regularOffersById: {},
		selectedRegularOffersId: [],
		holidayTimeSlots: [],
		selectedHolidayTimeSlotsId: [],
		weekConfig: { startTime: 8, endTime: 18, hourHeight: 30 }
	};

	computeRegularRate = () => {
		let income = this.state.income * 100;
		let CAFclampedIncome = income > this.props.CAFplafond ? this.props.CAFplafond :
			income < this.props.CAFplancher ? this.props.CAFplancher :
			income;

		const effort = this.props.effortTable[this.state.children - 1];
		
		let amountSchool = 0;
		let amountExtra = 0;

		this.state.selectedRegularOffersId.forEach((roid) => {
			const o = this.state.regularOffersById[roid];
			if (o.rate) {
				if (this.state.employerGrant && this.props.employerGrantRate) {
					amountSchool = this.props.employerGrantRate;
				} else {
					// progressive mode computation
					amountSchool = Math.round(1/10 * (income * 0.02 + o.rate * 10));
					amountSchool = amountSchool > this.props.progressiveSchoolRateMax ? this.props.progressiveSchoolRateMax : amountSchool < this.props.progressiveSchoolRateMin ? this.props.progressiveSchoolRateMin : amountSchool;
				}
			} else {
				const offerNonCAFIncrease = this.state.caf ? 0 : o.nonCAFIncrease;
				let offerExtraHours = 0;
				o.SchoolTimeSlots.forEach((offerSchoolTimeSlot) => {
					const schoolTimeSlot = this.state.schoolTimeSlots.find((sts) => sts.id === offerSchoolTimeSlot.id);
					offerExtraHours += timeStringToFloat(schoolTimeSlot.endTime) - timeStringToFloat(schoolTimeSlot.startTime);
				});
				const offerAmount = Math.round(1/10 * (10 * offerNonCAFIncrease + effort * CAFclampedIncome/12 * offerExtraHours * this.state.schoolYear.nbOpenWeeks));
				amountExtra += offerAmount;
			}
		});

		return amountSchool + amountExtra;
	};

	computeHolidayRate = () => {
		let income = this.state.income * 100;
		let CAFclampedIncome = income > this.props.CAFplafond ? this.props.CAFplafond :
			income < this.props.CAFplancher ? this.props.CAFplancher :
			income;

		const effort = this.props.effortTable[this.state.children - 1];

		let amount = 0;

		this.state.selectedHolidayTimeSlotsId.forEach((htsid) => {
			const hts = this.state.holidayTimeSlotsById[htsid];
			const offerHours = timeStringToFloat(hts.endTime) - timeStringToFloat(hts.startTime);
			const offerNonCAFIncrease = this.state.caf ? 0 : 50 * offerHours; // 0.50€ / hour
			const offerAmount = Math.round((effort * CAFclampedIncome/12 * offerHours) + offerNonCAFIncrease);
			amount += offerAmount;
		});

		return amount;
	};

	handleValueChange = (value, prop) => {
		this.setState({ [prop]: value });
	};

	toggleRegularOffer = (regularOfferId) => {
		this.setState((prevState) => {
			const selectedRegularOffersId = prevState.selectedRegularOffersId.includes(regularOfferId) ?
				prevState.selectedRegularOffersId.filter((sroid) => sroid !== regularOfferId) :
				[ ...prevState.selectedRegularOffersId, regularOfferId ];
			
			const schoolTimeSelectedSlotsId = [].concat(...selectedRegularOffersId.map((sroid) =>
				prevState.regularOffers
				.find((regularOffer) => regularOffer.id === sroid)
				.SchoolTimeSlots
				.map((sts) => sts.id)
			));

			return {
				selectedRegularOffersId,
				schoolTimeSlotsByDay: prevState.schoolTimeSlotsByDay.map((stsOfDay) => ({
					...stsOfDay,
					slots: stsOfDay.slots.map((slot) => ({ ...slot, selected: schoolTimeSelectedSlotsId.includes(slot.id) }))
				}))
			};
		});
	};

	toggleHolidayTimeSlot = (holidayTimeSlotId) => {
		this.setState((prevState) => {
			const selectedHolidayTimeSlotsId = prevState.selectedHolidayTimeSlotsId.includes(holidayTimeSlotId) ?
				prevState.selectedHolidayTimeSlotsId.filter((shtsid) => shtsid !== holidayTimeSlotId) :
				[ ...prevState.selectedHolidayTimeSlotsId, holidayTimeSlotId ];

			return {
				selectedHolidayTimeSlotsId,
				holidayTimeSlotsByDay: prevState.holidayTimeSlotsByDay.map((htsOfDay) => ({
					...htsOfDay,
					slots: htsOfDay.slots.map((slot) => ({ ...slot, selected: selectedHolidayTimeSlotsId.includes(slot.id) }))
				}))
			};
		});
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYear(this.props.rateSchoolYearId),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.rateSchoolYearId),
			fetchRegularOffersOfSchoolYear(this.props.rateSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.rateSchoolYearId)
		])
		.then(([ schoolYear, schoolTimeSlots, regularOffers, holidayTimeSlots ]) => {
			const regularOffersById = {};
			regularOffers.forEach((ro) => regularOffersById[ro.id] = ro);
			const holidayTimeSlotsById = {};
			holidayTimeSlots.forEach((hts) => holidayTimeSlotsById[hts.id] = hts);
			this.setState({
				schoolYear,
				schoolTimeSlots,
				schoolTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
					name: dayNames[i],
					slots: schoolTimeSlots.filter((sts) => sts.weekday === i).map((slot) => ({ ...slot, active: true }))
				})),
				regularOffers,
				regularOffersById,
				holidayTimeSlots,
				holidayTimeSlotsById,
				holidayTimeSlotsByDay: [0,1,2,3,4].map((i) => ({
					name: dayNames[i],
					slots: holidayTimeSlots.filter((hts) => hts.weekday === i).map((slot) => ({ ...slot, active: true }))
				})),
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row>
				<Col xs="12" md={{ size: 7, offset: 1 }}>
					<Form onSubmit={ (e) => e.preventDefault() }>
						<FormGroup tag="fieldset">
							<legend>Your information</legend>
							<FormGroup row>
								<Label for="income" className="text-right" sm={5}>Annual gross income (year N-2)</Label>
								<Col sm={7}>
									<Input type="number" name="income" id="income" min="0" value={ this.state.income } onChange={ (e) => this.handleValueChange(parseInt(e.target.value, 10), 'income') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="children" className="text-right" sm={5}>Number of dependant children</Label>
								<Col sm={7}>
									<Input type="number" name="children" id="children" min="1" value={ this.state.children } onChange={ (e) => this.handleValueChange(parseInt(e.target.value, 10), 'children') } />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="caf" className="text-right" sm={5}>CAF rate (régime sécu général ou local)</Label>
								<Col sm={7}>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" id="caf" checked={ this.state.caf } onChange={ (e) => this.handleValueChange(e.target.checked, 'caf') } />
										</Label>
									</FormGroup>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="caf" className="text-right" sm={5}>Your employer gives you a grant for child care</Label>
								<Col sm={7}>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" id="caf" checked={ this.state.employerGrant } onChange={ (e) => this.handleValueChange(e.target.checked, 'employerGrant') } />
										</Label>
									</FormGroup>
								</Col>
							</FormGroup>
						</FormGroup>
					</Form>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col xs="9" md={{ size: 7, offset: 1 }} className="text-center">
					<ButtonGroup className="mb-2">
						{ this.state.regularOffers.map((regularOffer) => {
							const active = this.state.selectedRegularOffersId.includes(regularOffer.id);
							const color = active ? 'success' : 'info';
							return (
								<Button key={ regularOffer.id } color={ color } active={ active } style={{ cursor: 'pointer' }} onClick={ () => this.toggleRegularOffer(regularOffer.id) }>
									{ regularOffer.title }
								</Button>
							);
						}) }
					</ButtonGroup>
					<Week
						config={ this.state.weekConfig }
						slotsByDay={ this.state.schoolTimeSlotsByDay }
					/>
				</Col>
				<Col xs="3" md={{ size: 3 }}>
					<p className="text-center display-4"> Your monthly rate: </p>
					<h3 className="text-center"> { this.computeRegularRate() / 100 } € / month </h3>
					<p className="text-center"> (10 months per year) </p>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col xs="9" md={{ size: 7, offset: 1 }} className="text-center">
					<Week
						config={ this.state.weekConfig }
						slotsByDay={ this.state.holidayTimeSlotsByDay }
						onToggleSlot={ this.toggleHolidayTimeSlot }
					/>
				</Col>
				<Col xs="3" md={{ size: 3 }}>
					<p className="text-center display-4"> Your holiday rate: </p>
					<h3 className="text-center"> { this.computeHolidayRate() / 100 } € </h3>
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

Rates.propTypes = {
	rateSchoolYearId: PropTypes.number.isRequired,
	effortTable: PropTypes.array.isRequired,
	CAFplancher: PropTypes.number.isRequired,
	CAFplafond: PropTypes.number.isRequired,
	progressiveSchoolRateMin: PropTypes.number.isRequired,
	progressiveSchoolRateMax: PropTypes.number.isRequired,
	employerGrantRate: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		rateSchoolYearId: config.getConfigValue('rateSchoolYearId'),
		effortTable: config.getConfigRevisableValue('effortTable', new Date()),
		CAFplancher: config.getConfigRevisableValue('CAFplancher', new Date()),
		CAFplafond: config.getConfigRevisableValue('CAFplafond', new Date()),
		progressiveSchoolRateMin: config.getConfigRevisableValue('progressiveSchoolRateMin', new Date()),
		progressiveSchoolRateMax: config.getConfigRevisableValue('progressiveSchoolRateMax', new Date()),
		employerGrantRate: config.getConfigRevisableValue('employerGrantRate', new Date())
	})
)(Rates);

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const fetchParents = () => {
	return fetch(apiBasename + '/parent', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const updateParent = (id, changes) => {
	return fetch(apiBasename + '/parent/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeParent = (id) => {
	return fetch(apiBasename + '/parent/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

export const createParent = (parent) => {
	return fetch(apiBasename + '/parent', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(parent)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchChildrenOfParent = (id) => {
	return fetch(apiBasename + '/parent/' + id + '/children', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addChildToParent = (id, childId) => {
	return fetch(apiBasename + '/parent/' + id + '/children', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id: childId })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeChildFromParent = (id, childId) => {
	return fetch(apiBasename + '/parent/' + id + '/children', authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ id: childId })
	}))
	.then(checkStatus);
};

export const fetchUserOfParent = (id) => {
	return fetch(apiBasename + '/parent/' + id + '/user', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const createUserOfParent = (id) => {
	return fetch(apiBasename + '/parent/' + id + '/user', authorization({
		method: 'POST'
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

import getMonth from 'date-fns/get_month';
import addMonths from 'date-fns/add_months';
import isBefore from 'date-fns/is_before';
import startOfMonth from 'date-fns/start_of_month';
import lastDayOfMonth from 'date-fns/last_day_of_month';

export const dayNames = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday'
];

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export const isMonthWithinRange = (month, startDate, endDate) => {
	const end = lastDayOfMonth(endDate);
	let d = startOfMonth(startDate);
	do {
		if (getMonth(d) === month) {
			return true;
		}
		d = addMonths(d, 1);
	}
	while (isBefore(d, end));
	return false;
};

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const fetchDocumentCategories = () => {
	return fetch(apiBasename + '/documentCategory', authorization())
	.then(checkStatus)
	.then((res) => res.json());
}

export const addDocumentCategory = (documentCategory) => {
	return fetch(apiBasename + '/documentCategory', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(documentCategory)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const updateDocumentCategory = (id, changes) => {
	return fetch(apiBasename + '/documentCategory/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchDocumentsOfDocumentCategory = (id) => {
	return fetch(apiBasename + '/documentCategory/' + id + '/documents', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addDocumentToCategory = (id, doc) => {
	const formData = new FormData();
	formData.append('doc', doc.file);
	return fetch(apiBasename + '/document', authorization({
		method: 'POST',
		body: formData
	}))
	.then(() => fetch(apiBasename + '/documentCategory/' + id + '/documents', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			title: doc.title,
			filename: doc.file.name
		})
	})))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeDocumentCategory = (id) => {
	return fetch(apiBasename + '/documentCategory/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Collapse, Badge,
	Form, FormGroup, Label, Input
} from 'reactstrap';

import { fetchUsers, updateUser, removeUser } from 'actions/user';

import { debounce } from 'services/debounce';
import Paginate from 'components/utils/paginate';

import AdminUserDetail from 'components/admin/users/detail';

class AdminUsers extends React.Component {
	state = {
		dataReady: false,
		users: [],
		showFilters: false,
		filters: {
			username: ''
		},
		filteredUsers: [],
		paginationStartIndex: 0,
		paginationEndIndex: 0
	};

	toggleFilters = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
	};

	handleFilterChange = (value, prop) => {
		this.setState(
			(prevState) => ({ filters: { ...prevState.filters, [prop]: value } }),
			this.debouncedUpdateFilteredUsers
		);
	};

	filteredUsers = (users) => {
		return users.filter((user) => {
			return user.username.toLowerCase().includes(this.state.filters.username.toLowerCase());
		});
	};
	updateFilteredUsers = () => {
		this.setState({
			filteredUsers: this.filteredUsers(this.state.users)
		});
	};
	debouncedUpdateFilteredUsers = debounce(this.updateFilteredUsers, 500, false);

	pageChanged = (paginationStartIndex, paginationEndIndex) => {
		this.setState({ paginationStartIndex, paginationEndIndex });
	};

	updateUser = (userId, changes) => {
		return updateUser(userId, changes)
		.then((user) => {
			this.setState((prevState) => {
				let users = prevState.users.map((p) => p.id === userId ? { ...p, ...user } : p);
				return {
					users,
					filteredUsers: this.filteredUsers(users)
				};
			});
		});
	};

	removeUser = (userId) => {
		return removeUser(userId)
		.then(() => {
			this.props.history.push(this.props.match.url);
			this.setState((prevState) => ({
				users: prevState.users.filter((u) => u.id !== userId),
				filteredUsers: prevState.filteredUsers.filter((u) => u.id !== userId)
			}));
		});
	};

	componentDidMount() {
		fetchUsers()
		.then((users) => {
			this.setState({
				users,
				dataReady: true,
				filteredUsers: this.filteredUsers(users)
			});
		});
	}

	render() {
		const filterParagraphStyle = {
			color: 'white',
			backgroundColor: 'rgba(52, 58, 64, 0.60)',
			cursor: 'pointer'
		};
		return (
			<React.Fragment>
			<Row>
				<Col>
					<p style={ filterParagraphStyle } className="p-2 d-flex align-items-center" onClick={ this.toggleFilters }>
						Filters
						<Badge className="ml-3" color="info">{ this.state.filteredUsers.length }</Badge>
						{ this.state.showFilters ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
					</p>
					<Collapse isOpen={ this.state.showFilters }>
						<Form onSubmit={ (e) => e.preventDefault() }>
							<FormGroup row>
								<Label for="username" sm={2}>Username</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.username } onChange={ (e) => this.handleFilterChange(e.target.value, 'username') } name="username" id="username" placeholder="Username" />
								</Col>
							</FormGroup>
						</Form>
						<hr />
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginate nbItems={ this.state.filteredUsers.length } onPageChange={ this.pageChanged } initialPage={ 1 } />
				</Col>
			</Row>
			<Row>
				<Col xs="3">
					<Nav vertical>
						{ this.state.filteredUsers.slice(this.state.paginationStartIndex, this.state.paginationEndIndex + 1).map((user) => (
							<NavItem key={ user.id }>
								<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/' + user.id }>
									{ user.username }
								</NavLink>
							</NavItem>
						)) }
					</Nav>
				</Col>
				<Col xs="9">
					{ this.state.dataReady &&
					<Route path={ this.props.match.url + '/:userId' } render={ (props) => (
						<AdminUserDetail
							user={ this.state.users.find((u) => u.id === parseInt(props.match.params.userId, 10)) }
							updateUser={ (changes) => this.updateUser(parseInt(props.match.params.userId, 10), changes) }
							removeUser={ () => this.removeUser(parseInt(props.match.params.userId, 10)) }
						/>
					) } /> }
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

export default AdminUsers;

import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

import SigninNav from 'components/nav/signin';
import ConditionalNavItem from 'components/nav/conditional_navitem';

import { AuthContext } from 'contexts/auth';

import './navstyle.css';

class NavBar extends React.PureComponent {
	state = {
		navbarOpen: false
	};
	
	toggleNavbar = () => {
		this.setState((prevState) => ({
			navbarOpen: !prevState.navbarOpen
		}));
	};

	render() {
		const roleSpecificNavlinkStyle = {
			backgroundColor: 'rgb(180,50,50)',
			borderRadius: '5px',
			marginLeft: '5px'
		};
		return (
			<Navbar dark color="dark" fixed="top" expand="md">
				<div className="container-fluid">
					<NavbarBrand tag={ Link } to="/">Playgroup</NavbarBrand>
					<NavbarToggler onClick={ this.toggleNavbar } />
					<Collapse isOpen={ this.state.navbarOpen } navbar>
						<Nav navbar>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/rates">Rates</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/documents">Documents</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/enrolment">Enrolment</NavLink>
							</NavItem>
							<ConditionalNavItem condition={() => this.props.user}>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/calendar">Calendar</NavLink>
							</ConditionalNavItem>
							<ConditionalNavItem condition={() => this.props.user && this.props.user.hasRole('admin')}>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/admin" style={ roleSpecificNavlinkStyle }>Admin</NavLink>
							</ConditionalNavItem>
							<ConditionalNavItem condition={() => this.props.user && this.props.user.hasRole('parent')}>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/parent" style={ roleSpecificNavlinkStyle }>Parent</NavLink>
							</ConditionalNavItem>
							<ConditionalNavItem condition={() => this.props.user && this.props.user.hasRole('prof')}>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/prof" style={ roleSpecificNavlinkStyle }>Prof</NavLink>
							</ConditionalNavItem>
							<ConditionalNavItem condition={() => this.props.user && this.props.user.hasRole('accountant')}>
								<NavLink tag={ RRNavLink } activeClassName="active" to="/accountant" style={ roleSpecificNavlinkStyle }>Accountant</NavLink>
							</ConditionalNavItem>
						</Nav>
						<SigninNav />
					</Collapse>
				</div>
			</Navbar>
		);
	}
}

NavBar.propTypes = {
	user: PropTypes.object.isRequired
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		user: auth.user
	})
)(NavBar);

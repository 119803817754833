import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink,
	Collapse, Badge,
	Form, FormGroup, Label, Input
} from 'reactstrap';

import { fetchParents, updateParent } from 'actions/parent';

import { debounce } from 'services/debounce';
import Paginate from 'components/utils/paginate';

import AccountantParentDetail from 'components/accountant/parents/detail';

class AccountantParents extends React.Component {
	state = {
		dataReady: false,
		parents: [],
		showFilters: false,
		filters: {
			firstname: '',
			lastname: ''
		},
		filteredParents: [],
		paginationStartIndex: 0,
		paginationEndIndex: 0
	};

	toggleFilters = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
	};

	handleFilterChange = (value, prop) => {
		this.setState(
			(prevState) => ({ filters: { ...prevState.filters, [prop]: value } }),
			this.debouncedUpdateFilteredParents
		);
	};

	filteredParents = (parents) => {
		return parents.filter((parent) => {
			return parent.firstname.toLowerCase().includes(this.state.filters.firstname.toLowerCase()) &&
				parent.lastname.toLowerCase().includes(this.state.filters.lastname.toLowerCase());
		});
	};
	updateFilteredParents = () => {
		this.setState({
			filteredParents: this.filteredParents(this.state.parents)
		});
	};
	debouncedUpdateFilteredParents = debounce(this.updateFilteredParents, 500, false);

	pageChanged = (paginationStartIndex, paginationEndIndex) => {
		this.setState({ paginationStartIndex, paginationEndIndex });
	};

	updateParent = (parentId, changes) => {
		return updateParent(parentId, changes)
		.then((parent) => {
			this.setState((prevState) => {
				const parents = prevState.parents.map((p) => p.id === parentId ? { ...p, ...parent } : p);
				return {
					parents,
					filteredParents: this.filteredParents(parents)
				};
			});
		});
	};

	componentDidMount() {
		fetchParents()
		.then((parents) => {
			this.setState({
				parents,
				dataReady: true,
				filteredParents: this.filteredParents(parents)
			});
		});
	}

	render() {
		const filterParagraphStyle = {
			color: 'white',
			backgroundColor: 'rgba(52, 58, 64, 0.60)',
			cursor: 'pointer'
		};
		return (
			<React.Fragment>
			<Row>
				<Col>
					<p style={ filterParagraphStyle } className="p-2 d-flex align-items-center" onClick={ this.toggleFilters }>
						Filters
						<Badge className="ml-3" color="info">{ this.state.filteredParents.length }</Badge>
						{ this.state.showFilters ? <i className="fa fa-minus-square-o ml-auto"></i> : <i className="fa fa-plus-square-o ml-auto"></i> }
					</p>
					<Collapse isOpen={ this.state.showFilters }>
						<Form onSubmit={ (e) => e.preventDefault() }>
							<FormGroup row>
								<Label for="firstname" sm={2}>Firstname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.firstname } onChange={ (e) => this.handleFilterChange(e.target.value, 'firstname') } name="firstname" id="firstname" placeholder="Firstname" />
								</Col>
								<Label for="lastname" sm={2}>Lastname</Label>
								<Col sm={4}>
									<Input type="text" value={ this.state.filters.lastname } onChange={ (e) => this.handleFilterChange(e.target.value, 'lastname') } name="lastname" id="lastname" placeholder="Lastname" />
								</Col>
							</FormGroup>
						</Form>
						<hr />
					</Collapse>
				</Col>
			</Row>
			<Row>
				<Col>
					<Paginate nbItems={ this.state.filteredParents.length } onPageChange={ this.pageChanged } initialPage={ 1 } />
				</Col>
			</Row>
			<Row>
				<Col xs="3">
					<Nav vertical>
						{ this.state.filteredParents.slice(this.state.paginationStartIndex, this.state.paginationEndIndex + 1).map((parent) => (
							<NavItem key={ parent.id }>
								<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/' + parent.id }>
									{ parent.firstname } { parent.lastname }
								</NavLink>
							</NavItem>
						)) }
					</Nav>
				</Col>
				<Col xs="9">
					{ this.state.dataReady &&
					<Route path={ this.props.match.url + '/:parentId' } render={ (props) => (
						<AccountantParentDetail
							parent={ this.state.parents.find((p) => p.id === parseInt(props.match.params.parentId, 10)) }
							updateParent={ (changes) => this.updateParent(parseInt(props.match.params.parentId, 10), changes) }
						/>
					) } /> }
				</Col>
			</Row>
			</React.Fragment>
		);
	}
};

export default AccountantParents;

import React from 'react';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Col, Row,
	Nav, NavItem, NavLink
} from 'reactstrap';

import ProfAbsences from 'components/prof/absences';
import ProfPresences from 'components/prof/presences';

class Prof extends React.PureComponent {

	render() {
		return (
			<Row>
				<Col xs="2">
					<Nav xs="2" vertical>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/absences' }>Absences</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/presences' }>Presences</NavLink>
						</NavItem>
					</Nav>
				</Col>
				<Col xs="10">
					<Route path={ this.props.match.url + '/absences' } component={ ProfAbsences } />
					<Route path={ this.props.match.url + '/presences' } component={ ProfPresences } />
				</Col>
			</Row>
		);
	}
};

export default Prof;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Table, Button
} from 'reactstrap';

import { fetchParentsOfChild } from 'actions/child';

class AccountantChildDetailInfo extends React.Component {
	state = {
		dataReady: false,
		parents: []
	};

	componentDidMount() {
		fetchParentsOfChild(this.props.child.id)
		.then((parents) => {
			this.setState({
				parents,
				dataReady: true
			});
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.child.id !== prevProps.child.id) {
			this.setState({ dataReady: false }, () => {
				fetchParentsOfChild(this.props.child.id)
				.then((parents) => {
					this.setState({
						parents,
						dataReady: true
					});
				});
			});
		}
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Firstname</th>
						<td><span>{ this.props.child.firstname }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Lastname</th>
						<td><span>{ this.props.child.lastname }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Birthdate</th>
						<td><span>{ this.props.child.birthdate }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Previous childcare</th>
						<td><span>{ this.props.child.previousChildcare }</span></td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Home language</th>
						<td><span>{ this.props.child.homeLanguage }</span></td>
					</tr>
					<tr>
						<th scope="row">Parents</th>
						<td>
							{ this.state.parents.map((parent) => (
								<Button key={ parent.id }
									tag={ Link } to={ '/accountant/parents/' + parent.id }
									color="primary" size="sm" className="mr-2"
									style={{ cursor: 'pointer' }}>
									{ parent.firstname } { parent.lastname }
								</Button>
							)) }
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
};

AccountantChildDetailInfo.propTypes = {
	child: PropTypes.object.isRequired
};

export default AccountantChildDetailInfo;

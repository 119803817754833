import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const updateDocument = (id, changes) => {
	return fetch(apiBasename + '/document/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeDocument = (id) => {
	return fetch(apiBasename + '/document/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

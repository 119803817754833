import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const updatePunctualContract = (id, changes) => {
	return fetch(apiBasename + '/punctualContract/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const addPunctualOfferToPunctualContract = (id, punctualOffer) => {
	return fetch(apiBasename + '/punctualContract/' + id + '/punctualOffers', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(punctualOffer)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removePunctualOfferFromPunctualContract = (id, punctualOfferId) => {
	return fetch(apiBasename + '/punctualContract/' + id + '/punctualOffers', authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ punctualOfferId })
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removePunctualContract = (id, sendMail) => {
	return fetch(apiBasename + '/punctualContract/' + id, authorization({
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ sendMail })
	}))
	.then(checkStatus);
};

export const generatePunctualContractInvoice = (id) => {
	return fetch(apiBasename + '/punctualContract/' + id + '/invoice', authorization({
		method: 'POST'
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const submitPunctualContractEnrolmentFormAuth = (formData) => {
	return fetch(apiBasename + '/punctualContract/demandAuth', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(formData)
	}))
	.then(checkStatus);
};

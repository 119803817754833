import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';

export const addSchoolTimeSlot = (schoolYearId, schoolTimeSlot) => {
	schoolTimeSlot = {
		...schoolTimeSlot,
		SchoolYearId: schoolYearId
	};
	return fetch(apiBasename + '/schoolTimeSlot', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(schoolTimeSlot)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const removeSchoolTimeSlot = (id) => {
	return fetch(apiBasename + '/schoolTimeSlot/' + id, authorization({
		method: 'DELETE'
	}))
	.then(checkStatus);
};

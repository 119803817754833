import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink as RRNavLink } from 'react-router-dom';
import {
	Button,
	Nav, NavItem, NavLink
} from 'reactstrap';

import ConfirmationModal from 'components/utils/confirmation_modal';

import AdminChildDetailInfo from 'components/admin/children/detail/info';
import AdminChildDetailContracts from 'components/admin/children/detail/contracts';
import AdminChildDetailDocuments from 'components/admin/children/detail/documents';

class AdminChildDetail extends React.Component {
	state = {
		removeChildModalOpen: false
	};

	componentDidMount() {
		if (this.props.match.isExact) {
			this.props.history.push(this.props.match.url  +'/info');
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.child.id !== prevProps.child.id) {
			this.props.history.push(this.props.match.url + '/info');
		}
	}

	render() {
		return (
			<React.Fragment>
			<Nav className="mb-4">
				<li className="font-weight-bold mr-4" style={{ alignSelf: 'center' }}>
					{ this.props.child.firstname } { this.props.child.lastname }
				</li>
				<NavItem style={{ borderLeft: '1px solid #343A40' }}>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/info' }>Info</NavLink>
				</NavItem><NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/contracts' }>Contracts</NavLink>
				</NavItem><NavItem>
					<NavLink tag={ RRNavLink } activeClassName="active-link" to={ this.props.match.url + '/documents' }>Documents</NavLink>
				</NavItem>
				<li className="ml-auto" style={{ alignSelf: 'center' }}>
					<Button size="sm" color="danger" className="mr-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeChildModalOpen: true }) }>
						Remove child{' '}<i className="fa fa-trash"></i>
					</Button>
				</li>
			</Nav>
			<Route path={ this.props.match.path + '/info' } render={ () => (
				<AdminChildDetailInfo
					child={ this.props.child }
					updateChild={ this.props.updateChild }
				/>
			) } />
			<Route path={ this.props.match.path + '/contracts' } render={ (props) => (
				<AdminChildDetailContracts
					{ ...props }
					child={ this.props.child }
				/>
			) } />
			<Route path={ this.props.match.path + '/documents' } render={ (props) => (
				<AdminChildDetailDocuments
					child={ this.props.child }
				/>
			) } />
			<ConfirmationModal
				isOpen={ this.state.removeChildModalOpen }
				text={ 'Are you sure you want to remove this child (' + this.props.child.firstname + ' ' + this.props.child.lastname + ') ?' }
				onConfirm={ () => { this.setState({ removeChildModalOpen: false }); this.props.removeChild(); } }
				onDismiss={ () => { this.setState({ removeChildModalOpen: false }); } }
			/>
			</React.Fragment>
		);
	}
};

AdminChildDetail.propTypes = {
	child: PropTypes.object.isRequired,
	updateChild: PropTypes.func.isRequired,
	removeChild: PropTypes.func.isRequired
};

export default AdminChildDetail;

import 'whatwg-fetch';
import { checkStatus, authorization } from 'actions/utils/fetch_utils';
import { apiBasename } from 'actions/config/api';
import format from 'date-fns/format';

export const fetchSchoolYears = () => {
	return fetch(apiBasename + '/schoolYear', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const updateSchoolYear = (id, changes) => {
	return fetch(apiBasename + '/schoolYear/' + id, authorization({
		method: 'PUT',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(changes)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const addSchoolYear = (schoolYear) => {
	return fetch(apiBasename + '/schoolYear', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(schoolYear)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id, authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularOffersOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/regularOffers', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addRegularOfferToSchoolYear = (id, regularOffer) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/regularOffers', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(regularOffer)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchDaysOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/days', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchDaysOfSchoolYearBetweenDates = (id, startDate, endDate) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/days/' + format(startDate, 'YYYY-MM-DD') + '/' + format(endDate, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchDayOfSchoolYearAtDate = (id, date) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/days/' + format(date, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchSchoolTimeSlotsOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/schoolTimeSlots', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addSchoolTimeSlotToSchoolYear = (id, schoolTimeSlot) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/schoolTimeSlots', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(schoolTimeSlot)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayTimeSlotsOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/holidayTimeSlots', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const addHolidayTimeSlotToSchoolYear = (id, holidayTimeSlot) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/holidayTimeSlots', authorization({
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(holidayTimeSlot)
	}))
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularContractsOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/regularContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularContractInvoicesOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/regularContractInvoices', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchRegularContractsOfSchoolYearAtDate = (id, date) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/regularContracts/' + format(date, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchPunctualContractsOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/punctualContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchPunctualContractInvoicesOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/punctualContractInvoices', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchPunctualContractsOfSchoolYearAtDate = (id, date) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/punctualContracts/' + format(date, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayContractsOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/holidayContracts', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayContractInvoicesOfSchoolYear = (id) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/holidayContractInvoices', authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchHolidayContractsOfSchoolYearAtDate = (id, date) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/holidayContracts/' + format(date, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};

export const fetchAbsencesOfSchoolYearBetweenDates = (id, startDate, endDate) => {
	return fetch(apiBasename + '/schoolYear/' + id + '/absences/' + format(startDate, 'YYYY-MM-DD') + '/' + format(endDate, 'YYYY-MM-DD'), authorization())
	.then(checkStatus)
	.then((res) => res.json());
};
